import Keycloak from 'keycloak-js';
import { env } from './env';

const config = {
  realm: env.REACT_APP_AUTH_REALM || '',
  url: env.REACT_APP_AUTH_URL || '',
  'ssl-required': 'external',
  resource: 'roseland',
  'public-client': true,
  'verify-token-audience': true,
  'use-resource-role-mappings': true,
  'confidential-port': 0,
  clientId: window.location.host === 'recon.locallabs.com' ? 'recon' : 'roseland'
};

const keycloak = new Keycloak(config);

export default keycloak;
export { config as keycloakConfig };
