import { gql, useLazyQuery, useQuery } from '@apollo/client';

export interface Organization {
  id: string;
  name: string;
  type: string;
  category: string;
  web: string;
  email: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  phone: string;
  lat: number;
  lon: number;
  address: string;
  city: string;
  state: string;
  zip: string;
  unit: string;
  addressId: string;
  logo: string;
}

const ORGANIZATION_BY_ID = gql`
  query OrganizationById($id: UUID) {
    org: viewOrganizations(condition: { id: $id }) {
      nodes {
        id
        type
        category
        name
        web
        email
        facebook
        twitter
        linkedin
        phone
        lat
        lon
        address
        city
        state
        zip
        unit
        addressId
        logo
      }
    }
  }
`;

export const useOrganizationByIdQuery = (id: string) => {
  return useQuery(ORGANIZATION_BY_ID, { variables: { id: id }, context: { version: '2' } });
};

const SIMPLE_ORGANIZATION_BY_ID = gql`
  query SimpleOrganizationById($ids: [UUID!]!) {
    result: viewOrganizationNames(filter: { id: { in: $ids } }, first: 1) {
      nodes {
        name
        id
      }
    }
  }
`;

export const useSimpleOrganizationByIdQuery = () => {
  return useLazyQuery(SIMPLE_ORGANIZATION_BY_ID, { context: { version: '2' } });
};

const ORGANIZATIONS_BY_IDS = gql`
  query OrganizationsByIds($ids: [UUID!]!) {
    result: viewOrganizationNames(filter: { id: { in: $ids } }) {
      nodes {
        name
        id
      }
    }
  }
`;

export const useOrganizationsByIdsQuery = () => {
  return useLazyQuery(ORGANIZATIONS_BY_IDS, { context: { version: '2' } });
};

export interface SlipMessage {
  organizationId: string;
  threadId: string;
  message_id: string;
  timestamp: Date;
  subject: string;
  sender: string;
  recipients: string;
  direction: string;
  body_text: string;
  body_html: string;
  attachments: string;
  deliveries: string;
}

const SLIP_MESSAGES_BY_THREAD_ID = gql`
  query SlipMessagesByThreadId($id: UUID) {
    messages: viewOrganizationSlipMessages(condition: { threadId: $id }, orderBy: TIMESTAMP_ASC) {
      nodes {
        organizationId
        threadId
        messageId
        timestamp
        subject
        sender
        recipients
        direction
        bodyText
        bodyHtml
        attachments
        deliveries
      }
    }
  }
`;

export const useOrganizationSlipMessagesByThreadIdQuery = () => {
  return useLazyQuery(SLIP_MESSAGES_BY_THREAD_ID);
};
