import { toast } from 'react-toastify';

export const showSuccess = (msg: string, autoClose?: number, closeCallback?: any) =>
  toast.success(msg, buildOptions(msg, autoClose, closeCallback));
export const showWarning = (msg: string, autoClose?: number, closeCallback?: any) =>
  toast.warn(msg, buildOptions(msg, autoClose, closeCallback));
export const showError = (msg: string, autoClose?: number, closeCallback?: any) =>
  toast.error(msg, buildOptions(msg, autoClose, closeCallback));
export const showInfo = (msg: string, autoClose?: number, closeCallback?: any) =>
  toast.info(msg, buildOptions(msg, autoClose, closeCallback));

const buildOptions = (msg: string, autoClose?: number, closeCallback?: any) => {
  const options = { toastId: msg };
  if (autoClose) {
    options['autoClose'] = autoClose;
  }
  if (closeCallback) {
    options['onClose'] = closeCallback;
  }
  return options;
};
