import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useFilterCountQuery } from '../../gql/filter';
import { useNumberFormat } from '../../utils/format';
import { ClassNameProps } from '../../common/Props';
import { LoadingMini } from '../Loading';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import Icon from '../Icon';
import { FILTER_VALUE_SEP, DataSource } from '../../utils/filter';
import { FormCheck } from '../Form';

/**
 * Filter Option
 */

interface FilterOptionProps extends ClassNameProps {
  label: string;
  query: string;
  type: string;
  value: any[];
  skipCount: boolean;
  field: string;
  filterType: string;
  source: DataSource;
  apiVersion: string;
  exclusive?: any[];
}

const FilterOption = styled(
  memo(
    ({
      className,
      label,
      query,
      value,
      type,
      skipCount,
      field,
      filterType,
      source,
      apiVersion,
      exclusive
    }: FilterOptionProps) => {
      const format = useNumberFormat();

      const { data, loading, error } = useFilterCountQuery({
        query,
        value,
        type,
        field,
        filterType,
        skipCount,
        source,
        apiVersion
      });

      let [searchParams, setSearchParams] = useSearchParams({});
      const urlValue = useMemo(() => value.join(FILTER_VALUE_SEP), [value]);
      const urlKey = useMemo(() => `${field}-${type}`, [field, type]);

      const [disabled, setDisabled] = useState(true);

      const active = useMemo(() => {
        return !isNil(searchParams.getAll(urlKey).find((param) => param === urlValue));
      }, [searchParams, urlKey, urlValue]);

      const handleClick = useCallback(() => {
        if (!active) {
          const newParams = new URLSearchParams();
          //if the selected option is "exclusive", clear any other search params
          if (exclusive?.includes(urlValue)) {
            searchParams.forEach((value, key) => {
              if (key !== urlKey) {
                newParams.append(key, value);
              }
            });
          }
          //if the selected option is not "exclusive", clear any "exclusive" search params
          if (!exclusive?.includes(urlValue)) {
            searchParams.forEach((value, key) => {
              if (!exclusive?.includes(value) || key !== urlKey) {
                newParams.append(key, value);
              }
            });
          }
          newParams.append(urlKey, urlValue);
          setSearchParams(newParams);
        } else {
          const newParams = new URLSearchParams();
          searchParams.forEach((value, key) => {
            if (value !== urlValue || key !== urlKey) {
              newParams.append(key, value);
            }
          });
          setSearchParams(newParams);
        }
      }, [urlValue, urlKey, active, setSearchParams, searchParams]);

      useEffect(() => {
        if (!loading) {
          if (data && data.count?.totalCount < 1) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        }
      }, [data, loading, setDisabled]);

      return (
        <Row
          className={`${className} filter-option ${active ? 'filter-option--active' : ''} ${
            disabled ? 'filter-option--disabled' : ''
          }`}
        >
          <Col xs={8} className="filter-option__label">
            <FormCheck disabled={disabled} onClick={handleClick} checked={active} />{' '}
            <span onClick={active || disabled ? () => false : handleClick}>{label}</span>
          </Col>
          {!skipCount && (
            <Col xs={4} className="filter-option__count">
              {isNil(error) ? (
                loading || !data ? (
                  <LoadingMini size={4} style={{ padding: '2px', float: 'right' }} />
                ) : (
                  `${format(data.count?.totalCount)}`
                )
              ) : (
                <Icon size={'xs'} icon={faExclamationCircle} />
              )}
            </Col>
          )}
        </Row>
      );
    }
  )
)`
  padding-left: 0;
  padding-bottom: 6px;
  position: relative;
  .filter-option__count {
    text-align: right;
  }
  .filter-option__label {
    cursor: pointer;
    padding-right: 0;
    font-size: 11px;
    & > div {
      display: inline-block;
      line-height: 17px;
      input {
        border-radius: 2px;
        border: 1px solid var(--filter-cb-border-color);
        width: 16px;
        height: 16px;
        margin-right: 5px;
        background-color: transparent;
        position: relative;
        top: -2px;
      }
    }
  }

  &.filter-option--active {
    .filter-option__label {
      cursor: default;
    }
    color: var(--filter-color);
  }
  &.filter-option--disabled {
    .filter-option__label,
    .filter-option__count {
      cursor: default;
      color: var(--filter-disabled);
    }
  }
`;

export default FilterOption;
