import React from 'react';
import Forbidden from '../../pages/Forbidden';
import { hasRole, isUserActivated } from '../../utils/auth';

const Authorize: React.FC<{ roles: string[]; children: any }> = ({ roles, children }) => {
  if (roles.some(hasRole) && isUserActivated()) {
    return children;
  }
  return <Forbidden />;
};

export default Authorize;
