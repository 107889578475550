export const casesByLawfirmDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (field === 'lawFirmType') {
    return {};
  }
  if (filterValue[0].length === 0) {
    return {};
  }
  const lfType = searchParams.getAll('lawFirmType-clf');

  return lfType.length > 0
    ? {
        viewLawfirmsByCasesByCaseIdConnection: {
          some: {
            and: [{ lawFirmName: { equalTo: filterValue[0] } }, { type: { in: lfType } }]
          }
        }
      }
    : {
        viewLawfirmsByCasesByCaseIdConnection: {
          some: {
            and: [{ lawFirmName: { equalTo: filterValue[0] } }]
          }
        }
      };
};
export const casesByLawfirmEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  throw new Error('clf is unimplemented for elasticSearch source');
};
