import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import mick from '../../img/mick.webp';
import mickPants from '../../img/mickPants.webp';
import mickYoda from '../../img/mick_yoda.png';

const Mick: React.FC<{ className?: string }> = styled(({ className }) => {
  const [searchParams] = useSearchParams();
  const [img, setImg] = useState(mickPants);
  const [showPants, setShowPants] = useState<boolean>(false);
  const [showYoda, setShowYoda] = useState<boolean>(false);

  useEffect(() => {
    if (searchParams.get('person') === 'mick mcdonnell') {
      setShowPants(true);
      setShowYoda(false);
    } else if (searchParams.get('person') === 'mick yoda') {
      setShowYoda(true);
      setShowPants(false);
    } else {
      setShowPants(false);
      setShowYoda(false);
    }
  }, [searchParams, setShowPants, setShowYoda]);

  useEffect(() => {
    let timer;

    if (showPants) {
      timer = setTimeout(() => {
        setImg(mick);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [setImg, showPants]);

  if (showYoda) {
    return (
      <img
        alt="Baby Yoda Mick"
        className={`${className} yoda ${showYoda ? 'show' : ''}`}
        loading="lazy"
        src={mickYoda}
      />
    );
  } else if (showPants) {
    return (
      <img
        alt="Mick"
        style={showPants ? { transform: 'translateX(-100%)' } : {}}
        className={`${className} pants ${showPants ? 'show' : ''}`}
        loading="lazy"
        src={img}
      />
    );
  }

  return null;
})`


@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
}
}





&.pants{
  position:fixed; 
  left:100%; 
  bottom:0;
  z-index:1000
  transition: transform 1s;
}

&.yoda{
  position:fixed; 
  display:none;
  left:20%;
  top:0;
  z-index:10000;
  overflow: hidden;
  box-shadow:none;
	
  animation: float 6s ease-in-out infinite;
  &.show{
    display:block;
  }
}


`;

export default Mick;
