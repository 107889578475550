import { isNil } from 'lodash';
import { bucketsToRows } from '../../utils/table';

export const mergeUnknownPartyRecords = (rows: any[]) => {
  const result: any[] = [];

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    let skipAdd = false;
    for (let j = 0; j < result.length; j++) {
      const collected = result[j];
      if (isNil(collected.candidateName)) {
        continue;
      }
      if (row.party === 'UNKNOWN' && collected.candidateName === row.candidateName) {
        skipAdd = true;
        result[j] = { ...collected, ...{ votes: collected.votes + row.votes } };
      }
    }
    if (!skipAdd) {
      result.push(row);
    }
  }
  return result;
};

export const flattenBuckets = (agg: any, row: any) => {
  if (isNil(agg)) {
    return [];
  }

  return mergeUnknownPartyRecords(
    bucketsToRows(
      agg,
      row,
      [
        'buckets',
        'state',
        'type',
        'contest_category',
        'year',
        'contest_name',
        'county_id',
        'county_name',
        'precinct_name',
        'township_id',
        'township_name',
        'candidate_name',
        'party'
      ],
      { key: 'vote_totals', alias: 'votes' }
    )
  );
};
