import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const LAWYER_FRAGMENT = gql`
  fragment Lawyer on MViewLawyer {
    id
    firstName
    lastName
    middleName
    suffix
    dateOfBirth
    hometown
    state
    viewLawfirmsByLawyersByLawyerIdConnection {
      nodes {
        lawFirmId
        lawFirmName
      }
    }
    viewLawschoolsByLawyersByLawyerIdConnection {
      nodes {
        lawschoolId
        lawschoolName
        years
      }
    }
    viewUniversitiesByLawyersByLawyerIdConnection {
      nodes {
        universityId
        universityName
        years
      }
    }
  }
`;

const LAWYERS_LISTING = gql`
  query LawyersListing($filter: MViewLawyerFilter!, $offset: Int!, $orderBy: [MViewLawyersOrderBy!]) {
    lawyers: mViewLawyersConnection(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Lawyer
      }
      totalCount
    }
  }
  ${LAWYER_FRAGMENT}
`;

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: mViewLawyersConnection(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useStateLawyerCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY);
};

export const useAllLawyersListingQuery = (offset: number, sort: string[]) => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  if (sort.length < 1) {
    sort = ['name_ASC'];
  }
  if (sort.indexOf('name_ASC') > -1) {
    sort.splice(sort.indexOf('name_ASC'), 1, 'LAST_NAME_ASC', 'FIRST_NAME_ASC');
  }
  if (sort.indexOf('name_DESC') > -1) {
    sort.splice(sort.indexOf('name_DESC'), 1, 'LAST_NAME_DESC', 'FIRST_NAME_DESC');
  }

  const result = useQuery(LAWYERS_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

const LAWYERS_FOR_DOWNLOAD = gql`
  query LawyersDownload($first: Int!, $filter: MViewLawyerFilter!, $offset: Int!, $orderBy: [MViewLawyersOrderBy!]) {
    csv: mViewLawyersConnection(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Lawyer
      }
    }
  }
  ${LAWYER_FRAGMENT}
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: LAWYERS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC', 'FIRST_NAME_ASC']
    }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewLawyersConnection {
      totalCount
    }
  }
`;

export const useLawyersTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, {});
};
