import React from 'react';
import { Card as BootstrapCard, CardProps } from 'react-bootstrap';
import styled from 'styled-components';

interface RCartProps extends Omit<CardProps, 'title'> {
  title?: React.ReactNode;
}
const Card = styled(
  React.forwardRef(({ children, title, className }: RCartProps, ref: React.ForwardedRef<any>) => {
    return (
      <BootstrapCard ref={ref} className={`${className}`}>
        {title && (
          <BootstrapCard.Header>
            <h3>{title}</h3>
          </BootstrapCard.Header>
        )}
        <BootstrapCard.Body className="card__body">{children}</BootstrapCard.Body>
      </BootstrapCard>
    );
  })
)`
  box-shadow: var(--card-box-shadow);
  border: var(--card-border);
  .card__body {
    padding: var(--card-padding);
  }
`;

export default Card;
