import React, { memo } from 'react';
import { FingerprintSpinner, LoopingRhombusesSpinner } from 'react-epic-spinners';
import styled from 'styled-components';

const Loading = styled(
  memo((props: any) => {
    return <FingerprintSpinner color="#ff2500" {...props} />;
  })
)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;

  min-width: 150px;
  padding: 12px;
  border-radius: var(--border-radius);
`;

export const LoadingMini = styled(
  memo((props: any) => {
    return <LoopingRhombusesSpinner {...props} />;
  })
)`
  padding: 12px;
`;

export default Loading;
