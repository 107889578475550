import { toSnakeCase } from '../../utils/format';

export const dateRandgeDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const rangeFrom = searchParams.get(field + '-rangeFrom');
  const rangeTo = searchParams.get(field + '-rangeTo');
  return rangeFrom && rangeTo
    ? {
        and: [
          {
            [field]: { greaterThanOrEqualTo: rangeFrom }
          },
          {
            [field]: { lessThanOrEqualTo: rangeTo }
          }
        ]
      }
    : rangeFrom
      ? {
          [field]: {
            greaterThanOrEqualTo: rangeFrom
          }
        }
      : {
          [field]: {
            lessThanOrEqualTo: rangeTo
          }
        };
};

export const dateRangeEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);

  const rangeFrom = searchParams.get(field + '-rangeFrom');
  const rangeTo = searchParams.get(field + '-rangeTo');

  return {
    must: [
      {
        range: {
          [snkField]: {
            lte: rangeTo ? rangeTo : new Date().toISOString().split('T')[0],
            gte: rangeFrom ? rangeFrom : '1970-01-01',
            format: 'yyyy-MM-dd'
          }
        }
      }
    ]
  };
};
