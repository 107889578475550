import { stripPunctuation } from '../../utils/search';

export const caseNameDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return filterValue.length > 0
    ? {
        or: [
          {
            name: { likeInsensitive: '%' + filterValue[0] + '%' }
          }
        ]
      }
    : {};
};
export const caseNameEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (filterValue[0].length <= 1) {
    return {};
  }

  const tokens = filterValue[0].split(' ');

  let resp: any[] = [];
  tokens.forEach((token) => {
    resp.push({
      query_string: {
        query: `*${stripPunctuation(token)}*`,
        fields: ['name_text']
      }
    });
  });

  return { must: resp };
};
