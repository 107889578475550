export const emailExistsDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  console.error('Email exists parameter processor not available for DB query.');
};
export const emailExistsEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (filterValue[0] === 'false') {
    return {
      must_not: [
        {
          bool: {
            must: [
              {
                wildcard: {
                  email: '*'
                }
              },
              {
                bool: {
                  must_not: {
                    term: {
                      email: ''
                    }
                  }
                }
              }
            ]
          }
        },
        {
          nested: {
            path: 'factoids',
            query: {
              bool: {
                must: [
                  {
                    wildcard: {
                      'factoids.email.keyword': '*'
                    }
                  },
                  {
                    bool: {
                      must_not: {
                        term: {
                          'factoids.email.keyword': ''
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        }
      ]
    };
  } else {
    return {
      should: [
        {
          bool: {
            must: [
              {
                wildcard: {
                  email: '*'
                }
              },
              {
                bool: {
                  must_not: {
                    term: {
                      email: ''
                    }
                  }
                }
              }
            ]
          }
        },
        {
          nested: {
            path: 'factoids',
            query: {
              bool: {
                must: [
                  {
                    wildcard: {
                      'factoids.email.keyword': '*'
                    }
                  },
                  {
                    bool: {
                      must_not: {
                        term: {
                          'factoids.email.keyword': ''
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        }
      ],
      minimum_should_match: 1
    };
  }
};
