import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { endsWith, flatten, isNil, split } from 'lodash';
import React, { MouseEvent, createRef, memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, LinkProps, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import styled from 'styled-components';
import { setExpandedNav, setShowSubnavMenu, useExpandedNav, useShowSubnavMenu } from '../../store/app';
import { setShowFilter, useFilterState, useShowFilter } from '../../store/filter';
import { setSubNavState, useSubNavState } from '../../store/subnav';
import { useFeatureFlags } from '../../utils/features';
import { useNumberFormat } from '../../utils/format';
import { useScreenSize } from '../../utils/layout';
import Filter from '../Filter';
import Icon from '../Icon';
import { LoadingMini } from '../Loading';
import ScrollPane from '../ScrollPane';
import RequiresRoles from '../Security/RequiresRoles';
import AppSelectorNav from './AppSelectorNav';
import { Nav, NavBarBrand, NavBarToggle } from './NavBar';
import NavigationConfig, { NavItem, SubMenu } from './NavigationConfig';
import SubNav from './SubNav';

interface NavLinkProps extends LinkProps {
  onActiveClick?: () => void;
  onDashboardClick?: () => void;
}

const NavLink = styled(({ className, onActiveClick, onDashboardClick, to, ...props }: NavLinkProps) => {
  let resolved = useResolvedPath(to);
  let location = useLocation();
  let match = useMatch({ path: resolved.pathname, end: false });

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (!isNil(onDashboardClick)) {
        onDashboardClick!();
      }
      if (endsWith(location.pathname, split(to, '?')[0]) && !isNil(onActiveClick)) {
        onActiveClick!();
        e.preventDefault();
        e.stopPropagation();
      }
      return false;
    },
    [onActiveClick, location, to, onDashboardClick]
  );

  return (
    <Link
      to={to}
      onClick={handleClick}
      className={`${className} left-nav__link ${match ? 'left-nav__link--active' : ''}`}
      {...props}
    />
  );
})`
  &.left-nav__link {
    .icon--prefix {
      margin-right: 10px;
    }
    border-radius: var(--border-radius-sm);
    .icon--suffix {
      float: right;
      margin-right: 5px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
    color: var(--left-nav-color);
    text-decoration: none;
    line-height: var(--left-nav-line-height);
    padding: var(--left-nav-item-padding);
    transition: background-color 0.3s;
    display: block;
    overflow: hidden;
    white-space: nowrap;

    position: relative;
    &:hover {
      background-color: var(--left-nav-item-hover-bg);
    }
    &.left-nav__link--active {
      color: var(--left-nav-color-active);
      background-color: var(--left-nav-item-active-bg);
    }
  }
`;

const EntityTotal = styled(({ className, queryHook }) => {
  const numberFormat = useNumberFormat({
    maximumFractionDigits: 0
  });

  const { data, loading } = queryHook ? queryHook() : '';

  return (
    <div style={{ fontSize: '10px' }} className={className}>
      (
      {data && !loading ? (
        numberFormat(data.dashboardTotals.totalCount)
      ) : (
        <LoadingMini style={{ display: 'inline-block' }} size={4} />
      )}
      )
    </div>
  );
})`
.looping-rhombuses-spinner{
  position:relative;
  top:6px;
  left:2px;
  .rhombus{
      background-color: currentColor;
    }
  }
}
`;

const LeftNavDashMenu: React.FC<{ items: (NavItem | SubMenu)[] }> = memo(({ items }) => {
  const show = useShowFilter();
  const filterState = useFilterState();

  const features = useFeatureFlags();

  const flattenedItems = flatten(
    items
      .filter((item: any) => isNil(item.feature) || features[item.feature || ''])
      .map((item: any) => (item.submenu ? item.navItems : item))
  );

  return (
    <>
      {flattenedItems.map((navItem: any) => {
        return (
          <RequiresRoles key={navItem.filterType} roles={navItem.roles || []}>
            <NavLink
              className={`nav-link nav-link--${
                show[navItem.filterType] &&
                ((filterState?.filter?.filters || []).length > 0 || !isNil(navItem.subNavType))
                  ? 'expanded'
                  : 'collapsed'
              }`}
              onActiveClick={() => {
                setShowFilter({ [navItem.filterType]: !show[navItem.filterType] });
              }}
              onDashboardClick={() => {
                setShowSubnavMenu(!isNil(navItem.subNavType));
                setSubNavState({ type: navItem.subNavType });
              }}
              title={navItem.title}
              to={navItem.route}
            >
              <Icon icon={navItem.icon} className="icon--prefix" />
              <span className="label">
                {navItem.title}
                <EntityTotal queryHook={navItem.queryHook} />
              </span>
            </NavLink>
            <Filter type={navItem.filterType} />
            {!isNil(navItem.subNavType) && show[navItem.filterType] && <SubNav type={navItem.subNavType} />}
          </RequiresRoles>
        );
      })}
    </>
  );
});

const NavSubMenu: React.FC<{
  title: string;
  className?: string;
  icon: any;
  onActivate?: () => void;
  children: React.ReactNode | React.ReactNode[];
  type: string;
}> = styled(({ title, children, className, icon, onActivate, type }) => {
  const state = useSubNavState();
  const [show, setShow] = useState<boolean>(false);

  const showFromDashboard = state.type === type;

  useEffect(() => {
    return () => {
      setShow(false);
      setShowSubnavMenu(false);
      setSubNavState({ type: '' });
    };
  }, []);

  const showSubnav = useCallback(
    (show: boolean) => {
      if (show) {
        setShow(show);
      } else {
        setTimeout(() => setShow(show), 250);
      }
      setShowSubnavMenu(show);
      setSubNavState({ type: '' });
      if (show && !isNil(onActivate)) {
        onActivate();
      }
    },
    [setShow, onActivate]
  );

  return (
    <div className={`${className} ${showFromDashboard || show ? 'show' : ''}`}>
      <Button onClick={() => showSubnav(true)} className={'submenu__title_link'}>
        <Icon icon={icon} className="icon--prefix" />
        <span className="label">{title}</span>
        <Icon className={'icon--suffix'} icon={faAnglesRight} />
      </Button>
      <div className={'submenu__menu'}>
        <Button onClick={() => showSubnav(false)} className={'submenu__back_link'}>
          <Icon className={'icon--prefix'} icon={faAnglesLeft} />{' '}
          <span className="submenu__back-label">Back to Main Navigation</span>
        </Button>
        {children}
      </div>
    </div>
  );
})`
  .btn {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    color: var(--left-nav-color);
    line-height: var(--left-nav-line-height);
    padding: var(--left-nav-item-padding);
    transition: background-color 0.3s ease 0s;
    background-color: transparent;
    border: 0;
    border-radius: var(--border-radius-sm);
    .icon--prefix {
      margin-right: 10px;
    }
    .icon--suffix {
      margin-left: 10px;
      float: right;
    }
    &:hover {
      background-color: var(--left-nav-item-hover-bg);
    }
  }
  .submenu__menu {
    position: absolute;
    left: 100%;
    top: 0;
    display: none;
    width: 100%;
  }
  &.show {
    .submenu__menu {
      display: block;
    }
  }
`;

const LeftNavMenu: React.FC<{ items: (NavItem | SubMenu)[]; onActivate?: () => void }> = memo(
  ({ items, onActivate }) => {
    const show = useShowFilter();
    const filterState = useFilterState();

    const features = useFeatureFlags();

    return (
      <>
        {items
          .filter((item: any) => isNil(item.feature) || features[item.feature || ''])
          .map((navItem: any) => {
            return !navItem.submenu ? (
              <RequiresRoles key={navItem.title} roles={navItem.roles || []}>
                <NavLink
                  className={`nav-link nav-link--${
                    show[navItem.filterType] &&
                    ((filterState?.filter?.filters || []).length > 0 || !isNil(navItem.subNavType))
                      ? 'expanded'
                      : 'collapsed'
                  }`}
                  onActiveClick={() => {
                    setShowFilter({ [navItem.filterType]: !show[navItem.filterType] });
                  }}
                  title={navItem.title}
                  to={navItem.route}
                >
                  <Icon icon={navItem.icon} className="icon--prefix" />
                  <span className="label">{navItem.title}</span>
                </NavLink>
                <Filter type={navItem.filterType} />
              </RequiresRoles>
            ) : (
              <RequiresRoles key={navItem.title} roles={navItem.roles || []}>
                <NavSubMenu title={navItem.title} icon={navItem.icon} onActivate={onActivate} type={navItem.type}>
                  <LeftNavMenu items={navItem.navItems} />
                </NavSubMenu>
              </RequiresRoles>
            );
          })}
      </>
    );
  }
);

export const LeftNav = styled(
  ({
    className,
    dashboard,
    navigationConfig
  }: {
    className?: string;
    dashboard?: boolean;
    navigationConfig?: any[];
  }) => {
    const screenSize = useScreenSize();
    const expandedNav = useExpandedNav();
    const showSubnavMenu = useShowSubnavMenu();
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
      setExpandedNav(screenSize.width > 780);
    }, []);

    const scrollToTop = useCallback(() => {
      if (!isNil(ref.current)) {
        ref.current!.scrollTop = 0;
      }
    }, [ref]);

    // NB: Add nav items within NavigationConfig.ts
    return (
      <Nav className={`${className} flex-column side-nav side-nav--${expandedNav ? 'expanded' : 'collapsed'}`}>
        <AppSelectorNav />
        <Nav>
          <NavBarBrand />
          <NavBarToggle onClick={() => setExpandedNav(!expandedNav)} />
        </Nav>
        <ScrollPane ref={ref} className="dark" id={'left-nav-scrollpane'}>
          {dashboard ? (
            <LeftNavDashMenu items={navigationConfig || NavigationConfig} />
          ) : (
            <div className={`left-nav__innner_wrap left-nav__innner_wrap${showSubnavMenu ? '--show-subnav' : ''}`}>
              <LeftNavMenu items={navigationConfig || NavigationConfig} onActivate={scrollToTop} />
            </div>
          )}
        </ScrollPane>
      </Nav>
    );
  }
)`
  position: relative;
  padding: 20px 10px;
  border-radius: var(--border-radius-sm);
  background-color: var(--left-nav-bg);
  max-height: calc(100vh - 40px);
  display: flex;
  gap: 23px;
  align-items: stretch;
  .left-nav__innner_wrap {
    position: relative;
    transition: transform 0.25s ease-in-out;
    &.left-nav__innner_wrap--show-subnav {
      transform: translateX(-100%);
    }
  }

  @media (max-width: 767.98px) {
    max-height: 100vh;
    border-radius: 0;
  }
  .scroll-pane {
    overflow-x: hidden;
  }
  .nav {
    padding: 0 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 94px;
    height: 35px;
  }
  .icon--suffix {
    display: none;
  }
  .submenu__back-label {
    display: none;
  }
  .nav-link--collapsed {
    border-bottom-left-radius: var(--border-radius-sm);
    border-bottom-right-radius: var(--border-radius-sm);
  }
  .nav.left-nav__segment {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;
    .tab__label {
      display: none;
    }
    a {
      font-size: 10px;
      padding: 0;
      text-align: center;
    }
  }
  &.side-nav--expanded {
    overflow: hidden;
    .nav.left-nav__segment {
      .tab__label {
        display: inline-block;
        padding-left: 4px;
      }
      a {
        font-size: 12px;
        padding: 0 20px;
      }
    }
    .icon--suffix {
      display: inline;
    }
    .submenu__back-label {
      display: inline;
    }
    .nav-link--expanded {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  &.nav.side-nav {
    flex-wrap: nowrap;
  }
`;
