import { unflatten } from '@jc99ta/react-base-table';
import { camelCase, differenceWith, flatten, get, isNil, uniqueId } from 'lodash';
import { useCallback, useState } from 'react';
export interface OptionDef {
  key: string;
}

export const filterSelected = (availableColumns: OptionDef[], selectedColumns: OptionDef[]) => {
  return availableColumns.filter((column: OptionDef) => {
    return selectedColumns.some((df: OptionDef) => df.key === column.key);
  });
};

export const syncOrder = (availableColumns: OptionDef[], selectedColumns: OptionDef[]) => {
  const newArray: any[] = [];
  const unselected = differenceWith(availableColumns, selectedColumns, (a: OptionDef, b: OptionDef) => a.key === b.key);
  if (!isNil(selectedColumns)) {
    selectedColumns.forEach((column: OptionDef, i: number) => {
      const col = availableColumns.find((ac: OptionDef) => ac.key === column.key);
      newArray[i] = col;
    });
  }

  return [...newArray, ...unselected];
};

export const clearColumnConfig = (tableName: string) => {
  localStorage.removeItem(tableName);
};

export const loadColumnConfig = (tableName: string, availableColumns: OptionDef[], defaultColumns: OptionDef[]) => {
  const config: string | null = localStorage.getItem(tableName);

  if (isNil(config) || '' === config) {
    return defaultColumns
      .map((item: OptionDef) => {
        return availableColumns.find((column: OptionDef) => {
          return item.key === column.key;
        });
      })
      .filter((i: any) => !isNil(i));
  }

  const parsed: any[] = JSON.parse(config!);
  return getColumns(availableColumns, parsed);
};

export const getColumns = (availableColumns: OptionDef[], selectedColumns: OptionDef[]) => {
  if (isNil(selectedColumns)) {
    return [];
  }
  return selectedColumns
    .map((item: OptionDef) => {
      return availableColumns.find((column: OptionDef) => {
        return item.key === column.key;
      });
    })
    .filter((i: any) => !isNil(i));
};

export const saveColumnConfig = (tableName: string, columns: OptionDef[]) => {
  localStorage.setItem(tableName, JSON.stringify(columns));
};

export const useColumnConfigCallbacks = (columnStorageKey: string, availableColumns: any[], defaultColumns: any[]) => {
  const [columns, setColumns] = useState(loadColumnConfig(columnStorageKey, availableColumns, defaultColumns));

  const handleColumnChange = useCallback(
    (newColumns: any[]) => {
      saveColumnConfig(columnStorageKey, newColumns);
      setColumns(getColumns(availableColumns, newColumns));
    },
    [setColumns, availableColumns, columnStorageKey]
  );

  const handleReset = useCallback(() => {
    setColumns(getColumns(availableColumns, defaultColumns));
    clearColumnConfig(columnStorageKey);
  }, [setColumns, columnStorageKey, availableColumns, defaultColumns]);

  return { columns, setColumns, handleColumnChange, handleReset, availableColumns, columnStorageKey };
};

export const ADDRESS_GB_KEY: string = 'address-gbt';

export const useMaybeGroupResultsByAddress = () => {
  return (result: any, searchParams: URLSearchParams, dataKey?: string) => {
    if (!isNil(searchParams.get(ADDRESS_GB_KEY))) {
      const groupedResult = { ...result };
      const addressVoterIdDictionary = {};

      // keep track of the actual, ungrouped, count for offset calculation
      const actualCount = (result?.data?.[dataKey || 'people']?.nodes || []).length;

      const grouped = (result?.data?.[dataKey || 'people']?.nodes || []).map((person: any) => {
        const key = `${person.address}`.toLowerCase();
        const existingVoterId = addressVoterIdDictionary[key];

        const voterId = person.id || person.voterId || person.personId;

        if (!isNil(existingVoterId)) {
          return { ...person, ...{ parentId: existingVoterId }, ...{ id: voterId } };
        } else {
          addressVoterIdDictionary[key] = voterId;
        }
        return { ...person, ...{ id: voterId } };
      });

      if (isNil(groupedResult) || isNil(groupedResult.data)) {
        return groupedResult;
      }

      const composed = {
        ...groupedResult,
        ...{
          data: {
            actualCount: actualCount,
            [dataKey || 'people']: {
              nodes: unflatten(grouped),
              totalCount: result?.data?.[dataKey || 'people']?.totalCount ?? 0
            }
          }
        }
      };

      return composed;
    }

    return result;
  };
};

export const addRowId = (nodes: any[]) => {
  if (isNil(nodes)) {
    return [];
  }
  return nodes.map((n: any) => {
    return isNil(n.rowId) ? { ...n, ...{ rowId: uniqueId() } } : n;
  });
};

export const bucketsToRows = (agg: any, row: any, keys: string[], endKey: any) => {
  const endVal = get(agg, endKey.key);

  // handle the end of recusion
  if (!isNil(endVal)) {
    return [{ ...row, ...{ [endKey.alias || endKey.key]: endVal.value } }];
  }

  if (isNil(agg)) {
    return [];
  }

  let rows: any[] = [];

  keys.every((key: string) => {
    if (!isNil(agg[key])) {
      const nextAgg = agg[key] || agg;
      rows = flatten(
        nextAgg.buckets.map((bucket: any) =>
          bucketsToRows(
            bucket,
            { ...row, ...{ [camelCase(key)]: bucket.key, totalCount: bucket.doc_count } },
            keys,
            endKey
          )
        )
      );
      return false;
    }
    return true;
  });
  return rows;
};
