import { isNestedPath, toSnakeCase } from '../../utils/format';

export const nameDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (isNestedPath(field)) {
    const path = field.substring(0, field.indexOf('.'));
    const rest = field.substring(field.indexOf('.') + 1);

    return { [path]: nameDbFilter(rest, searchParams, filterValue) };
  }

  return {
    [field]: {
      likeInsensitive: '%' + filterValue[0] + '%'
    }
  };
};

export const nameEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return {
    must: [
      {
        prefix: {
          [snkField]: {
            value: filterValue[0],
            case_insensitive: true
          }
        }
      }
    ]
  };
};
