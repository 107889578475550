export const groupByDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  throw new Error('gb is unimplemented for elasticSearch source');
};
export const groupByEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return {
    aggs: { group: { cardinality: { field: `normalized_${field}`, precision_threshold: 40000 } } },
    runtime_mappings: {
      [`normalized_${field}`]: {
        type: 'keyword',
        script: `String fld = doc['${field}'].value;emit(null == fld ? null : fld.toLowerCase());`
      }
    }
  };
};
