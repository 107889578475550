import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const COURTS_LISTING = gql`
  query CourtsListing($filter: MViewCourtFilter!, $offset: Int!, $orderBy: [MViewCourtsOrderBy!]) {
    courts: mViewCourtsConnection(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        name
        oldestCase
        newestCase
        totalCases
        address
        city
        state
        zip
        type
        subType
        limparUuid
      }
      totalCount
    }
  }
`;

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: mViewCourtsConnection(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

const TYPE_COUNT_QUERY = gql`
  query TypeCounts($filter: MViewCourtFilter!) {
    options: mViewCourtsConnection(filter: $filter, orderBy: NATURAL) {
      groupedAggregates(groupBy: TYPE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

const SUB_TYPE_COUNT_QUERY = gql`
  query SubTypeCounts($filter: MViewCourtFilter!) {
    options: mViewCourtsConnection(filter: $filter, orderBy: NATURAL) {
      groupedAggregates(groupBy: SUB_TYPE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useStateCourtsCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY);
};

export const useTypeCourtsCountQuery = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  queryCriteria.filter.type = { isNull: false };

  return useLazyQuery(TYPE_COUNT_QUERY, {
    variables: {
      ...queryCriteria
    }
  });
};

export const useSubTypeCourtsCountQuery = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  queryCriteria.filter.subType = { isNull: false };

  return useLazyQuery(SUB_TYPE_COUNT_QUERY, {
    variables: {
      ...queryCriteria
    }
  });
};

export const useAllCourtsListingQuery = (offset: number, sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  useEffect(() => {
    if (sort.length < 1) {
      sort = loadDefaultSort(searchParams, sort, ['NAME_ASC']);
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const result = useQuery(COURTS_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

const COURTS_FOR_DOWNLOAD = gql`
  query CourtsDownload($first: Int!, $filter: MViewCourtFilter!, $offset: Int!, $orderBy: [MViewCourtsOrderBy!]) {
    csv: mViewCourtsConnection(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        name
        address
        city
        state
        zip
        oldestCase
        newestCase
        totalCases
        type
        subType
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: COURTS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewCourtsConnection {
      totalCount
    }
  }
`;

export const useCourtsTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, {});
};
