import { createReduxModule } from 'hooks-for-redux';
import { trim } from 'lodash';
import { paths } from '../components/routes/routes';
import { Props as JoyrideProps } from 'react-joyride';

export interface BreadcrumbModel {
  label: string;
  path: string;
  home?: boolean;
}

export const [useAppLoading, setAppLoading] = createReduxModule<boolean>('appLoading', false);
export const [usePageTour, setPageTour] = createReduxModule<JoyrideProps>('pageTour', { run: false, steps: [] });
export const [useExpandedNav, setExpandedNav] = createReduxModule<boolean>('expandedNav', true);
export const [useShowSubnavMenu, setShowSubnavMenu] = createReduxModule<boolean>('showSubnavMenu', false);
export const [useLocationName, setLocationName] = createReduxModule<string>('locationName', '');

export const [useResetPendingUsersIndicator, setResetPendingUsersIndicator] = createReduxModule<any>(
  'resetPendingUsersIndicator',
  true
);

export const [useAppBreadcrumb, { pushBreadcrumb, popBreadcrumb, resetBreadcrumb }] = createReduxModule(
  'appBreadcrumb',
  [{ path: paths.dashboard, label: 'Home' }],
  {
    pushBreadcrumb: (state, item) => {
      if (state.every((i) => i.path !== item.path && trim(item.label) !== '')) {
        return [...state, item];
      }
      return state;
    },
    popBreadcrumb: (state) => {
      if (state.length > 1) {
        state.pop();
      }
      return [...state];
    },
    resetBreadcrumb: (state, excludeHome?: boolean) => (excludeHome ? [] : [{ path: paths.dashboard, label: 'Home' }])
  }
);
export const [useAppFilterKey, setAppFilterKey] = createReduxModule<string>('appFilterKey', '');
