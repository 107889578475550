import { gql, useApolloClient } from '@apollo/client';
import { isArray, isNil, merge, sortBy } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from '../components/routes/routes';
import { BreadcrumbModel, pushBreadcrumb, resetBreadcrumb, setAppFilterKey } from '../store/app';
import { FilterState, setFilterState } from '../store/filter';
import ParameterProcessorRegistry from '../urlParameterProcessor';
import { toSnakeCase, toTitleCase, useNumberFormat } from './format';
import { STEP_PARAM_KEY } from '../components/Wizard';

export const FILTER_VALUE_SEP: string = '|';

export const useFilterOptions = (options: { label: string; value: string } | string, skipQuery: boolean) => {
  const client = useApolloClient();

  const [searchParams] = useSearchParams();

  const numberFormat = useNumberFormat({
    maximumFractionDigits: 0
  });

  return useMemo(() => {
    if (isArray(options)) {
      return new Promise((resolve) => {
        resolve(options);
      });
    } else {
      if (skipQuery) {
        return [];
      }
      //replace and [state] reference with the current state
      const query = gql(parseQueryParameters(searchParams, options as string));

      return client.query({ query: query }).then((result) => {
        if (result?.data?.options?.nodes) {
          return sortBy(
            result.data.options.nodes
              .filter((n: any) => !isNil(n))
              .map(({ option }: { option: string }) => {
                return { label: option, value: [option] };
              }),
            'label'
          );
        }
        if (result?.data?.options?.groupedAggregates) {
          return sortBy(
            result.data.options.groupedAggregates.map((val) => {
              return { label: val.keys[0] + ' (' + numberFormat(val.distinctCount.id) + ')', value: [val.keys[0]] };
            }),
            'label'
          );
        }
      });
    }
  }, [options, client, searchParams, skipQuery, numberFormat]);
};

export const mergeRecords = (
  records: any[],
  getLabel: (record: any) => string,
  getId: (record: any) => number | string,
  getTotal: (record: any) => number,
  applyDefaultOptionFormatter: boolean = true,
  sorter?: Function
) => {
  const options = records.map((c: any) => {
    return {
      value: getId(c),
      total: getTotal(c),
      label: applyDefaultOptionFormatter ? toTitleCase(getLabel(c)) : getLabel(c)
    };
  });

  if (sorter) {
    options.sort((a: any, b: any) => sorter(a.label, b.label));
  } else {
    options.sort((a: any, b: any) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });
  }

  return options;
};

export const useGeoOptionFormatter = (hideTotals?: boolean) => {
  const numberFormat = useNumberFormat();

  return useCallback(
    (data, meta) => {
      if (meta.context === 'value') {
        return data.label;
      }
      return (
        <>
          {data.label}
          {hideTotals ? null : (
            <>
              <span className="option__subtext">({numberFormat(data.total)})</span>
            </>
          )}
        </>
      );
    },
    [numberFormat, hideTotals]
  );
};

export const useClearState = () => {
  const navigate = useNavigate();

  return (path?: string) => {
    setFilterState({});
    navigate(path || paths.people);
  };
};

export const useConfigureScreen = (
  filter: FilterState,
  breadcrumbModel: BreadcrumbModel | BreadcrumbModel[],
  filterKey: string,
  reset?: boolean,
  excludeHome?: boolean
) => {
  useEffect(() => {
    setFilterState(filter);
    resetBreadcrumb(excludeHome);
    if (isArray(breadcrumbModel)) {
      (breadcrumbModel as BreadcrumbModel[]).forEach(pushBreadcrumb);
    } else {
      pushBreadcrumb(breadcrumbModel);
    }
    setAppFilterKey(filterKey);
  }, [filter, breadcrumbModel, filterKey, excludeHome]);
};

export const EXCLUDED_FILTER_PARAMS: string[] = ['sort', STEP_PARAM_KEY, 'r'];

const joinSearchClauses = (typeMap: any, source: string) => {
  if (source === 'db') {
    return Object.values(typeMap).map((val: any) => (val.length > 1 ? { or: val } : val[0]));
  }

  return Object.values(typeMap).map((val: any) =>
    val.length > 1 ? { should: val.map((v: any) => (isArray(v.must) ? v.must : v)).flat(1) } : val[0]
  );
};

/**
 * Pull filter params from the url query string, fall back on the current option values for
 * counts if necessary
 */
export const getCountQueryCriteriaFromUrl = (
  searchParams: URLSearchParams,
  field: string,
  type: string,
  value: any[],
  source: DataSource
) => {
  let filters: any = {};

  const typeMap = {};
  let search = {};
  const filterTypeRegistry = ParameterProcessorRegistry.getInstance();
  for (const key of searchParams.keys()) {
    const allValues = searchParams.getAll(key);
    const keyParts = key.split('-');
    if (keyParts.length === 2) {
      const typeFromUrl = keyParts[1];
      const fieldFromUrl = keyParts[0];
      const isCurrentField = field === fieldFromUrl;

      // add current item as filter if not already added for count
      if (typeFromUrl === type && isCurrentField) {
        continue;
      }

      for (const val of allValues) {
        // or together all items of same type
        const urlValueArray = val.split(FILTER_VALUE_SEP);

        addCriteriaForKey(
          typeMap,
          key,
          filterTypeRegistry.buildProcessor(searchParams, fieldFromUrl, typeFromUrl, urlValueArray, source)
        );
      }
    } else if (!EXCLUDED_FILTER_PARAMS.includes(key)) {
      addCriteriaForKey(typeMap, key, filterTypeRegistry.buildProcessor(searchParams, key, key, allValues, source));
    }
  }

  addCriteriaForKey(
    typeMap,
    criteriaKey(field, type),
    filterTypeRegistry.buildProcessor(searchParams, field, type, value, source)
  );

  const criteria = joinSearchClauses(typeMap, source);

  if (!source || source === 'db') {
    criteria.forEach((c) => {
      filters = { ...filters, ...c };
    });
  } else {
    let root: any = null;
    let queryFilter: any = null;
    criteria.forEach((c) => {
      if (c.should) {
        if (!filters.must) {
          filters.must = [];
        }
        //add a bool for each should
        let bool: any = {};
        bool.bool = {};
        bool.bool.should = [...c.should];
        bool.bool.minimum_should_match = c.minimum_should_match || 1;
        filters.must = [...filters.must, bool];
      }
      if (c.filter) {
        if (!queryFilter) {
          queryFilter = {};
        }
        queryFilter = { ...queryFilter, ...c.filter };
      }
      if (c.must) {
        if (!filters.must) {
          filters.must = [];
        }
        filters.must = [...filters.must, ...c.must];
      }
      if (c.must_not) {
        if (!filters.must_not) {
          filters.must_not = [];
        }
        filters.must_not = [...filters.must_not, ...c.must_not];
      }
      if (c.root) {
        root = c.root;
      }
    });

    if (queryFilter) {
      filters = { query: { bool: { ...filters, ...{ filter: queryFilter } } } };
    } else {
      filters = { query: { bool: filters } };
    }
    if (root) {
      filters = { ...filters, ...root };
    }
  }

  return { filter: filters, ...search };
};

export const criteriaKey = (field: string, type: string) => {
  return `${field}-${type}`;
};

export const addCriteriaForKey = (typeMap: { [key: string]: any[] }, key: string, criteria: any) => {
  if (!criteria) {
    return;
  }
  if (!typeMap[key]) {
    typeMap[key] = [];
  }
  typeMap[key].push(criteria);
};

export const getFilterQueryCriteriaFromUrl = (
  searchParams: URLSearchParams,
  source: DataSource,
  omit?: string[],
  paramOverrides?: FilterParameterOverrides
) => {
  const filterTypeRegistry = ParameterProcessorRegistry.getInstance();
  let filters: any = {};

  const typeMap = {};

  for (let key of new Set([...searchParams.keys(), ...Object.keys(paramOverrides || {})])) {
    if (!isNil(omit) && omit!.includes(key)) {
      continue;
    }

    const allValues = (paramOverrides || {})[key] || searchParams.getAll(key);
    const keyParts = key.split('-');

    if (keyParts.length === 2) {
      const typeFromUrl = keyParts[1];
      const fieldFromUrl = keyParts[0];

      for (const val of allValues) {
        if (isNil(val)) {
          continue;
        }
        // or together all items of same type
        const urlValueArray = val.split(FILTER_VALUE_SEP).filter((v: string) => v !== '');

        addCriteriaForKey(
          typeMap,
          key,
          filterTypeRegistry.buildProcessor(searchParams, fieldFromUrl, typeFromUrl, urlValueArray, source)
        );
      }
    } else if (!EXCLUDED_FILTER_PARAMS.includes(key)) {
      addCriteriaForKey(typeMap, key, filterTypeRegistry.buildProcessor(searchParams, key, key, allValues, source));
    }
  }

  const criteria = joinSearchClauses(typeMap, source);

  if (!source || source === 'db') {
    criteria.forEach((c) => {
      filters = merge(filters, c); //{ ...filters, ...c };
    });
  } else {
    let aggs: any = null;
    let root: any = null;
    let queryFilter: any = null;
    criteria.forEach((c) => {
      if (c.should) {
        if (!filters.must) {
          filters.must = [];
        }
        //add a bool for each should
        let bool: any = {};
        bool.bool = {};
        bool.bool.should = [...c.should];
        bool.bool.minimum_should_match = getMinimumShouldMatch(c);
        filters.must = [...filters.must, bool];
      }
      if (c.filter) {
        if (!queryFilter) {
          queryFilter = {};
        }
        queryFilter = { ...queryFilter, ...c.filter };
      }
      if (c.must) {
        if (!filters.must) {
          filters.must = [];
        }
        filters.must = [...filters.must, ...c.must];
      }
      if (c.must_not) {
        if (!filters.must_not) {
          filters.must_not = [];
        }
        filters.must_not = [...filters.must_not, ...c.must_not];
      }
      if (c.aggs) {
        aggs = c;
      }
      if (c.root) {
        root = c.root;
      }
      if (c.bool) {
        if (!filters.must) {
          filters.must = [];
        }
        filters.must = [...filters.must, c];
      }
      if (c.nested) {
        if (!filters.must) {
          filters.must = [];
        }
        filters.must = [...filters.must, c];
      }
    });

    if (queryFilter) {
      filters = { query: { bool: { ...filters, ...{ filter: queryFilter } } } };
    } else {
      filters = { query: { bool: filters } };
    }
    if (aggs) {
      filters = { ...filters, ...aggs };
    }
    if (root) {
      filters = { ...filters, ...root };
    }
  }

  return { filter: filters };
};

export const getMinimumShouldMatch = (criteria: any) => {
  return isParticipationKey(criteria) ? criteria.should.length : criteria.minimum_should_match || 1;
};

const isParticipationKey = (criteria: any) => {
  return criteria.should[0].match_phrase?.hasOwnProperty('voter_history.key');
};

export interface FilterParameterOverrides {
  [key: string]: string[];
}

export const useFilterQueryCriteriaFromUrl = (
  searchParams: URLSearchParams,
  source: DataSource,
  paramOverrides?: FilterParameterOverrides
) => {
  return useMemo(
    () => getFilterQueryCriteriaFromUrl(searchParams, source, [], paramOverrides),
    [searchParams, source, paramOverrides]
  );
};

export const getFilterQueryCriteria = (paramOverrides: FilterParameterOverrides, source: DataSource) => {
  return getFilterQueryCriteriaFromUrl(new URLSearchParams(), source, [], paramOverrides);
};

export const queryParameters = [
  {
    param: 'state-eq',
    var: '[state]',
    filter: 'state: {equalTo: "[state]"}'
  },
  {
    param: 'type-eq',
    var: '[type]',
    filter: 'type: {in: [type]}',
    in: true
  },
  {
    param: 'category-eq',
    var: '[category]',
    filter: 'category: {in: [category]}',
    in: true
  },
  {
    param: 'subcategory-eq',
    var: '[subcategory]',
    filter: 'subcategory: {in: [subcategory]}',
    in: true
  },
  {
    param: 'courtType-eq',
    var: '[courtType]',
    filter: 'courtType: {equalTo: "[courtType]"}'
  },
  {
    param: 'status-eq',
    var: '[status]',
    filter: 'status: {equalTo: "[status]"}'
  },
  {
    param: 'judgeName-eq',
    var: '[judgeName]',
    filter: 'judgeName: {equalTo: "[judgeName]"}'
  },
  {
    param: 'courtName-eq',
    var: '[courtName]',
    filter: 'courtName: {equalTo: "[courtName]"}'
  },
  {
    param: 'partyName-eq',
    var: '[partyName]',
    filter: 'partyName: {equalTo: "[partyName]"}'
  },
  {
    param: 'officeName-eq',
    var: '[officeName]',
    filter: 'officeName: {equalTo: "[officeName]"}'
  },
  {
    param: 'elecYear-eq',
    var: '[elecYear]',
    filter: 'elecYear: {equalTo: "[elecYear]"}'
  },
  {
    param: 'lawFirmName-clf',
    var: '[lawFirmName]',
    filter:
      'viewLawfirmsByCasesByCaseIdConnection: {some: {lawFirmName: {equalTo: "[lawFirmName]"}, and: {type: {equalTo: "[lawFirmType]"}}}}'
  },
  {
    param: 'lawFirmType-clf',
    var: '[lawFirmType]',
    filter: ', and: {type: {equalTo: "[lawFirmType]"}}'
  },
  {
    param: 'school-eq',
    var: '[school]',
    filter: 'school: {equalTo: ["school"]}'
  },
  {
    param: 'title-eq',
    var: '[title]',
    filter: 'title: {equalTo: ["title"]}'
  }
];

export const parseQueryParameters = (searchParams: URLSearchParams, query: string) => {
  let clensedQuery = query;

  queryParameters.forEach(function (p) {
    const param = searchParams.getAll(p.param);
    if (param.length > 0) {
      if (p.in) {
        clensedQuery = clensedQuery.replace(p.var, `["${param.join('","')}"]`);
      } else {
        clensedQuery = clensedQuery.replace(p.var, param[0] || '');
      }
    } else {
      clensedQuery = clensedQuery.replace(p.filter + ',', '');
      clensedQuery = clensedQuery.replace(p.filter, '');
    }
  });

  return clensedQuery;
};

export const loadDefaultSort = (searchParams: URLSearchParams, sort: string[], defaultSort: string[]) => {
  if (sort.length === 0) {
    defaultSort.forEach((s) => {
      sort.push(s);
      if (!searchParams.getAll('sort').includes(s)) {
        searchParams.append('sort', s);
      }
    });
  }
  return sort;
};

export type DataSource = 'db' | 'elasticSearch';

export const getEsAggregationQuery = (
  searchParams: URLSearchParams,
  params: string[],
  key: string,
  label: string,
  key2?: string,
  label2?: string
) => {
  let filter: any = {
    query: { bool: { filter: [{ exists: { field: key } }] } },
    aggs: {
      results: {
        composite: {
          sources: [{ label: { terms: { field: label } } }, { value: { terms: { field: key } } }],
          size: 10000
        }
      }
    },
    size: 0
  };
  if (key2) {
    filter.aggs.results.composite.sources.push(
      { label2: { terms: { field: label2 } } },
      { value2: { terms: { field: key2 } } }
    );
  }

  params.forEach((p) => {
    const valueText = searchParams.get(p);
    if (valueText) {
      if (p.split('-').length > 1 && p.split('-')[1] === 'iic') {
        //wildcard search
        filter.query.bool.filter.push({
          wildcard: { [toSnakeCase(p.split('-')[0])]: { value: valueText, case_insensitive: true } }
        });
      } else {
        filter.query.bool.filter.push({ term: { [toSnakeCase(p.split('-')[0])]: valueText } });
      }
    }
  });

  return filter;
};

export const getEsOptions = (esResult: any) => {
  const options: any = [];

  esResult?.aggregations?.results?.buckets?.forEach((b) => {
    const option = { keys: [b.key.value, b.key.label], sum: { total: b.doc_count } };
    if (b.key.label2) {
      option.keys.push(b.key.label2);
      option.keys.push(b.key.value2);
    }
    options.push(option);
  });

  return options;
};
