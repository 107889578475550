import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const PENSIONS_LISTING = gql`
  query PensionsListing($filter: MViewPensionFilter!, $offset: Int!, $orderBy: [MViewPensionsOrderBy!]) {
    pensions: mViewPensions(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        organizationId
        name
        state
        members
      }
      totalCount
    }
  }
`;

export const usePensionsQuery = (offset: number, sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  useEffect(() => {
    if (sort.length < 1) {
      sort = loadDefaultSort(searchParams, sort, ['NAME_ASC']);
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const filter = {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: '2' }
  };

  const result = useQuery(PENSIONS_LISTING, filter);

  return result;
};
const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewPensions {
      totalCount
    }
  }
`;

export const usePensionsTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

const PENSIONS_FOR_DOWNLOAD = gql`
  query PensionsDownload(
    $first: Int!
    $filter: MViewPersonPensionFilter!
    $offset: Int!
    $orderBy: [MViewPersonPensionsOrderBy!]
  ) {
    csv: mViewPersonPensions(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        name
        state
        members
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: PENSIONS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};

const STATES_FOR_PENSION_QUERY = gql`
  query StatesQuery {
    states: mViewPensions(filter: { state: { isNull: false } }) {
      groupedAggregates(groupBy: [STATE]) {
        keys
        sum: distinctCount {
          total: organizationId
        }
      }
    }
  }
`;

export const useStatesForPensions = () => {
  return useLazyQuery(STATES_FOR_PENSION_QUERY, { context: { version: '2' } });
};
