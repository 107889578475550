import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const SCHOOLS_LISTING = gql`
  query SchoolsListing($filter: MViewSchoolFilter!, $offset: Int!, $orderBy: [MViewSchoolsOrderBy!]) {
    schools: mViewSchoolsConnection(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        name
        type
        numJudges
        numLawyers
        state
        city
      }
      totalCount
    }
  }
`;

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: mViewSchoolsConnection(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useStateSchoolCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY);
};

export const useAllSchoolsListingQuery = (offset: number, sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  useEffect(() => {
    if (sort.length < 1) {
      sort = loadDefaultSort(searchParams, sort, ['NAME_ASC']);
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const result = useQuery(SCHOOLS_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

const SCHOOLS_FOR_DOWNLOAD = gql`
  query SchoolsDownload($first: Int!, $filter: MViewSchoolFilter!, $offset: Int!, $orderBy: [MViewSchoolsOrderBy!]) {
    csv: mViewSchoolsConnection(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        name
        state
        city
        type
        numJudges
        numLawyers
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: SCHOOLS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewSchoolsConnection {
      totalCount
    }
  }
`;

export const useSchoolsTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, {});
};
