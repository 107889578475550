import { toSnakeCase } from '../../utils/format';

export const yearDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return {
    [field]: {
      lessThanOrEqualTo: filterValue[0] + '-12-31',
      greaterThanOrEqualTo: filterValue[0] + '-01-01'
    }
  };
};

export const yearEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return {
    must: [
      {
        range: {
          [snkField]: {
            lte: filterValue[0] + '-12-31',
            gte: filterValue[0] + '-01-01'
          }
        }
      }
    ]
  };
};
