import { isNil, without } from 'lodash';

export const raceDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  throw new Error('raceDbFilter is not supported');
};

const races = {
  w: 'pct_white',
  b: 'pct_black',
  a: 'pct_asian',
  l: 'pct_latino',
  n: 'pct_native'
};

const pctFields: string[] = ['pct_white', 'pct_black', 'pct_asian', 'pct_latino', 'pct_native'];

export const raceEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const targetRaceField = races[filterValue[0]];

  if (isNil(targetRaceField)) {
    return {};
  }

  const otherRaceFields = without(pctFields, targetRaceField);

  const clauses = otherRaceFields.map((f: string) => ({
    script: {
      script: `doc['${targetRaceField}'].value >= doc['${f}'].value`
    }
  }));

  return { must: clauses };
};
