export const organizationDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return filterValue.length > 0
    ? {
        or: [
          {
            name: { likeInsensitive: '%' + filterValue[0] + '%' }
          }
        ]
      }
    : {};
};
export const organizationEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (filterValue[0].length <= 1) {
    return {};
  }

  const tokens = filterValue[0].split(' ');

  let resp = ``;
  tokens.forEach((token) => {
    if (resp) {
      resp += `,`;
    }
    resp +=
      `{
      "query_string": {
        "query": "*` +
      token +
      `*",
        "fields": ["name_text"]
      }
    }`;
  });
  resp = `{"must":[` + resp + `]}`;

  return JSON.parse(resp);
};
