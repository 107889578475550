import { toSnakeCase } from '../../utils/format';

export const notInDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (filterValue.length < 1 || filterValue[0] === '') {
    return {};
  }

  return {
    [field]: {
      notIn: filterValue[0]
    }
  };
};

export const notInEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);

  if (filterValue.length < 1 || filterValue[0] === '') {
    return {};
  }
  return {
    must_not: [
      {
        [snkField]: {
          values: filterValue
        }
      }
    ]
  };
};
