import BTable, { BaseTableProps, Column, callOrReturn } from '@jc99ta/react-base-table';
import { isNil } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import SelectionCell from './SelectionCell';

export interface TableProps extends BaseTableProps {
  loading?: boolean;
  emptyMessage?: string;
  selectable?: boolean;
  allowSelectAll?: boolean;
  selectedRowKeys?: any[];
  setSelectedRowKeys?: any;
  totalCount?: number;
  allowResize?: boolean;
  rowSelectable?: Function;
}
const Table = styled(
  ({
    selectable,
    allowSelectAll,
    selectedRowKeys,
    setSelectedRowKeys,
    loading,
    totalCount,
    emptyMessage,
    headerHeight,
    width,
    allowResize,
    columns,
    height,
    selectionColumnProps,
    rowSelectable,
    notSelectableElement,
    ...props
  }: TableProps) => {
    const handleSelectChange = useCallback(
      ({ selected, rowData, rowIndex }) => {
        const keys = [...(selectedRowKeys || [])];
        const key = rowData[props.rowKey!];

        if (selected) {
          if (!keys.includes(key)) keys.push(key);
        } else {
          const index = keys.indexOf(key);
          if (index > -1) {
            keys.splice(index, 1);
          }
        }

        // if `selectedRowKeys` is uncontrolled, update internal state
        setSelectedRowKeys(keys);
        if (!isNil(props.onRowSelect)) {
          props.onRowSelect({ selected, rowData, rowIndex, selectedRowKeys: keys });
        }
        if (!isNil(props.onSelectedRowsChange)) {
          props.onSelectedRowsChange(keys);
        }
      },
      [selectedRowKeys, props, setSelectedRowKeys]
    );

    const calcHeight: number = useMemo(() => {
      return (height || 600) - 6;
    }, [height]);

    const rowClassName = useCallback<any>(
      ({ rowData, rowIndex }) => {
        if (!selectable) {
          return '';
        }
        const { rowClassName, rowKey } = props;

        const rowClass = rowClassName ? callOrReturn(rowClassName, { rowData, rowIndex }) : '';
        const key = rowData[rowKey!];

        return [rowClass, (selectedRowKeys || []).includes(key) && 'row-selected'].filter(Boolean).concat(' ');
      },
      [props, selectedRowKeys, selectable]
    );

    const cols = useMemo(() => {
      (columns || []).forEach((c: any) => {
        c.minWidth = 175;
        if (allowResize === false) {
          c.resizable = false;
        }
        // c.maxWidth = 600;
      });
      if (selectable) {
        const selectionColumn = {
          width: 40,
          flexShrink: 0,
          resizable: false,
          frozen: Column.FrozenDirection.LEFT,
          cellRenderer: (props: any) => (
            <SelectionCell {...props} rowSelectable={rowSelectable} notSelectableElement={notSelectableElement} />
          ),
          ...selectionColumnProps,
          key: '__selection__',
          rowKey: props.rowKey,
          selectedRowKeys: selectedRowKeys,
          onChange: handleSelectChange
        };
        return [selectionColumn, ...(columns || [])];
      }

      return columns;
    }, [columns, selectable, selectedRowKeys, handleSelectChange, props.rowKey, selectionColumnProps, allowResize]);

    return (
      <BTable
        id="table"
        fixed
        width={width}
        headerHeight={headerHeight || 85}
        height={height}
        columns={cols}
        rowClassName={rowClassName}
        emptyRenderer={<EmptyRenderer loading={loading} message={emptyMessage || 'No matching results'} />}
        {...props}
        overlayRenderer={
          loading ? (
            <>
              <EmptyRenderer message={'Loading...'}></EmptyRenderer>
            </>
          ) : null
        }
      />
    );
  }
)`
  box-shadow: none;
  .table__empty {
    text-align: center;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    width: 100%;
  }
  .BaseTable__row {
    transition: background-color 0.25s;
    &:nth-child(even) {
      background-color: var(--table-row-even-bg);
    }
    &:nth-child(odd) {
      background-color: var(--table-row-odd-bg);
    }
    &:hover {
      background-color: var(--table-row-hover-bg);
    }
    &.row-selected {
      background-color: var(--table-row-selected-bg) !important;
    }
  }
  .BaseTable__table-main {
    outline: none;
    // max-width: 100% !important;
    // .BaseTable__body {
    //   overflow: visible !important;
    // }
  }

  .BaseTable__header {
    // overflow: visible !important;
    .BaseTable__header-row {
      background-color: var(--white);
      .BaseTable__header-cell {
        align-items: flex-start;
        padding-top: 10px;
        background-color: transparent;
        .BaseTable__header-cell-text {
          font-size: 12px;
          color: var(--table-header-color);

          .btn {
            height: var(--input-height);
            padding: var(--input-padding);
            width: calc(100% - 15px) !important;
            border-radius: 0.35em;
          }
          .form-select-input,
          .btn {
            width: calc(100% - 30px);
            position: absolute;
            left: 8px;
            top: 38px;
            .form-select__control,
            .form-select__control.form-select__control--is-focused {
              box-shadow: none;
              border: var(--table-search-input-border);
              font-size: 12px;

              background-color: transparent;

              outline: 0;
              font-weight: normal;

              &::placeholder {
                color: var(--plaudit);
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }
`;

export const EmptyRenderer: React.FC<ClassNameProps & { loading?: boolean; message: string }> = styled(
  ({ loading, className, message }) => {
    return loading ? null : <div className={`empty-table ${className}`}>{message}</div>;
  }
)`
  width: 30%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: var(--primary);
  background-color: var(--white);
  padding: 20px;
  border-radius: var(--border-radius-sm);
`;

export const TableShadow = styled.div`
  box-shadow: var(--panel-box-shadow);
  border-radius: 16px;
  // overflow:hidden;
`;

export interface TableWrapProps extends ClassNameProps {
  withFatDetail?: boolean;
  withThinDetail?: boolean;
  withDetail?: boolean;
}
export const TableWrap = styled(({ className, withDetail, withFatDetail, withThinDetail, ...rest }: TableWrapProps) => {
  return (
    <div
      className={`${className} table-wrap ${withFatDetail ? 'table-wrap--with-fat-detail' : ''} ${
        withThinDetail ? 'table-wrap--with-thin-detail' : ''
      } ${withDetail ? 'table-wrap--with-detail' : ''}`}
      {...rest}
    />
  );
})`
  height: calc(100vh - var(--header-height) - 215px);
  border-radius: var(--border-radius-sm);
  // overflow-x: auto;

  @media (max-width: 1400.98px) {
    height: max(300px, calc(100vh - var(--header-height) - 145px));
    //  overflow-x: auto;
  }

  @media (max-width: 767.98px) {
    height: max(300px, calc(100vh - var(--header-height) - 145px));
  }

  &.table-wrap--with-detail {
    height: calc(100vh - var(--header-height) - 430px);

    @media (max-width: 1400.98px) {
      height: max(300px, calc(100vh - var(--header-height) - 430px));
      overflow-x: auto;
    }

    @media (max-width: 767.98px) {
      height: max(300px, calc(100vh - var(--header-height) - 430px));
    }
  }

  &.table-wrap--with-thin-detail {
    height: calc(100vh - var(--header-height) - 355px);

    @media (max-width: 1400.98px) {
      height: max(300px, calc(100vh - var(--header-height) - 355px));
      overflow-x: auto;
    }

    @media (max-width: 767.98px) {
      height: max(300px, calc(100vh - var(--header-height) - 355px));
    }
  }
  &.table-wrap--with-fat-detail {
    height: calc(100vh - var(--header-height) - 515px);

    @media (max-width: 1400.98px) {
      height: max(300px, calc(100vh - var(--header-height) - 515px));
      overflow-x: auto;
    }

    @media (max-width: 767.98px) {
      height: max(300px, calc(100vh - var(--header-height) - 515px));
    }
  }
`;

export default Table;
