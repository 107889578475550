import React, { memo } from 'react';
import styled from 'styled-components';
export interface InfoProps {
  className?: string;
  children?: React.ReactNode;
  tall?: boolean;
}
const Info = styled(
  memo(({ className, children, tall }: InfoProps) => {
    return <div className={`${className} information information--${tall ? 'tall' : 'normal'}`}>{children}</div>;
  })
)`
  color: var(--info-color);
  margin: 0 0 40px;
  padding: 0;
  border-radius: var(--border-radius-sm);
  z-index: 10;
  position: relative;
  overflow: hidden;
  height: 170px;
  a {
    color: var(--gray);
    &:hover {
      color: var(--bay-view);
    }
  }
  &.information--tall {
    height: 270px;
  }
`;

export const InfoDetailWrap = styled.div`
  background-color: var(--info-bg);
  padding: 20px;
  line-height: 18px;
  padding-left: 60px;
  overflow-y: auto;
  max-height: 100%;
  height: 100%;
  font-size: 13px;

  @media (max-width: 767.98px) {
    max-width: 100%;
  }
`;

export default Info;
