import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const DORMITORY_LISTING = gql`
  query DormitoryListing($filter: ViewDormitoryFilter!, $offset: Int!, $orderBy: [ViewDormitoriesOrderBy!]) {
    dormitory: viewDormitories(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        name
        universityId
        university
        state
        voterCount
      }
      totalCount
    }
  }
`;

export const useDormitoriesQuery = (offset: number, sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  useEffect(() => {
    if (sort.length < 1) {
      sort = loadDefaultSort(searchParams, sort, ['NAME_ASC']);
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const filter = {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: '2' }
  };

  const result = useQuery(DORMITORY_LISTING, filter);

  return result;
};

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: viewDormitories(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useDormitoryStatesQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY, { context: { version: '2' } });
};

const UNIVERSITIES_QUERY = gql`
  query Universities($filter: ViewDormitoryFilter!) {
    universities: viewDormitories(filter: $filter) {
      groupedAggregates(groupBy: [UNIVERSITY_ID, UNIVERSITY, STATE]) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useDormitoryUniversities = () => {
  return useLazyQuery(UNIVERSITIES_QUERY, { context: { version: '2' } });
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewDormitories {
      totalCount
    }
  }
`;

export const useDormitoryTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

const DORMITORIES_FOR_DOWNLOAD = gql`
  query DormitoryDownload(
    $first: Int!
    $filter: ViewDormitoryFilter!
    $offset: Int!
    $orderBy: [ViewDormitoriesOrderBy!]
  ) {
    csv: viewDormitories(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        name
        university
        state
        voterCount
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: DORMITORIES_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};
