import { toSnakeCase } from '../../utils/format';

export const participationDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  console.error('participationDbFilter is not supported');
};

export const participationEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  const voted: boolean = filterValue[0].endsWith('*voted*');

  return voted
    ? {
        must: [
          {
            bool: {
              must: [
                {
                  query_string: {
                    default_field: snkField,
                    query: filterValue[0].replace('voted*', '')
                  }
                }
              ]
            }
          },
          {
            bool: {
              must_not: [
                {
                  query_string: {
                    default_field: snkField,
                    query: filterValue[0].replace('*voted*', 'did_not_vote')
                  }
                }
              ]
            }
          }
        ]
      }
    : {
        must: [
          {
            query_string: {
              default_field: snkField,
              query: filterValue[0]
            }
          }
        ]
      };
};
