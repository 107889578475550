import { PRIMARY, PRIMARY_ALT, getLatestElectionYear } from '../../utils/propensity';

// rpa = Roseland Party Affiliation

const REPUBLICAN = 'rep';
const DEMOCRAT = 'dem';
const VOTER_HISTOTY_KEY = 'voter_history.key.keyword';

export const rpaDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  console.error('rpaDbFilter is not supported');
};

export const rpaEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  let year = getLatestElectionYear();

  const party = filterValue[0] === 'd' ? DEMOCRAT : REPUBLICAN;
  const non_party = filterValue[0] === 'd' ? REPUBLICAN : DEMOCRAT;

  return {
    must: [
      {
        bool: {
          should: [
            {
              prefix: {
                party_affiliation: {
                  value: party,
                  case_insensitive: true
                }
              }
            },
            {
              bool: {
                should: [
                  {
                    bool: {
                      must: [
                        {
                          bool: {
                            should: [
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year}_${PRIMARY}_*${party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year}_${PRIMARY_ALT}_*${party}*`
                                }
                              }
                            ],
                            minimum_should_match: 1
                          }
                        }
                      ]
                    }
                  },
                  {
                    bool: {
                      must: [
                        {
                          bool: {
                            should: [
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 2}_${PRIMARY}_*${party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 2}_${PRIMARY_ALT}_*${party}*`
                                }
                              }
                            ],
                            minimum_should_match: 1
                          }
                        },
                        {
                          bool: {
                            must_not: [
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year}_${PRIMARY}_*${non_party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year}_${PRIMARY_ALT}_*${non_party}*`
                                }
                              }
                            ]
                          }
                        }
                      ]
                    }
                  },
                  {
                    bool: {
                      must: [
                        {
                          bool: {
                            should: [
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 4}_${PRIMARY}_*${party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 4}_${PRIMARY_ALT}_*${party}*`
                                }
                              }
                            ],
                            minimum_should_match: 1
                          }
                        },
                        {
                          bool: {
                            must_not: [
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year}_${PRIMARY}_*${non_party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 2}_${PRIMARY}_*${non_party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year}_${PRIMARY_ALT}_*${non_party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 2}_${PRIMARY_ALT}_*${non_party}*`
                                }
                              }
                            ]
                          }
                        }
                      ]
                    }
                  },
                  {
                    bool: {
                      must: [
                        {
                          bool: {
                            should: [
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 6}_${PRIMARY}_*${party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 6}_${PRIMARY_ALT}_*${party}*`
                                }
                              }
                            ],
                            minimum_should_match: 1
                          }
                        },
                        {
                          bool: {
                            must_not: [
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year}_${PRIMARY}_*${non_party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 2}_${PRIMARY}_*${non_party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 4}_${PRIMARY}_*${non_party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year}_${PRIMARY_ALT}_*${non_party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 2}_${PRIMARY_ALT}_*${non_party}*`
                                }
                              },
                              {
                                query_string: {
                                  default_field: VOTER_HISTOTY_KEY,
                                  query: `*${year - 4}_${PRIMARY_ALT}_*${non_party}*`
                                }
                              }
                            ]
                          }
                        }
                      ]
                    }
                  }
                ],
                minimum_should_match: 1
              }
            }
          ]
        }
      }
    ]
  };
};
