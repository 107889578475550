import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const JUDGE_FRAGMENT = gql`
  fragment Judge on MViewJudge {
    id
    firstName
    lastName
    middleName
    suffix
    dateOfBirth
    hometown
    state
    courtId
    courtName
    viewUniversitiesByJudgesByJudgeIdConnection {
      nodes {
        years
        universityId
        universityName
      }
    }
    viewLawschoolsByJudgesByJudgeIdConnection {
      nodes {
        lawschoolId
        lawschoolName
        years
      }
    }
  }
`;

const JUDGES_LISTING = gql`
  query JudgesListing($filter: MViewJudgeFilter!, $offset: Int!, $orderBy: [MViewJudgesOrderBy!]) {
    judges: mViewJudgesConnection(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Judge
      }
      totalCount
    }
  }
  ${JUDGE_FRAGMENT}
`;

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: mViewJudgesConnection(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useStateJudgesCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY);
};

export const useAllJudgesListingQuery = (offset: number, sort: string[]) => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  if (sort.length < 1) {
    sort = ['name_ASC'];
  }
  if (sort.indexOf('name_ASC') > -1) {
    sort.splice(sort.indexOf('name_ASC'), 1, 'LAST_NAME_ASC', 'FIRST_NAME_ASC');
  }
  if (sort.indexOf('name_DESC') > -1) {
    sort.splice(sort.indexOf('name_DESC'), 1, 'LAST_NAME_DESC', 'FIRST_NAME_DESC');
  }

  const result = useQuery(JUDGES_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

const JUDGES_FOR_DOWNLOAD = gql`
  query JudgesDownload($first: Int!, $filter: MViewJudgeFilter!, $offset: Int!, $orderBy: [MViewJudgesOrderBy!]) {
    csv: mViewJudgesConnection(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Judge
      }
    }
  }
  ${JUDGE_FRAGMENT}
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: JUDGES_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC', 'FIRST_NAME_ASC']
    }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewJudgesConnection {
      totalCount
    }
  }
`;

export const useJudgesTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, {});
};
