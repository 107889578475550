import React from 'react';
import styled from 'styled-components';
import { MESSAGING_ROLE } from '../../utils/auth';
import A from '../A';
import RequiresRoles from '../Security/RequiresRoles';
import { Nav } from './NavBar';
import { useLocation } from 'react-router-dom';
import { faBookOpen, faComment } from '@fortawesome/free-solid-svg-icons';
import Icon from '../Icon';

const AppSelectorNav = styled(({ className }) => {
  const location = useLocation();
  const active = (test: string) => {
    return location.pathname.indexOf(`/${test}`) > -1 ? 'active' : '';
  };

  return (
    <RequiresRoles roles={[MESSAGING_ROLE]}>
      <Nav className={`${className} left-nav__segment`}>
        <A to="/app" className={active('app')} title="Reference">
          <Icon icon={faBookOpen} /> <span className="tab__label">Reference</span>
        </A>
        <A to="/messaging" className={active('messaging')} title="Outreach">
          <Icon icon={faComment} /> <span className="tab__label">Outreach</span>
        </A>
      </Nav>
    </RequiresRoles>
  );
})`
  &.nav.left-nav__segment {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--filter-bg);
    color: var(--left-nav-color);
    width: 100%;

    display: flex;
    flex-direction: row;
    gap: 0;
    flex-basis: 50%;
    align-content: flex-start;
    justify-content: start;
    align-items: stretch;
    line-height: 40px;
    font-weight: 800;
    a {
      padding: 0 20px;
      width: 50%;
      display: block;
      font-size: 16px;
      color: var(--left-nav-color);
      &.active {
        background-color: var(--left-nav-bg);
        color: var(--left-nav-color-active);
      }
      &:last-child {
        padding-right: 0;
      }
    }
    & ~ .nav {
      margin-top: 32px;
    }
  }
`;

export default AppSelectorNav;
