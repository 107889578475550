import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import React, { memo } from 'react';

const Icon = styled(
  memo(({ ...props }: FontAwesomeIconProps) => {
    return <FontAwesomeIcon {...props} />;
  })
)``;

export default Icon;
