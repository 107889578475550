import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { DataSource, loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const MOST_RECENT_SALARIES_LISTING = gql`
  query HigherEducationSalariesListing(
    $filter: MViewHigherEdSalariesWithVoterMostRecentFilter!
    $offset: Int!
    $orderBy: [MViewHigherEdSalariesWithVoterMostRecentsOrderBy!]
  ) {
    salaries: mViewHigherEdSalariesWithVoterMostRecentsConnection(
      first: 150
      filter: $filter
      offset: $offset
      orderBy: $orderBy
    ) {
      nodes {
        firstName
        middleName
        lastName
        state
        department
        title
        employmentType
        school
        salary
        voterId
        year
        suid
        id
      }
      totalCount
    }
  }
`;

const HIGHER_ED_JOB_TITLES = gql`
  query HigherEducationDistinctJobTitles($filter: ViewHigherEdSchoolJobTitleFilter!) {
    titles: viewHigherEdSchoolJobTitlesConnection(filter: $filter) {
      totalCount
      nodes {
        title
      }
    }
  }
`;

const HIGHER_ED_SALARIES_COUNT = gql`
  query HigherEducationSalariesSchoolCounts {
    schools: viewHigherEdSchoolTotalsConnection(orderBy: SCHOOL_ASC) {
      aggregates {
        keys
        sum {
          total
        }
      }
      nodes {
        school
        total
      }
    }
  }
`;

const HIGHER_ED_STATES_QUERY = gql`
  query HigherEducationStates {
    options: mViewHigherEdSalariesWithVoterMostRecentsConnection(orderBy: STATE_ASC) {
      groupedAggregates(groupBy: STATE) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

const HIGHER_ED_YEARS_QUERY = gql`
  query HigherEducationYears {
    options: mViewHigherEdSalariesWithVoterMostRecentsConnection(orderBy: YEAR_ASC) {
      groupedAggregates(groupBy: YEAR) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

export const useHigherEdYearsQuery = () => {
  return useLazyQuery(HIGHER_ED_YEARS_QUERY);
};
export const useHigherEdStatesQuery = () => {
  return useLazyQuery(HIGHER_ED_STATES_QUERY);
};

export const useHigherEdSchoolSalariesCountQuery = () => {
  return useLazyQuery(HIGHER_ED_SALARIES_COUNT);
};

export const useMostRecentHigherEducationListingQuery = (offset: number, sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');
  correctUnknownSchoolFilter(queryCriteria);
  useEffect(() => {
    if (sort.length < 1) {
      sort = loadDefaultSort(searchParams, sort, ['LAST_NAME_ASC', 'FIRST_NAME_ASC']);
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const filter = {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    }
  };

  const result = useQuery(MOST_RECENT_SALARIES_LISTING, filter);

  return result;
};

export const useHigherEdJobTitlesQuery = (source: DataSource) => {
  const [searchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, source);
  correctUnknownSchoolFilter(queryCriteria);
  delete queryCriteria.filter.year;
  delete queryCriteria.filter.state;

  //No schools selected - return nothing
  let filter = queryCriteria.filter;

  //No schools selected - return nothing
  if (Object.keys(queryCriteria.filter).length === 0) {
    filter = {
      school: {
        in: ['']
      }
    };
  }

  return useLazyQuery(HIGHER_ED_JOB_TITLES, {
    variables: { filter }
  });
};

const correctUnknownSchoolFilter = (queryCriteria: any) => {
  if (queryCriteria?.filter?.school?.equalTo === 'Unknown') {
    delete queryCriteria.filter.school.equalTo;
    queryCriteria.filter.school['isNull'] = true;
  }
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewHigherEdSalariesWithVoterMostRecentsConnection {
      totalCount
    }
  }
`;

export const useHigherEdSalariesTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, {});
};

const HIGHER_ED_FOR_DOWNLOAD = gql`
  query HigherEdDownload(
    $first: Int!
    $filter: MViewHigherEdSalariesWithVoterMostRecentFilter!
    $offset: Int!
    $orderBy: [MViewHigherEdSalariesWithVoterMostRecentsOrderBy!]
  ) {
    csv: mViewHigherEdSalariesWithVoterMostRecentsConnection(
      first: $first
      filter: $filter
      offset: $offset
      orderBy: $orderBy
    ) {
      nodes {
        firstName
        middleName
        lastName
        state
        department
        title
        employmentType
        school
        salary
        voterId
        year
        suid
        id
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');
  correctUnknownSchoolFilter(queryCriteria);
  const csvProps: CsvProps = {
    query: HIGHER_ED_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC', 'FIRST_NAME_ASC']
    }
  };

  return csvProps;
};
