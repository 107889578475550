import { toSnakeCase } from '../../utils/format';

export const nestedDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  console.error('nestedDbFilter is not supported');
};
export const nestedEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const path = field.split('.')[0];
  const snkField = toSnakeCase(field);

  return {
    nested: {
      path: path,
      query: {
        bool: {
          must: [
            {
              match: { [snkField]: filterValue[0] }
            }
          ]
        }
      }
    }
  };
};
