import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const PPP_LOAN_FRAGMENT = gql`
  fragment PPPLoan on ViewPppLoan {
    id
    personId
    organizationId
    loanNumber
    borrowerName
    address
    city
    state
    zip
    loanStatusDate
    loanStatus
    currentApprovalAmount
    forgivenessAmount
  }
`;
const PPP_LOAN_LISTING = gql`
  query PPPLoansListing($filter: JSONObject!, $offset: Int!, $orderBy: [ViewPppLoansOrderBy!]) {
    result: viewPppLoans(first: 150, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...PPPLoan
      }
      totalCount
    }
  }
  ${PPP_LOAN_FRAGMENT}
`;

const STATES_QUERY = gql`
  query StatesQuery($filter: JSONObject!) {
    states: viewPppLoans(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useStatePPPCountQuery = () => {
  const filter: any = {
    aggs: {
      results: {
        terms: {
          field: 'state'
        }
      }
    },
    size: 0
  };
  const vars = { variables: { filter: filter }, context: { version: '2' } };

  return useLazyQuery(STATES_QUERY, vars);
};

export const useAllPPPLoansListingQuery = (offset: number, sort: string[]) => {
  const [searchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

  const result = useQuery(PPP_LOAN_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort.length < 1 ? ['BORROWER_NAME_ASC'] : sort
    },
    context: { version: '2' }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

const PPP_FOR_DOWNLOAD = gql`
  query PPPLoansListing($first: Int!, $filter: JSONObject!, $offset: Int!, $orderBy: [ViewPppLoansOrderBy!]) {
    csv: viewPppLoans(first: $first, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...PPPLoan
      }
    }
  }
  ${PPP_LOAN_FRAGMENT}
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

  const csvProps: CsvProps = {
    query: PPP_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['BORROWER_NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewPppLoans(elasticSearch: { index: "pppv2" }) {
      totalCount
    }
  }
`;

export const usePPPTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

const CITIES_FOR_PPP_QUERY = gql`
  query CitiesForState($filter: JSONObject!) {
    cities: viewPppLoans(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useCitiesForPpp = () => {
  return useLazyQuery(CITIES_FOR_PPP_QUERY, { context: { version: '2' } });
};
