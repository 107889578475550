import { gql, useQuery } from '@apollo/client';
import { toUpper } from 'lodash';

export interface Property {
  id?: string;
  owner?: string;
  legal?: string;
  unit?: string;
  propertyClass?: string;
  beds?: number;
  baths?: number;
  livingSf?: number;
  landSf?: number;
  parcelId?: string;
  propertyTax?: number;
  hasPool?: boolean;
  yearBuilt?: number;
  stories?: number;
  useCode?: string;
  floorPlan?: string;
  addressId?: string;
  address?: string;
  num?: string;
  street?: string;
  streetType?: string;
  dir?: string;
  postDir?: string;
  cityId?: string;
  city?: string;
  countyId?: string;
  county?: string;
  stateId?: string;
  state?: string;
  zip?: string;
  subdivisionId?: string;
  subdivision?: string;
  complexId?: string;
  complex?: string;
  lat?: number;
  lon?: number;
  voterCount?: number;
  pppCount?: number;
  // Not indexed
  neighborhood?: string;
  neighborhoodId?: string;
  parkDistrict?: string;
  parkDistrictId?: string;
  elementarySchoolDistrict?: string;
  elementarySchoolDistrictId?: string;
  secondarySchoolDistrict?: string;
  secondarySchoolDistrictId?: string;
  township?: string;
  townshipId?: string;
  precinct?: string;
  precinctId?: string;
  stateHouse?: string;
  stateHouseId?: string;
  stateSenate?: string;
  stateSenateId?: string;
  usHouse?: string;
  usHouseId?: string;
}

export const PROPERTY_FRAGMENT = gql`
  fragment Property on ViewProperty {
    id
    owner
    legal
    unit
    propertyClass
    beds
    baths
    livingSf
    landSf
    parcelId
    propertyTax
    hasPool
    yearBuilt
    stories
    useCode
    floorPlan
    addressId
    address
    num: houseNumber
    street: streetName
    streetType
    dir: preDirection
    postDir: postDirection
    cityId
    city
    countyId
    county
    stateId
    state
    zip
    subdivisionId
    subdivision
    complexId
    complex
    neighborhood
    neighborhoodId
    parkDistrict
    parkDistrictId
    elementarySchoolDistrict
    elementarySchoolDistrictId
    secondarySchoolDistrict
    secondarySchoolDistrictId
    township
    townshipId
    precinct
    precinctId
    lat
    lon
    voterCount
    pppCount
  }
`;

const PROPERTY_BY_PROPERTY_ID = gql`
  query PropertyByPropertyId($id: UUID) {
    property: viewProperties(condition: { id: $id }) {
      nodes {
        ...Property
      }
    }
  }
  ${PROPERTY_FRAGMENT}
`;

export const PROPERTY_LOOKUP = gql`
  query PropertiesLookup($filter: JSONObject!) {
    properties: viewProperties(first: 1, elasticSearch: $filter) {
      nodes {
        ...Property
      }
      totalCount
      totalAggCount
    }
  }
  ${PROPERTY_FRAGMENT}
`;

export const buildPropertyAddressLookupParams = (address: string, city: string, stateAbbr: string): any => {
  return { property: [toUpper(address)], 'city-eic': [toUpper(city)], 'state-eq': [stateAbbr] };
};

export const usePropertyByIdQuery = (id: string) => {
  return useQuery(PROPERTY_BY_PROPERTY_ID, { variables: { id: id }, context: { version: '2' } });
};
