import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { capitalize, get, isNil, toLower, toUpper, trim } from 'lodash';
import { useCallback, useMemo } from 'react';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { Property } from '../gql/property';
import { parseLinkVariables } from './form';
import { getStateName } from './states';
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export const getHumanizedDuration = (duration: number) => {
  return dayjs.duration(duration).humanize();
};

export const useNumberFormat = (options = {}, locale = 'en-US') => {
  const formatter = useMemo(() => {
    return new Intl.NumberFormat(locale, options);
  }, [locale, options]);

  return (value: number) => {
    return formatter.format(value);
  };
};

export const SIMPLE_DATE_FORMAT = 'MMMM DD, YYYY';
export const SIMPLE_DATE_FORMAT_WITH_TIME = 'MMMM DD, YYYY  hh:mm a';

export const dateFormat = (date: Date | string, format: string = SIMPLE_DATE_FORMAT) => {
  let dateOnly = date?.toString().split('T')[0];
  if (dayjs(dateOnly).isValid()) {
    // 6 digit date in the format of 202209 --> return September 2022
    if ('string' === typeof dateOnly && dateOnly.length === 6) {
      return dayjs(date).format('MMMM YYYY');
    }
    return dayjs(date).format(format);
  }
  return '';
};

export const useDateFormat = (format = SIMPLE_DATE_FORMAT) => {
  return useCallback(
    (date: Date | string) => {
      return dateFormat(date, format);
    },

    [ format]
  );
};

export const utcToLocalDataFormat = (date: Date | string, format: string = SIMPLE_DATE_FORMAT_WITH_TIME) => {
  let dateOnly = date?.toString().split('T')[0];
  if (dayjs(dateOnly).isValid()) {
    // 6 digit date in the format of 202209 --> return September 2022
    if ('string' === typeof dateOnly && dateOnly.length === 6) {
      return dayjs(date).utc(true).local().format('MMMM YYYY');
    }
    return dayjs(date).utc(true).local().format(format);
  }
  return '';
};

export const useUTCToLocalDateFormat = (format = 'MMMM DD, YYYY') => {
  return useCallback(
    (date: Date | string) => {
      return utcToLocalDataFormat(date, format);
    },
    [format]
  );
};

export const ageFromDate = (date?: string | Date | null) => {
  return isNil(date) ? '-' : dayjs().diff(date, 'year');
};

export const emptyValueFormat = (value?: string, placeholder: string = '-') => {
  return isNil(value) || value === '' ? placeholder : value;
};

export const formatPropertyAddress = (property: Property, clean?: boolean) => {
  return formatAddressFull(
    property.num,
    property.dir,
    property.street,
    property.streetType,
    property.postDir,
    property.unit,
    property.city,
    property.state,
    property.zip,
    clean ? '' : property.county,
    property.address,
    clean ? '' : property.township
  );
};

export const formatAddressFull = (
  num?: string | number,
  preDir?: string,
  street?: string,
  streetType?: string,
  postDir?: string,
  unit?: string,
  city?: string,
  state?: string,
  zip?: string | number,
  county?: string,
  address?: string,
  township?: string
) => {
  let formattedAddress = '';

  if (!isNil(street) && street?.trim()) {
    if (num && num.toString() !== 'null') {
      formattedAddress = formattedAddress.concat(formattedAddress ? ' ' : '', num.toString());
    }
    if (preDir?.trim() && 'null' !== preDir.toLowerCase()) {
      formattedAddress = formattedAddress.concat(formattedAddress ? ' ' : '', preDir.trim());
    }
    formattedAddress = formattedAddress.concat(formattedAddress ? ' ' : '', street.trim());
    if (streetType?.trim() && 'null' !== streetType.toLowerCase()) {
      formattedAddress = formattedAddress.concat(formattedAddress ? ' ' : '', streetType.trim());
    }
    if (postDir?.trim() && 'null' !== postDir.toLowerCase()) {
      formattedAddress = formattedAddress.concat(formattedAddress ? ' ' : '', postDir.trim());
    }
  } else if (!isNil(address)) {
    formattedAddress = formattedAddress.concat(address!);
  }

  unit = unit?.trim();
  if ('' !== unit && 'null' !== unit?.toLowerCase()) {
    formattedAddress = formattedAddress.concat(', ', unit?.trim() || '');
  }

  return formatAddress(formattedAddress, city, state, zip, county, unit, township);
};

export const properName = (name?: string) => {
  if (isNil(name)) {
    return '';
  }
  return name!.trim().split(' ').map(capitalize).join(' ');
};

const isNilOrEmpty = (str?: string) => {
  return isNil(str) || trim(str) === '' || trim(toLower(str)) === 'null';
};

export const personName = (firstName?: string, middleName?: string, lastName?: string, suffix?: string) => {
  return `${capitalize(firstName || '')}${isNilOrEmpty(middleName) ? '' : ' ' + capitalize(middleName)} ${capitalize(
    lastName || ''
  )}${isNilOrEmpty(suffix) ? '' : ', ' + capitalize(toLower(suffix))}`;
};

export const transformAddressData = (address?: string, unit?: any, stripCityStateZip?: boolean) => {
  if (isNil(address)) {
    return '';
  }
  if (stripCityStateZip) {
    address = address?.split(',')[0];
  }

  let formattedAddress = address;

  if (unit) {
    unit = isNaN(unit) ? ' - '.concat(unit) : ' - Unit '.concat(unit);
    formattedAddress = '';
    address?.split(',').forEach((a) => {
      formattedAddress += formattedAddress ? a : a.concat(unit || '');
    });
  }

  return formattedAddress!.trim().split(' ').map(capitalize).join(' ');
};

export const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value);
};

export const formatAddress = (
  address?: string,
  city?: string,
  state?: string,
  zip?: string | number,
  county?: string,
  unit?: string,
  township?: string
) => {
  let fields: string[] = [];

  if (!isNil(address) && address?.trim() && String(address) !== 'null') {
    fields = fields.concat(transformAddressData(address, unit, true));
  }
  if (!isNil(city) && city?.trim() && String(city) !== 'null') {
    fields = fields.concat(properName(city));
  }
  if (!isNil(state) && state?.trim() && String(state) !== 'null') {
    fields = fields.concat(toUpper(state.trim()));
  }
  if (!isNil(township) && township?.trim() && String(township) !== 'null') {
    fields = fields.concat(capitalize(township.trim()));
  }
  if (!isNil(county) && county?.trim() && String(county) !== 'null') {
    fields = fields.concat(capitalize(county.trim()));
  }
  if (!isNil(zip) && String(zip) !== 'null') {
    fields = fields.concat(String(zip));
  }
  return fields.join(', ');
};

export const formatName = (first?: string, last?: string, middle?: string, prefix?: string, suffix?: string) => {
  let fields: string[] = [];

  if (prefix?.trim() && 'null' !== toLower(prefix.trim())) {
    fields = fields.concat(capitalize(prefix.trim().toLowerCase()));
  }
  if (first?.trim() && 'null' !== toLower(first.trim())) {
    fields = fields.concat(capitalize(first.trim().toLowerCase()));
  }
  if (middle?.trim() && 'null' !== toLower(middle.trim())) {
    fields = fields.concat(capitalize(middle.trim().toLowerCase()));
  }

  let formattedLast = '';
  if (last) {
    formattedLast = last.trim().toLowerCase().split(' ').map(capitalize).join(' ');
    if (formattedLast.indexOf('-') > -1) {
      formattedLast = last.split('-').map(capitalize).join('-');
    }
    if (suffix?.trim() && 'null' !== toLower(suffix.trim())) {
      formattedLast = formattedLast.concat(' ', capitalize(suffix.trim().toLowerCase()));
    }
  }

  return fields.length > 0 ? formattedLast.concat(', ', fields.join(' ')) : formattedLast;
};

export const isNestedPath = (str: string): boolean => {
  return str.indexOf('.') > -1;
};

export const toSnakeCase = (str: string): string => {
  if (!str) {
    return '';
  }
  return (
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z.]+[0-9]*|\b)|[A-Z]?[a-z.]+[0-9]*|[A-Z]|[0-9]+/g)
      ?.map((x) => x.toLowerCase())
      .join('_') || ''
  );
};

export const toTitleCase = (str: string): string => {
  if (isNil(str)) {
    return '';
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const containsAllLinkVariables = (rowData: any, template: string) => {
  const vars: string[] = parseLinkVariables(template);
  let valid: boolean = true;
  vars.forEach((v: string) => {
    if (!(get(rowData, v) || get(rowData, v.split('.')[1]))) {
      valid = false;
      return;
    }
  });

  return valid;
};

export const interpolateLinkVariables = (rowData: any, template: string) => {
  const vars: string[] = parseLinkVariables(template);
  let result: string = template;
  vars.forEach((v: string) => {
    // first check the root of the result set, then check the row data to support nested results
    result = result.replace(`[${v}]`, get(rowData, v) || get(rowData, v.split('.')[1]));
  });

  return result;
};

export const formatDisplayValue = (
  { numberFormat, currencyFormat, dateFormat },
  format: any,
  formatType: string,
  textFormat: string,
  rowData: any,
  value?: any,
  transformers?: any
) => {
  let result = '';
  switch (formatType) {
    case 'currency':
      if (typeof value === 'string') {
        value = value.replace(',', '');
      }
      result = !isNil(value) ? currencyFormat(value) : '-';
      break;
    case 'date':
      result = !isNil(value) && value.length > 4 ? dateFormat(value) : !isNil(value) ? value : '-';
      break;
    case 'jsonDate':
      result = '-';
      if (!isNil(value) && value.year) {
        result =
          value.day && value.month && value.year
            ? dateFormat(value.year + '-' + value.month + '-' + value.day)
            : value.month
              ? value.month + '/' + value.year
              : value.year;
      }
      break;
    case 'age':
      result = !isNil(value) && value.length > 4 ? dayjs().diff(dayjs(value), 'year') + '' : '-';
      break;
    case 'numeric':
      result = !isNil(value) ? numberFormat(value) : '-';
      break;
    case 'state':
      result = !isNil(value) ? getStateName(value) || '' : '-';
      break;
    case 'boolean':
      result =
        String(value).toLowerCase() === 'true' || String(value).toLowerCase() === 'y' || value === true ? 'Yes' : 'No';
      break;
    case 'text':
      result = !isNil(value) && 'null' !== toLower(value) ? value : '-';
      if (textFormat && textFormat === 'lower') {
        result = result.toLowerCase();
      } else if (textFormat && textFormat === 'upper') {
        result = result.toUpperCase();
      } else if (textFormat && textFormat === 'capitalize') {
        result = toTitleCase(result);
      }
      break;
    case 'properName':
      result = formatName(
        get(rowData || {}, format.first),
        get(rowData || {}, format.last),
        get(rowData || {}, format.middle)
      );
      break;
    default:
      result = !isNil(value) && 'null' !== toLower(value) ? value : '-';
      break;
  }

  if (transformers && transformers[result]) {
    result = transformers[result];
  }

  return result;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleansedPhone = phoneNumber && phoneNumber.toUpperCase() === 'NULL' ? '' : phoneNumber;
  if (isNil(cleansedPhone) || !isValidPhoneNumber(cleansedPhone, 'US')) {
    return '';
  }
  const parsed = parsePhoneNumber(cleansedPhone, 'US');
  return parsed ? `+1 ${parsed.formatNational()}` : '';
};

interface RecordTypeLookup {
  [key: string]: string;
}

const recordTypes: RecordTypeLookup = {
  voting_addresses: 'Voting Address',
  public_offices: 'Public Office',
  candidates_for_offices: 'Candidate for Office',
  campaign_contributions: 'Campaign Contribution',
  property_sales: 'Property Sales',
  national_change_of_addresses: 'National Change of Address'
};

export const formatRecordType = (recordType: string): string => {
  return recordTypes[recordType] || recordType;
};
