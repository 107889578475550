import { toSnakeCase } from '../../utils/format';

export const factoidDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  console.error('factoidDbFilter is not supported');
};

export const factoidEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return {
    must: [
      {
        match_phrase: {
          [snkField]: {
            query: filterValue[0]
          }
        }
      }
    ]
  };
};
