import React from 'react';
import { hasRole } from '../../utils/auth';

const RequiresRoles: React.FC<{ roles: string[]; children: any }> = ({ roles, children }) => {
  if (roles.some(hasRole)) {
    return children;
  }
  return null;
};

export default RequiresRoles;
