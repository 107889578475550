export function getCountyAggregateQuery() {
  let query: any = {
    aggs: {
      state: {
        terms: {
          field: 'state',
          size: 50
        },
        aggs: {
          county_id: {
            terms: {
              field: 'county_id',
              size: 1000
            },
            aggs: {
              county_name: {
                terms: {
                  field: 'county_name',
                  size: 1000
                },
                aggs: {
                  year: {
                    terms: {
                      field: 'year',
                      size: 10
                    },
                    aggs: {
                      party: {
                        terms: {
                          field: 'party',
                          size: 1000
                        },
                        aggs: {
                          vote_totals: {
                            sum: {
                              field: 'votes'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    size: 0
  };

  return query;
}

export function getPrecinctAggregateQuery() {
  let query: any = {
    aggs: {
      state: {
        terms: {
          field: 'state',
          size: 50
        },
        aggs: {
          precinct_name: {
            terms: {
              field: 'precinct_name',
              size: 1000
            },
            aggs: {
              year: {
                terms: {
                  field: 'year',
                  size: 10
                },
                aggs: {
                  party: {
                    terms: {
                      field: 'party',
                      size: 1000
                    },
                    aggs: {
                      vote_totals: {
                        sum: {
                          field: 'votes'
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    size: 0
  };

  return query;
}

export function getTownshipAggregateQuery() {
  let query: any = {
    aggs: {
      state: {
        terms: {
          field: 'state',
          size: 50
        },
        aggs: {
          township_id: {
            terms: {
              field: 'township_id',
              size: 1000
            },
            aggs: {
              township_name: {
                terms: {
                  field: 'township_name',
                  size: 1000
                },
                aggs: {
                  year: {
                    terms: {
                      field: 'year',
                      size: 10
                    },
                    aggs: {
                      party: {
                        terms: {
                          field: 'party',
                          size: 1000
                        },
                        aggs: {
                          vote_totals: {
                            sum: {
                              field: 'votes'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    size: 0
  };

  return query;
}

export function getSummaryQuery() {
  let query: any = {
    aggs: {
      year: {
        terms: {
          field: 'year',
          size: 10
        },
        aggs: {
          party: {
            terms: {
              field: 'party',
              size: 100
            },
            aggs: {
              candidate_name: {
                terms: {
                  field: 'candidate_name',
                  size: 100
                },
                aggs: {
                  vote_totals: {
                    sum: {
                      field: 'votes'
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    size: 0
  };

  return query;
}

export function getContestAggregateQuery() {
  let query: any = {
    aggs: {
      state: {
        terms: {
          field: 'state',
          size: 50
        },
        aggs: {
          type: {
            terms: {
              field: 'type',
              size: 2
            },
            aggs: {
              contest_category: {
                terms: {
                  field: 'contest_category',
                  size: 1000
                },
                aggs: {
                  year: {
                    terms: {
                      field: 'year',
                      size: 10
                    },
                    aggs: {
                      contest_name: {
                        terms: {
                          field: 'contest_name',
                          size: 1000
                        },
                        aggs: {
                          party: {
                            terms: {
                              field: 'party',
                              size: 1000
                            },
                            aggs: {
                              vote_totals: {
                                sum: {
                                  field: 'votes'
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    size: 0
  };

  return query;
}
