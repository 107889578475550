import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePageDef } from '../../store/pagedef';
import { pageTitles } from '../routes/routes';
import ReactGA from 'react-ga4';
import { env } from '../../env';

const PageTitle = () => {
  const location = useLocation();
  const pageDef = usePageDef();
  useEffect(() => {
    ReactGA.initialize(`${env.REACT_APP_GOOGLE_ANALYTICS_KEY}`, { alwaysSendToDefaultTracker: false });
  }, []);

  useEffect(() => {
    document.title = pageDef.title ?? 'Roseland';
    if (pageDef.title) {
      const path = pageDef.analyticsPath ?? pageDef.title;
      ReactGA.send({ hitType: 'pageview', page: path, title: path });
    }
  }, [pageDef]);

  useEffect(() => {
    const path = pageTitles[location.pathname];
    if (path && document.title !== path) {
      document.title = path;
      ReactGA.send({ hitType: 'pageview', page: path });
    }
  }, [location]);

  return null;
};

export default PageTitle;
