import { env } from '../../env';
import { getJWTToken } from '../../utils/auth';

export const initCheckoutSession = (priceCode: string) => {
  fetch(`${env.REACT_APP_XHR_ROOT_URL}checkout/create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getJWTToken()}`
    },
    body: JSON.stringify({
      returnTo: window.location.href,
      priceCode: priceCode
    })
  })
    .then((r: any) => r.json())
    .then((r: any) => (window.location.href = r.url))
    .catch((e) => console.error(e));
};

export const viewBillingPortal = () => {
  fetch(`${env.REACT_APP_XHR_ROOT_URL}checkout/customer-portal`, {
    method: 'POST',
    body: JSON.stringify({ returnTo: window.location.href }),
    headers: {
      Authorization: `Bearer ${getJWTToken()}`,
      'Content-Type': 'application/json'
    }
  })
    .then((r) => r.json())
    .then((r) => {
      window.location.href = r.url;
    });
};
