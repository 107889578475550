import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import React, { memo, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import Icon from '../Icon';
const FilterTitle = styled(
  memo(
    ({
      className,
      children,
      expanded,
      onClick,
      ...rest
    }: ClassNameProps & { expanded?: boolean; onClick: MouseEventHandler }) => {
      return (
        <h4
          {...rest}
          onClick={onClick}
          className={`filter__title ${className} filter__title--${expanded ? 'expanded' : 'closed'}`}
        >
          {children}
          {!isNil(onClick) && <Icon icon={faChevronDown} />}
        </h4>
      );
    }
  )
)`
  font-size: 12px;
  margin-top: 1.5rem;
  font-weight: normal;
  line-height: 15px;
  cursor: ${(props: any) => (isNil(props.onClick) ? 'normal' : 'pointer')};
  & > svg {
    float: right;
    transition-duration: 0.2s;
    transition-property: transform;
  }

  &.filter__title--expanded {
    svg {
      transform: rotate(-180deg);
    }
  }
`;

export default FilterTitle;
