import { faBan } from '@fortawesome/free-solid-svg-icons';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import A from '../../components/A';
import Icon from '../../components/Icon';
import { paths } from '../../components/routes/routes';
import { isUserActivated } from '../../utils/auth';

const Forbidden: React.FC<ClassNameProps> = styled(({ className }) => {
  const { initialized } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized && !isUserActivated()) {
      navigate(paths.requiresActivation);
    }
  }, [initialized, navigate]);

  return (
    <div className={`forbidden ${className}`}>
      <Icon icon={faBan} size="5x" />
      <h1>401: Forbidden</h1>
      <h4>Please contact your administrator to gain access to this resource.</h4>
      <A to={paths.dashboard}>Home</A>
    </div>
  );
})`
  text-align: center;
  padding: 200px 0;
  h1 {
    margin: 20px 0;
  }
`;

export default Forbidden;
