import { toLower } from 'lodash';
import { isNestedPath, toSnakeCase } from '../../utils/format';
import { stripPunctuation } from '../../utils/search';

export const equalsDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  let value: string | boolean = filterValue[0];
  if (toLower(value) === 'true' || toLower(value) === 'false') {
    value = toLower(value) === 'true';
  }

  if (isNestedPath(field)) {
    const path = field.substring(0, field.indexOf('.'));
    const rest = field.substring(field.indexOf('.') + 1);

    return { [path]: equalsDbFilter(rest, searchParams, filterValue) };
  }

  return {
    [field]: {
      equalTo: value
    }
  };
};
export const equalsEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = field.startsWith('vh') ? field : toSnakeCase(field);

  return {
    must: [
      {
        bool: {
          should: [
            {
              prefix: {
                [snkField]: {
                  value: stripPunctuation(filterValue[0])
                }
              }
            },
            {
              prefix: {
                [snkField]: {
                  value: filterValue[0]
                }
              }
            }
          ]
        }
      }
    ]
  };
};
