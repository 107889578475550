import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ELECTION_RESULTS_QUERY } from '.';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { equalsEsFilter } from '../../urlParameterProcessor/processors/equals';
import { PEOPLE_FOR_DOWNLOAD, PEOPLE_LISTING } from '../people';
import { flattenBuckets } from './common';
import { getSummaryQuery } from './electionResultsQuery';

export const useVotersForTownshipQuery = (offset: number, sort: string[]) => {
  const { townshipId } = useParams();
  const queryCriteria = useMemo(
    () => ({
      filter: {
        query: {
          bool: {
            ...equalsEsFilter('townshipId', new URLSearchParams(), [townshipId || ''])
          }
        }
      }
    }),
    [townshipId]
  );

  const memoSort = useMemo(() => {
    return sort.length < 1 ? ['LAST_NAME_ASC', 'FIRST_NAME_ASC'] : sort;
  }, [sort]);

  const result = useQuery(PEOPLE_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: memoSort
    }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

export const useElectionResultsSummaryForTownship = () => {
  const { contest, countyId, type, state, year, townshipId } = useParams();

  const townshipAggregateSummaryQuery = getSummaryQuery();
  const query: any = {
    ...{
      filter: {
        ...townshipAggregateSummaryQuery,
        ...{ query: { bool: { must: [] } } }
      }
    }
  };

  query.filter.query.bool.must = [
    { match: { contest_name: { query: contest } } },
    { match: { type: { query: type } } },
    { match: { county_id: { query: countyId } } },
    { match: { state: { query: state } } },
    { match: { year: { query: year } } },
    { match: { township_id: { query: townshipId } } }
  ];

  const result = useQuery(ELECTION_RESULTS_QUERY, { variables: query });

  let rows: any = flattenBuckets(result?.data?.result?.aggBuckets || { year: { buckets: [] } }, {});

  rows.sort((a: any, b: any) => {
    let result = parseInt(a.year) - parseInt(b.year);
    if (result === 0) {
      result = a.candidateName.localeCompare(b.candidateName);
    }
    return result;
  });

  return { ...result, ...{ data: { result: rows } } };
};

export const useCsvPropsForDownload = () => {
  const { townshipId } = useParams();
  const queryCriteria = useMemo(
    () => ({
      filter: {
        query: {
          bool: {
            ...equalsEsFilter('townshipId', new URLSearchParams(), [townshipId || ''])
          }
        }
      }
    }),
    [townshipId]
  );

  const csvProps: CsvProps = {
    query: PEOPLE_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC', 'FIRST_NAME_ASC']
    }
  };

  return csvProps;
};
