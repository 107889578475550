import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const EMAIL_OUTREACH_LISTING = gql`
  query EmailOutreachListing(
    $filter: ViewEmailOutreachFilter!
    $offset: Int!
    $orderBy: [ViewEmailOutreachesOrderBy!]
  ) {
    emailOutreaches: viewEmailOutreaches(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        emailOutreachId
        name
        scheduledTo
        foiaEmailsSentCount
        foiaEmailsReceivedCount
        noResponseCount
        paymentRequiredCount
        deniedCount
        pastDueCount
        state
      }
      totalCount
    }
  }
`;

export const useEmailOutreachesQuery = (offset: number, sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  useEffect(() => {
    if (sort.length < 1) {
      sort = loadDefaultSort(searchParams, sort, ['NAME_ASC']);
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const filter = {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: '2' }
  };

  const result = useQuery(EMAIL_OUTREACH_LISTING, filter);

  return result;
};

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: viewEmailOutreaches(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: emailOutreachId
        }
      }
    }
  }
`;

export const useEmailOutreachStateCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY, { context: { version: '2' } });
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewEmailOutreaches {
      totalCount
    }
  }
`;

export const useEmailOutreachesTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

const EMAIL_OUTREACHES_FOR_DOWNLOAD = gql`
  query EmailOutreachesDownload(
    $first: Int!
    $filter: ViewEmailOutreachFilter!
    $offset: Int!
    $orderBy: [ViewEmailOutreachesOrderBy!]
  ) {
    csv: viewEmailOutreaches(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        name
        scheduledTo
        foiaEmailsSentCount
        foiaEmailsReceivedCount
        noResponseCount
        paymentRequiredCount
        deniedCount
        pastDueCount
        state
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: EMAIL_OUTREACHES_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};
