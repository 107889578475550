import { createReduxModule } from 'hooks-for-redux';
import { useEffect } from 'react';
import { FilterIndicatorProps } from '../components/FilterIndicator/FilterIndicator';

export interface FilterDef {
  filters?: any[];
  query?: string;
  filterType?: string;
  source?: 'elasticSearch' | 'db';
  apiVersion?: '1' | '2';
}
export interface FilterState {
  type?: string;
  filter?: FilterDef;
  state?: string;
  apiVersion?: string;
}

export interface FilterIndicatorState {
  [key: string]: FilterIndicatorProps;
}

export const [useFilterState, setFilterState] = createReduxModule<FilterState>('filterState', {});
export const [useSuppressedFilters, setSuppressedFilters] = createReduxModule<string[]>('suppressedFilters', []);
export const [useShowFilter, setShowFilter] = createReduxModule<{ [type: string]: boolean }>('showFilter', {
  '': false
});

export const [useFilterIndicators, { addFilterIndicator, removeFilterIndicator, resetFilterIndicators }] =
  createReduxModule<FilterIndicatorState, any>(
    'filterIndicators',
    {},
    {
      addFilterIndicator: (state: FilterIndicatorState, filterIndicatorProps: FilterIndicatorState) => {
        return { ...state, ...filterIndicatorProps };
      },
      removeFilterIndicator: (state: FilterIndicatorState, key: string) => {
        const result = { ...state };
        delete result[key];
        return result;
      },
      resetFilterIndicators: () => {
        return {};
      }
    }
  );

export const useAddFilterIndicator = (filterIndicatorProps: FilterIndicatorState) => {
  useEffect(() => {
    addFilterIndicator(filterIndicatorProps);
  }, [filterIndicatorProps, addFilterIndicator]);
};
