import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { DataSource, useFilterQueryCriteriaFromUrl } from '../../utils/filter';
import { useMaybeGroupResultsByAddress } from '../../utils/table';

const CAMPAIGN_CONTRIBUTIONS_FRAGMENT = gql`
  fragment CampaignContribution on ViewCampaignContribution {
    id
    tranId
    committeeOrganizationId
    committeeName
    contributionDate
    contributionType
    contributionAmount
    personId
    firstName
    middleName
    lastName
    suffix
    phone
    sex
    birthdate
    gender
    address
    precinctId
    precinct
    cityId
    city
    countyId
    county
    zip
    state
    unit
    sourceOrganizationId
    source
    donorOrganizationId
    donorOrganization
    updatedAt
  }
`;

const CAMPAIGN_CONTRIBUTIONS_LISTING = gql`
  query CampaignContributionsListing(
    $filter: JSONObject!
    $offset: Int!
    $orderBy: [ViewCampaignContributionsOrderBy!]
  ) {
    result: viewCampaignContributions(first: 150, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...CampaignContribution
      }
      totalCount
      totalAggCount
    }
  }
  ${CAMPAIGN_CONTRIBUTIONS_FRAGMENT}
`;

export const useAllCampaignContributionsListingQuery = (offset: number, sort: string[], source: DataSource) => {
  const [searchParams] = useSearchParams();
  const maybeGroupResultsByHousehold = useMaybeGroupResultsByAddress();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, source);

  const result = useQuery(CAMPAIGN_CONTRIBUTIONS_LISTING, {
    errorPolicy: 'ignore',
    variables: {
      ...queryCriteria,
      first: 150,
      offset: offset,
      orderBy: sort.length < 1 ? ['CONTRIBUTION_AMOUNT_ASC'] : sort
    },
    context: { version: '2' }
  });

  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return maybeGroupResultsByHousehold(result, searchParams, 'result');
};

const STATES_FOR_CC_QUERY = gql`
  query StatesQuery($filter: JSONObject!) {
    states: viewCampaignContributions(elasticSearch: $filter) {
      esResult
    }
  }
`;

const COUNTIES_FOR_CC_QUERY = gql`
  query CountiesByState($filter: JSONObject!) {
    counties: viewCampaignContributions(elasticSearch: $filter) {
      esResult
    }
  }
`;

const CITIES_FOR_CC_QUERY = gql`
  query CitiesForState($filter: JSONObject!) {
    cities: viewCampaignContributions(elasticSearch: $filter) {
      esResult
    }
  }
`;

const PRECINCTS_FOR_CC_QUERY = gql`
  query PrecinctsForState($filter: JSONObject!) {
    precincts: viewCampaignContributions(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useCampaignContributionsStateTotalQuery = () => {
  const filter: any = {
    aggs: {
      results: {
        terms: {
          field: 'state'
        }
      }
    },
    size: 0
  };
  const vars = { variables: { filter: filter }, context: { version: '2' } };

  return useLazyQuery(STATES_FOR_CC_QUERY, vars);
};

export const useCampaignContributionsCountyTotalQuery = () => {
  return useLazyQuery(COUNTIES_FOR_CC_QUERY, { context: { version: '2' } });
};

export const useCampaignContributionsCityTotalQuery = () => {
  return useLazyQuery(CITIES_FOR_CC_QUERY, { context: { version: '2' } });
};

export const useCampaignContributionsPrecinctTotalQuery = () => {
  return useLazyQuery(PRECINCTS_FOR_CC_QUERY, { context: { version: '2' } });
};

const CONTRIBUTIONS_FOR_DOWNLOAD = gql`
  query ContributionsDownload(
    $first: Int!
    $filter: JSONObject!
    $offset: Int!
    $orderBy: [ViewCampaignContributionsOrderBy!]
  ) {
    csv: viewCampaignContributions(first: $first, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...CampaignContribution
      }
      totalCount
    }
  }
  ${CAMPAIGN_CONTRIBUTIONS_FRAGMENT}
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

  const csvProps: CsvProps = {
    query: CONTRIBUTIONS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC', 'FIRST_NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewCampaignContributions(elasticSearch: { index: "cmpgcontv2" }) {
      totalCount
    }
  }
`;

export const useCampaignContributionTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};
