import { replace } from 'lodash';
import { split } from 'lodash';

export const splitName = (term: string) => {
  return split(term || '', ' ');
};

const sepRegex = /'|-/gi;
export const parseQueryToNames = (queryTokens: string[]) => {
  const len: number = queryTokens.length;

  const first = new Set<string>();
  const middle = new Set<string>();
  const last = new Set<string>();

  if (len > 1) {
    first.add(queryTokens[0]);

    // ['michael','mcdonnell'] or ['mc', 'donnell']
    if (len === 2) {
      first.add(`${queryTokens[0]}${queryTokens[1]}`);
      last.add(queryTokens[1]);
      last.add(`${queryTokens[0]}${queryTokens[1]}`);
      last.add(replace(`${queryTokens[0]}${queryTokens[1]}`, sepRegex, ' '));
      last.add(replace(`${queryTokens[1]}`, sepRegex, ' '));
      last.add(`${queryTokens[0]}'${queryTokens[1]}`);
      last.add(`${queryTokens[0]}-${queryTokens[1]}`);
    }

    if (len > 2) {
      first.add(`${queryTokens[0]}${queryTokens[1]}`);
      middle.add(queryTokens[1]);
      middle.add(queryTokens[1].substring(0, 1));

      for (let index = 2; index < queryTokens.length; index++) {
        last.add(queryTokens[index]);
        last.add(`${queryTokens[index - 1]}${queryTokens[index]}`);
        last.add(replace(`${queryTokens[index - 1]}${queryTokens[index]}`, sepRegex, ' '));
        last.add(`${queryTokens[index - 1]}'${queryTokens[index]}`);
        last.add(`${queryTokens[index - 1]}-${queryTokens[index]}`);
        last.add(`${queryTokens[index]}`);
        last.add(replace(`${queryTokens[index]}${queryTokens[index]}`, sepRegex, ' '));
      }
    }
  } else {
    last.add(queryTokens[0]);
    last.add(replace(`${queryTokens[0]}`, sepRegex, ' '));
  }
  return {
    first: [...first],
    middle: [...middle],
    last: [...last]
  };
};

export const buildVariantClauses = (firstLast: string[]) => {
  const nameParts = parseQueryToNames(firstLast);

  const variantClauses: any[] = [];

  let minShouldMatch = '1';

  nameParts.first.forEach((firstName) => {
    variantClauses.push({
      match: {
        first_name_text: {
          query: firstName,
          analyzer: 'name_synonym',
          boost: 1.5
        }
      }
    });
  });

  nameParts.middle.forEach((middleName) => {
    variantClauses.push({
      match: {
        middle_name: {
          query: middleName,
          boost: 1
        }
      }
    });
  });
  nameParts.last.forEach((lastName) => {
    variantClauses.push({
      match_phrase: {
        last_name_text: {
          query: lastName,
          boost: 5
        }
      }
    });
    minShouldMatch = '2';
  });

  return { minShouldMatch: minShouldMatch, variantClauses: variantClauses };
};
