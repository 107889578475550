import { createReduxModule } from 'hooks-for-redux';
import { isNil, isObject } from 'lodash';
import features from '../features.json';

export const useFeatureFlag = (key: string) => {
  const allFlagsWithOVerrides = useFeatureFlags();
  return resolveValue(allFlagsWithOVerrides[key]);
};

const resolveValue = (obj: object | boolean) => {
  let val: boolean = false;
  const location = window.location;

  if (isObject(obj)) {
    val = obj[location.hostname] || false;
  } else {
    val = obj as boolean;
  }
  return val;
};
export const isFeatureOverridden = (key: string) => {
  const override = loadFromLocalStorage()[key];
  const defaultValue: boolean | object = features[key];
  return !isNil(override) && override !== resolveValue(defaultValue);
};

const loadFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem('features') || '{}');
};
export const loadFeatures = () => {
  const featureOverrides = JSON.parse(localStorage.getItem('features') || '{}');

  if (isNil(featureOverrides)) {
    return features;
  }
  return { ...(features || {}), ...featureOverrides! };
};
export const [useAppFeatures, setAppFeatures] = createReduxModule<any>('appFeatures', loadFeatures());

export const useFeatureFlags = () => {
  const features = useAppFeatures();

  const result: any = {};
  Object.keys(features).forEach((key) => {
    const val = resolveValue(features[key]);
    result[key] = val;
  });

  return result;
};
