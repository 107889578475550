import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DataSource, getCountQueryCriteriaFromUrl } from '../../utils/filter';

const getCountQuery = (entity: string, filterType: string, source: string) => {
  return !source || source === 'db'
    ? gql`
  query GetFilterCount($filter:  ${filterType}!) {
    count: ${entity}(filter: $filter) {
      totalCount
    }
  }
`
    : gql`
query GetFilterCount($filter:  JSONObject!) {
  count: ${entity}(elasticSearch: $filter) {
    totalCount
  }
}
`;
};

export const useFilterCountQuery = ({
  query,
  type,
  field,
  value,
  filterType,
  skipCount,
  source,
  apiVersion
}: {
  query: string;
  type: string;
  field: string;
  value: any[];
  filterType: string;
  skipCount: boolean;
  source: DataSource;
  apiVersion: string;
}) => {
  const [searchParams] = useSearchParams();

  const filters = useMemo(() => {
    return getCountQueryCriteriaFromUrl(searchParams, field, type, value, source);
  }, [type, field, value, searchParams, source]);

  return useQuery(getCountQuery(query, filterType, source), {
    skip: skipCount,
    variables: filters,
    context: { version: apiVersion || '' }
  });
};
