import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { FormCheck } from '../Form';

const SelectionCell: React.FC<{
  rowData: any[];
  rowIndex: number;
  column: any;
  rowSelectable?: Function;
  notSelectableElement?: ReactNode;
  className?: string;
}> = styled(({ className, rowData, rowIndex, column, rowSelectable, notSelectableElement }) => {
  const { onChange } = column;
  const handleChange = useCallback(
    (e: any) => {
      onChange({ selected: e.target.checked, rowData, rowIndex });
    },
    [onChange, rowData, rowIndex]
  );
  const { selectedRowKeys, rowKey } = column;
  const checked = (selectedRowKeys || []).includes(rowData[rowKey]);
  const canSelect = rowSelectable ? rowSelectable(rowData[rowKey]) : true;
  return canSelect ? (
    <FormCheck className={className} checked={checked} onChange={handleChange} />
  ) : notSelectableElement ? (
    notSelectableElement
  ) : (
    <></>
  );
})`
  .form-check-input {
    width: 1.5em;
    height: 1.5em;
  }
`;

export const SelectAllHeaderCell: React.FC<{
  rowData: any[];
  totalCount: number;
  selectedRowKeys: any[];
  onChange: any;
  className?: string;
}> = styled(({ selectedRowKeys, className, rowData, onChange, totalCount }) => {
  const handleChange = useCallback(
    (e: any) => {
      onChange({ selected: e.target.checked, rowData });
    },
    [onChange, rowData]
  );

  const indeterminate = selectedRowKeys.length === rowData.length;
  const checked = selectedRowKeys.length === totalCount;

  return (
    <FormCheck
      className={className}
      indeterminate={indeterminate ? 'true' : 'false'}
      checked={checked}
      onChange={handleChange}
    />
  );
})`
  .form-check-input {
    width: 1.5em;
    height: 1.5em;

    &[indeterminate='true'] {
      background-image: none;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 2px;
        width: 8px;
        background-color: var(--white);
      }
    }
  }
`;

export default SelectionCell;
