import React, { memo } from 'react';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import reconLogo from '../../img/recon-logo.svg';
import logoSmall from '../../img/rose-icon.svg';
import roselandLogo from '../../img/roseland-logo.svg';

const displayLogo = window.location.host.startsWith('recon') ? reconLogo : roselandLogo;
const altText = window.location.host.startsWith('recon') ? 'Recon' : 'Roseland';

const Logo = styled(
  memo(({ className, small }: { small?: boolean } & ClassNameProps) => {
    return <img alt={`${altText}`} className={`${className} logo`} src={small ? logoSmall : displayLogo} />;
  })
)`
  margin-bottom: 30px;
`;

const CompanyLogo = styled(
  memo(({ className, src, alt }: { src: string; alt: string } & ClassNameProps) => {
    if (!src) {
      return null;
    }
    return <img alt={`${alt}`} className={`${className} logo`} src={src} />;
  })
)`
  max-width: 150px;
  margin-bottom: 30px;
  height: 35px;
`;

export default Logo;
export { CompanyLogo };
