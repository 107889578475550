import { FILTER_VALUE_SEP } from '../../utils/filter';

export const geoRadiusDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  throw new Error('geoRadius is unimplemented for elasticSearch source');
};
export const geoRadiusEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const params = filterValue[0].split(FILTER_VALUE_SEP);

  return {
    filter: {
      geo_distance: {
        distance: `${params[0]}m`,
        geo_radius: {
          lat: parseFloat(params[1]),
          lon: parseFloat(params[2])
        }
      }
    },

    root: {
      runtime_mappings: {
        geo_radius: {
          type: 'geo_point',
          script: { source: "if(doc['lat'].size()>0){  emit( doc['lat'].value,doc['lon'].value);}" }
        }
      }
    }
  };
};
