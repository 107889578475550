import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { useFeatures } from 'flagged';
import React, { FormEvent, useCallback, useEffect } from 'react';
import { Container, Form, Table } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Info from '../../components/Info';
import { paths } from '../../components/routes/routes';
import { FilterState } from '../../store/filter';
import { setPageDef } from '../../store/pagedef';
import { isFeatureOverridden, loadFeatures, setAppFeatures, useFeatureFlags } from '../../utils/features';
import { useConfigureScreen } from '../../utils/filter';
import { showSuccess } from '../../utils/message';

const Features: React.FC<{ className?: string }> = styled(({ className }) => {
  const features = useFeatures();
  const featureFlags = useFeatureFlags();

  useEffect(() => {
    setPageDef({ title: 'Features', analyticsPath: 'Features' });
  }, []);

  const restoreDefault = useCallback((feature: string) => {
    const featureOverrides = JSON.parse(localStorage.getItem('features') || '{}');
    delete featureOverrides[feature];
    localStorage.setItem('features', JSON.stringify(featureOverrides));
    setAppFeatures(loadFeatures());
    showSuccess('Feature ' + feature + ' restored to default');
  }, []);

  const handleChange = useCallback((e: FormEvent) => {
    const featureOverrides = JSON.parse(localStorage.getItem('features') || '{}');
    const input: HTMLInputElement = e.target as HTMLInputElement;
    featureOverrides[input.value] = input.checked;

    localStorage.setItem('features', JSON.stringify(featureOverrides));
    setAppFeatures(loadFeatures());
    showSuccess('Feature ' + input.value + ' set to ' + (input.checked ? 'enabled' : 'disabled'));
  }, []);

  useConfigureScreen({} as FilterState, { path: paths.features, label: 'Features' }, 'features', true);

  return (
    <div className={className}>
      <Info>
        <h2>Feature Flags</h2>
      </Info>
      <Container>
        <h4>Setting a feature flag impacts only this browser</h4>
        <Table striped>
          <thead>
            <tr>
              <th>Feature</th>
              <th>Enabled</th>
              <th className="cell--restore-default">Restore Default</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(features).map((entry) => {
              return (
                <tr key={entry[0]}>
                  <td>{entry[0]}</td>
                  <td>
                    <Form.Check
                      value={entry[0]}
                      onChange={handleChange}
                      type="switch"
                      id={entry[0]}
                      checked={featureFlags[entry[0]] || false}
                      label=""
                    />
                  </td>
                  <td className="cell--restore-default">
                    {isFeatureOverridden(entry[0]) ? (
                      <Button
                        onClick={() => restoreDefault(entry[0])}
                        className="btn--restore-default"
                        variant={'link'}
                        title="Restore Default"
                      >
                        <Icon icon={faRotateLeft} />
                      </Button>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </div>
  );
})`
  .cell--restore-default {
    text-align: center;
    position: relative;
  }
  .btn--restore-default {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export default Features;
