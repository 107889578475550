import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
// import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from '@apollo/client';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Provider as RollbarProvider } from '@rollbar/react';
import { Provider } from 'hooks-for-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { SkeletonTheme } from 'react-loading-skeleton';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { client } from './apollo';
import ErrorHandler from './components/ErrorHandler';
import { env } from './env';
import keycloak from './keycloak';
import { ThemeProvider } from './theme/ThemeProvider';

const container = document.getElementById('root');
const root = createRoot(container!);

const rollbarConfig = {
  accessToken: '8fadf24e712d4c8d8e6c3585819c0e9c',
  environment: env.REACT_APP_HOSTNAME
};

root.render(
  <RollbarProvider config={rollbarConfig}>
    {/* @ts-ignore*/}
    <Provider>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <ReactKeycloakProvider
          authClient={keycloak}
          initOptions={{
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
          }}
        >
          <ThemeProvider>
            <SkeletonTheme baseColor="#E0E0E0" highlightColor="#f2f5f8">
              <ApolloProvider client={client}>
                <App />
                <ToastContainer position="top-center" />
              </ApolloProvider>
            </SkeletonTheme>
          </ThemeProvider>
        </ReactKeycloakProvider>
      </ErrorBoundary>
    </Provider>
  </RollbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
