import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { equalsEsFilter } from '../../urlParameterProcessor/processors/equals';
import { yearsAgoEsFilter } from '../../urlParameterProcessor/processors/yearsago';
import {
  DataSource,
  FilterParameterOverrides,
  getFilterQueryCriteriaFromUrl,
  useFilterQueryCriteriaFromUrl
} from '../../utils/filter';
import { GENERAL, VOTED } from '../../utils/propensity';
import { useMaybeGroupResultsByAddress } from '../../utils/table';

const PERSON_COLUMNS_FRAGMENT = gql`
  fragment Person on ViewPeopleList {
    id
    voterId
    kind
    prefix
    firstName
    middleName
    lastName
    suffix
    birthdate
    deathdate
    sex
    phone
    email
    twitter
    facebook
    linkedin
    instagram
    addressId
    address
    unit
    cityId
    city
    countyId
    county
    state
    zip
    registrationDate
    registrationStatus
    partyAffiliation
    registeredVoter
    stateHouseId
    stateHouse
    stateSenateId
    stateSenate
    usHouseId
    usHouse
    precinctId
    precinct
    blockGroupId
    blockGroup
    censusBlockId
    censusBlock
    censusTractId
    censusTract
    commissionerId
    commissioner
    countySubdivisionId
    countySubdivision
    subdivisionId
    subdivision
    neighborhoodId
    neighborhood
    parkTaxDistrictId
    parkTaxDistrict
    schoolElementaryId
    schoolElementary
    schoolHighId
    schoolHigh
    schoolSecondaryId
    schoolSecondary
    judicialDistrictId
    judicialDistrict
    wardId
    ward
    townshipId
    township
    flagIsNcoa
    suspectAge
    suspectMailstore
    suspectSectionEight
    suspectHighDensity
    suspectNursinghome
    suspectUniversity
    suspectSexOffender
    suspectArrested
    suspectRetiredGovEmp
    suspectActiveGovEmp
    suspectAlien
    suspectPhantom
    suspectName
    suspectDead
    suspectDuplicate
    suspectOutOfState
    suspectRegistrationProblem
    suspectAddressProblem
    suspectPpt
    suspectPpp
    schoolBoardDistrictId
    schoolBoardDistrict
    pctAsian
    pctWhite
    pctBlack
    pctNative
    pctLatino
    race
    voterHistory
    mktSentCnt
    mktEngagedCnt
    voterPropertyTax
    socialMedia
    permanentAbsentee
    factoids
  }
`;

const PERSON_COLUMNS_MINIMIZED_FRAGMENT = gql`
  fragment PersonMinimized on ViewPeopleList {
    id
    voterId
    kind
    firstName
    middleName
    lastName
    suffix
    birthdate
    sex
    phone
    email
    twitter
    facebook
    linkedin
    instagram
    address
    unit
    city
    county
    state
    zip
    registrationDate
    registrationStatus
    partyAffiliation
    registeredVoter
    stateHouse
    stateSenate
    usHouse
    precinct
    blockGroup
    censusBlock
    censusTract
    commissioner
    countySubdivision
    subdivision
    neighborhood
    parkTaxDistrict
    schoolElementary
    schoolHigh
    schoolSecondary
    judicialDistrict
    ward
    township
    flagIsNcoa
    suspectAge
    suspectMailstore
    suspectSectionEight
    suspectHighDensity
    suspectNursinghome
    suspectUniversity
    suspectSexOffender
    suspectArrested
    suspectRetiredGovEmp
    suspectActiveGovEmp
    suspectPpp
    schoolBoardDistrict
    pctAsian
    pctWhite
    pctBlack
    pctNative
    pctLatino
    race
    voterHistory
    mktSentCnt
    mktEngagedCnt
    voterPropertyTax
    socialMedia
    permanentAbsentee
    factoids
  }
`;

const PERSON_COLUMNS_ID_ONLY_FRAGMENT = gql`
  fragment PersonIdOnly on ViewPeopleList {
    id
  }
`;

export const PEOPLE_LISTING = gql`
  query PeopleListing($filter: JSONObject!, $offset: Int!, $orderBy: [ViewPeopleListsOrderBy!]) {
    people: viewPeopleLists(first: 150, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Person
      }
      totalCount
      totalAggCount
    }
  }
  ${PERSON_COLUMNS_FRAGMENT}
`;

export const PEOPLE_AGE_QUERY = gql`
  query PeopleAgeBuckets($filter: JSONObject!) {
    result: viewPeopleLists(elasticSearch: $filter) {
      aggBuckets
    }
  }
`;

export const PEOPLE_FOR_DOWNLOAD = gql`
  query PeopleForDownload($first: Int!, $filter: JSONObject!, $offset: Int!, $orderBy: [ViewPeopleListsOrderBy!]) {
    csv: viewPeopleLists(first: $first, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...PersonMinimized
      }
    }
  }
  ${PERSON_COLUMNS_MINIMIZED_FRAGMENT}
`;

export const PEOPLE_IDS_ONLY = gql`
  query PeopleIdsOnly($first: Int!, $filter: JSONObject!, $offset: Int!, $orderBy: [ViewPeopleListsOrderBy!]) {
    people: viewPeopleLists(first: $first, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...PersonIdOnly
      }
    }
  }
  ${PERSON_COLUMNS_ID_ONLY_FRAGMENT}
`;

const STATES_FOR_VOTERS_QUERY = gql`
  query StatesQuery($filter: JSONObject!) {
    states: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

const COUNTIES_FOR_VOTERS_QUERY = gql`
  query CountiesByState($filter: JSONObject!) {
    counties: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

const CITIES_FOR_VOTERS_QUERY = gql`
  query CitiesForState($filter: JSONObject!) {
    cities: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

const TOWNSHIPS_FOR_VOTERS_QUERY = gql`
  query TownshipsForState($filter: JSONObject!) {
    townships: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

const NEIGHBORHOODS_FOR_VOTERS_QUERY = gql`
  query NeighborhoodsForState($filter: JSONObject!) {
    neighborhoods: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

const WARDS_FOR_VOTERS_QUERY = gql`
  query WardsForState($filter: JSONObject!) {
    wards: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

const STATE_HOUSE_FOR_VOTERS_QUERY = gql`
  query StateHouseForState($filter: JSONObject!) {
    stateHouses: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

const STATE_SENATE_FOR_VOTERS_QUERY = gql`
  query StateSenateForState($filter: JSONObject!) {
    stateSenates: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

const US_HOUSE_FOR_VOTERS_QUERY = gql`
  query UsHouseForState($filter: JSONObject!) {
    usHouses: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

const PRECINCTS_FOR_VOTERS_QUERY = gql`
  query PrecinctsByState($filter: JSONObject!) {
    precincts: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

const TOWNSHIP_BY_ID = gql`
  query TownshipById($ids: [UUID!]!) {
    result: viewTownships(filter: { townshipId: { in: $ids } }, first: 1) {
      nodes {
        township
        townshipId
      }
    }
  }
`;

const PRECINCT_BY_ID = gql`
  query PrecinctById($ids: [UUID!]!) {
    result: viewPrecincts(filter: { precinctId: { in: $ids } }, first: 1) {
      nodes {
        precinct
        precinctId
      }
    }
  }
`;

const WARD_BY_ID = gql`
  query WardById($ids: [UUID!]!) {
    result: viewWards(filter: { wardId: { in: $ids } }, first: 1) {
      nodes {
        ward
        wardId
      }
    }
  }
`;

const US_HOUSE_BY_ID = gql`
  query UsHouseById($ids: [UUID!]!) {
    result: viewUsHouseDistricts(filter: { usHouseId: { in: $ids } }, first: 1) {
      nodes {
        usHouse
        usHouseId
      }
    }
  }
`;

const STATE_SENATE_BY_ID = gql`
  query StateSenateById($ids: [UUID!]!) {
    result: viewStateSenateDistricts(filter: { stateSenateId: { in: $ids } }, first: 1) {
      nodes {
        stateSenate
        stateSenateId
      }
    }
  }
`;

const STATE_HOUSE_BY_ID = gql`
  query StateHouseById($ids: [UUID!]!) {
    result: viewStateHouseDistricts(filter: { stateHouseId: { in: $ids } }, first: 1) {
      nodes {
        stateHouse
        stateHouseId
      }
    }
  }
`;

const ELEMENTARY_SCHOOL_BY_ID = gql`
  query ElementarySchoolById($id: UUID!) {
    result: viewSchoolDistrictElementaries(filter: { schoolElementaryId: { in: $ids } }, first: 1) {
      nodes {
        schoolElementary
        schoolElementaryId
      }
    }
  }
`;

const MIDDLE_SCHOOL_BY_ID = gql`
  query MiddleSchoolById($id: UUID!) {
    result: viewSchoolDistrictSecondaries(filter: { schoolSecondaryId: { in: $ids } }, first: 1) {
      nodes {
        schoolSecondary
        schoolSecondaryId
      }
    }
  }
`;

const HIGH_SCHOOL_BY_ID = gql`
  query HighSchoolById($id: UUID!) {
    result: viewSchoolDistrictHighs(filter: { schoolHighId: { in: $ids } }, first: 1) {
      nodes {
        schoolHigh
        schoolHighId
      }
    }
  }
`;

const PARK_DISTRICT_BY_ID = gql`
  query ParkDistrictById($id: UUID!) {
    result: viewParkTaxDistricts(filter: { parkTaxDistrictId: { in: $ids } }, first: 1) {
      nodes {
        parkTaxDistrict
        parkTaxDistrictId
      }
    }
  }
`;

const CENSUS_BLOCK_BY_ID = gql`
  query CensusBlockById($id: UUID!) {
    result: viewCensusBlocks(filter: { censusBlockId: { in: $ids } }, first: 1) {
      nodes {
        censusBlock
        censusBlockId
      }
    }
  }
`;

const JUDICIAL_DISTRICT_BY_ID = gql`
  query JudicialDistrictById($id: UUID!) {
    result: viewJudicialDistricts(filter: { judicialDistrictId: { in: $ids } }, first: 1) {
      nodes {
        judicialDistrict
        judicialDistrictId
      }
    }
  }
`;

const SUB_DIVISION_BY_ID = gql`
  query SubDivisionById($id: UUID!) {
    result: viewSubdivisions(filter: { subdivisionId: { in: $ids } }, first: 1) {
      nodes {
        subdivision
        subdivisionId
      }
    }
  }
`;
const BLOCK_GROUP_BY_ID = gql`
  query BlockGroupById($id: UUID!) {
    result: viewBlockGroups(filter: { blockGroupId: { in: $ids } }, first: 1) {
      nodes {
        blockGroup
        blockGroupId
      }
    }
  }
`;

const CENSUS_TRACT_BY_ID = gql`
  query CensusTractById($id: UUID!) {
    result: viewCensusTracts(filter: { censusTractId: { in: $ids } }, first: 1) {
      nodes {
        censusTract
        censusTractId
      }
    }
  }
`;

const NEIGHBORHOOD_BY_ID = gql`
  query NeighborhoodById($id: UUID!) {
    result: viewNeighborhoods(filter: { neighborhoodId: { in: $ids } }, first: 1) {
      nodes {
        neighborhood
        neighborhoodId
      }
    }
  }
`;

export const usePrecinctById = () => {
  return useLazyQuery(PRECINCT_BY_ID, { context: { version: '2' } });
};
export const useTownshipById = () => {
  return useLazyQuery(TOWNSHIP_BY_ID, { context: { version: '2' } });
};
export const useWardById = () => {
  return useLazyQuery(WARD_BY_ID, { context: { version: '2' } });
};
export const useUsHouseById = () => {
  return useLazyQuery(US_HOUSE_BY_ID, { context: { version: '2' } });
};
export const useStateSenateById = () => {
  return useLazyQuery(STATE_SENATE_BY_ID, { context: { version: '2' } });
};
export const useStateHouseById = () => {
  return useLazyQuery(STATE_HOUSE_BY_ID, { context: { version: '2' } });
};
export const useElementarySchoolById = () => {
  return useLazyQuery(ELEMENTARY_SCHOOL_BY_ID, { context: { version: '2' } });
};
export const useMiddleSchoolById = () => {
  return useLazyQuery(MIDDLE_SCHOOL_BY_ID, { context: { version: '2' } });
};
export const useHighSchoolById = () => {
  return useLazyQuery(HIGH_SCHOOL_BY_ID, { context: { version: '2' } });
};
export const useParkDistrictById = () => {
  return useLazyQuery(PARK_DISTRICT_BY_ID, { context: { version: '2' } });
};
export const useCensusBlockById = () => {
  return useLazyQuery(CENSUS_BLOCK_BY_ID, { context: { version: '2' } });
};
export const useSubDivisionById = () => {
  return useLazyQuery(SUB_DIVISION_BY_ID, { context: { version: '2' } });
};
export const useBlockGroupById = () => {
  return useLazyQuery(BLOCK_GROUP_BY_ID, { context: { version: '2' } });
};
export const useCensusTractById = () => {
  return useLazyQuery(CENSUS_TRACT_BY_ID, { context: { version: '2' } });
};
export const useJudicialDistrictuById = () => {
  return useLazyQuery(JUDICIAL_DISTRICT_BY_ID, { context: { version: '2' } });
};
export const useNeighborhoodById = () => {
  return useLazyQuery(NEIGHBORHOOD_BY_ID, { context: { version: '2' } });
};
export const useStatesForPeople = () => {
  const filter: any = {
    aggs: {
      results: {
        terms: {
          field: 'state',
          size: 59
        }
      }
    },
    size: 0
  };
  const vars = { variables: { filter: filter }, context: { version: '2' } };

  return useLazyQuery(STATES_FOR_VOTERS_QUERY, vars);
};

export const useStatesForVoters = () => {
  const filter: any = {
    query: {
      prefix: { kind: { value: 'voting_addresses' } }
    },
    aggs: {
      results: {
        terms: {
          field: 'state',
          size: 59
        }
      }
    },
    size: 0
  };
  const vars = { variables: { filter: filter }, context: { version: '2' } };

  return useLazyQuery(STATES_FOR_VOTERS_QUERY, vars);
};

export const useCountiesForState = () => {
  return useLazyQuery(COUNTIES_FOR_VOTERS_QUERY, { context: { version: '2' } });
};
export const useCitiesForState = () => {
  return useLazyQuery(CITIES_FOR_VOTERS_QUERY, { context: { version: '2' } });
};
export const useNeighborhoodsForState = () => {
  return useLazyQuery(NEIGHBORHOODS_FOR_VOTERS_QUERY, { context: { version: '2' } });
};
export const useTownshipForState = () => {
  return useLazyQuery(TOWNSHIPS_FOR_VOTERS_QUERY, { context: { version: '2' } });
};
export const useWardForState = () => {
  return useLazyQuery(WARDS_FOR_VOTERS_QUERY, { context: { version: '2' } });
};
export const usePrecinctsForState = () => {
  return useLazyQuery(PRECINCTS_FOR_VOTERS_QUERY, { context: { version: '2' } });
};
export const useStateHouseForState = () => {
  return useLazyQuery(STATE_HOUSE_FOR_VOTERS_QUERY, { context: { version: '2' } });
};
export const useStateSenateForState = () => {
  return useLazyQuery(STATE_SENATE_FOR_VOTERS_QUERY, { context: { version: '2' } });
};
export const useUsHouseForState = () => {
  return useLazyQuery(US_HOUSE_FOR_VOTERS_QUERY, { context: { version: '2' } });
};

export const useSimplePeopleListQuery = (offset: number, sort: string[]) => {
  return useBaseAllPeopleListingQuery({ offset: offset, sort: sort, source: 'elasticSearch', skip: false });
};

export const useAllPeopleListingQuery = (offset: number, sort: string[], source: DataSource, skip?: boolean) => {
  return useBaseAllPeopleListingQuery({ offset, sort, source, skip });
};

export const useCsvPropsForSelectedPeopleDownload = (selectedIds: any[]) => {
  const queryCriteria = useFilterQueryCriteriaFromUrl(new URLSearchParams(), 'elasticSearch', {
    'ids-in': [selectedIds.join('|')]
  });

  const csvProps: CsvProps = {
    query: PEOPLE_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC', 'FIRST_NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};

export const useSelectedPeopleAgeQuery = (selectedIds: string[]) => {
  const [searchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch', {
    'ids-in': [selectedIds.join('|')]
  });

  const resultQuery = {
    filter: {
      ...queryCriteria.filter,

      ...{
        size: 0,
        aggs: {
          age: {
            date_range: {
              field: 'birthdate',
              format: 'MM-dd-yyyy',
              ranges: [
                {
                  from: 'now-18y',
                  to: 'now'
                },
                {
                  from: 'now-25y',
                  to: 'now-18y-1d'
                },
                {
                  from: 'now-35y',
                  to: 'now-25y-1d'
                },

                {
                  from: 'now-45y',
                  to: 'now-35y-1d'
                },
                {
                  from: 'now-55y',
                  to: 'now-45y-1d'
                },
                {
                  from: 'now-65y',
                  to: 'now-55y-1d'
                },
                {
                  from: 'now-90y',
                  to: 'now-65y-1d'
                },
                {
                  from: 'now-200y',
                  to: 'now-90y-1d'
                }
              ]
            }
          }
        }
      }
    }
  };

  const result = useQuery(PEOPLE_AGE_QUERY, {
    variables: {
      ...resultQuery
    },
    skip: false,
    context: { version: '2' }
  });

  // todo: add result;
  // useEffect(() => {
  //   console.log('REFETCH??');
  //   setAppLoading(true);
  //   result.refetch(queryCriteria).finally(() => setAppLoading(false));
  // }, [queryCriteria]);

  return result;
};

export const useSelectedPeopleListingQuery = (
  offset: number,
  sort: string[],
  selectedIds: string[],
  excludeIds: boolean,
  allowFilter: boolean
) => {
  const [searchParams] = useSearchParams();
  const memoSort = useMemo(() => {
    return sort.length < 1 ? ['LAST_NAME_ASC', 'FIRST_NAME_ASC'] : sort;
  }, [sort]);

  const queryCriteria = useFilterQueryCriteriaFromUrl(
    allowFilter ? searchParams : new URLSearchParams(),
    'elasticSearch',
    excludeIds ? { 'ids-notin': [selectedIds.join('|')] } : { 'ids-in': [selectedIds.join('|')] }
  );
  const maybeGroupResultsByHousehold = useMaybeGroupResultsByAddress();

  const result = useQuery(PEOPLE_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: memoSort
    },
    skip: false,
    context: { version: '2' }
  });

  // todo: add result;
  // useEffect(() => {
  //   console.log('REFETCH??');
  //   setAppLoading(true);
  //   result.refetch(queryCriteria).finally(() => setAppLoading(false));
  // }, [queryCriteria]);

  return maybeGroupResultsByHousehold(result, searchParams);
};

export const useAppPeopleIdsOnlyListingQuery = () => {
  return useLazyQuery(PEOPLE_IDS_ONLY, {
    fetchPolicy: 'network-only',
    context: { version: '2' }
  });
};

export interface BaseAllPeopleListingProps {
  offset: number;
  sort: string[];
  source: DataSource;
  skip?: boolean;
  filterParameterOverrides?: FilterParameterOverrides;
}

export const useBaseAllPeopleListingQuery = ({
  offset,
  sort,
  source,
  skip,
  filterParameterOverrides
}: BaseAllPeopleListingProps) => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, source, filterParameterOverrides);
  const maybeGroupResultsByHousehold = useMaybeGroupResultsByAddress();

  const memoSort = useMemo(() => {
    return sort.length < 1 ? ['LAST_NAME_ASC', 'FIRST_NAME_ASC'] : sort;
  }, [sort]);

  const result = useQuery(PEOPLE_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: memoSort
    },
    skip: skip,
    context: { version: '2' }
  });

  // todo: add result;
  // useEffect(() => {
  //   console.log('REFETCH??');
  //   setAppLoading(true);
  //   result.refetch(queryCriteria).finally(() => setAppLoading(false));
  // }, [queryCriteria]);

  return maybeGroupResultsByHousehold(result, searchParams);
};

export const useSelectedPeopleCsvPropsForDownload = (selectedIds: string[]) => {
  const [searchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch', {
    'ids-in': [selectedIds.join('|')]
  });

  let query = PEOPLE_FOR_DOWNLOAD;

  //elimianting this function.  Don't think it gives us much and we need the id to be included for houshold filtering
  //if (headers) {
  //truncateQueryFields(query, headers);
  //}

  const csvProps: CsvProps = {
    query: query,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC', 'FIRST_NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};

export const useCsvPropsForDownload = (headers?: any[]) => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

  let query = PEOPLE_FOR_DOWNLOAD;

  //elimianting this function.  Don't think it gives us much and we need the id to be included for houshold filtering
  //if (headers) {
  //truncateQueryFields(query, headers);
  //}

  const csvProps: CsvProps = {
    query: query,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC', 'FIRST_NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewPeopleLists(elasticSearch: { index: "people" }) {
      totalCount
    }
  }
`;

export const VOTER_COUNT = gql`
  query PeopleCount($filter: JSONObject!) {
    people: viewPeopleLists(elasticSearch: $filter) {
      totalCount
    }
  }
`;

export const usePeopleTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

export const useVoterTotalCountOver65 = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useMemo(() => {
    const qc = getFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

    const yearsAgo = yearsAgoEsFilter('birthdate', searchParams, ['65', '200']);
    const kind = equalsEsFilter('kind', searchParams, ['voting_addresses']);

    qc.filter.query.bool.must ? qc.filter.query.bool.must.push(yearsAgo.must[0]) : (qc.filter.query.bool = yearsAgo);

    qc.filter.query.bool.must.push(kind.must[0]);
    return qc;
  }, [searchParams]);

  const result = useQuery(VOTER_COUNT, {
    variables: {
      ...queryCriteria
    },
    context: { version: '2' }
  });

  return result;
};

export const useVoterTotalCount = () => {
  const [searchParams] = useSearchParams();

  const queryCriteria = useMemo(() => {
    const qc = getFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

    const kind = equalsEsFilter('kind', searchParams, ['voting_addresses']);

    qc.filter.query.bool.must ? qc.filter.query.bool.must.push(kind.must[0]) : (qc.filter.query.bool = kind);
    return qc;
  }, [searchParams]);

  const result = useQuery(VOTER_COUNT, {
    variables: {
      ...queryCriteria
    },
    context: { version: '2' }
  });

  return result;
};

export const VOTER_COUNT_BY_YEAR = gql`
  query VoterCount($filter: JSONObject!) {
    result: viewPeopleLists(elasticSearch: $filter) {
      totalCount
    }
  }
`;

export const useGeneralVoteCountByYear = (ids: string[], year: number) => {
  const queryCriteria = {
    filter: {
      query: {
        bool: {
          must: {
            query_string: {
              default_field: 'voter_history.key.keyword',
              query: `*${year}_${GENERAL}_${VOTED}`
            }
          },
          filter: {
            ids: {
              values: ids
            }
          }
        }
      }
    }
  };

  const queryResult = useQuery(VOTER_COUNT_BY_YEAR, {
    variables: {
      ...queryCriteria
    },
    context: { version: '2' }
  });

  return queryResult;
};
