import dayjs from 'dayjs';
import { toSnakeCase } from '../../utils/format';

export const yearsAgoDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return {
    [field]: {
      lessThanOrEqualTo: dayjs().startOf('date').subtract(parseInt(filterValue[0]), 'year').format('YYYY-MM-DD'),
      greaterThan: dayjs()
        .startOf('date')
        .subtract(parseInt(filterValue[1]), 'year')
        .subtract(1, 'year')
        .format('YYYY-MM-DD')
    }
  };
};

export const yearsAgoEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return {
    must: [
      {
        range: {
          [snkField]: {
            lte: dayjs().startOf('date').subtract(parseInt(filterValue[0]), 'year').format('YYYY-MM-DD'),
            gt: dayjs()
              .startOf('date')
              .subtract(parseInt(filterValue[1]), 'year')
              .subtract(1, 'year')
              .format('YYYY-MM-DD'),
            format: 'yyyy-MM-dd'
          }
        }
      }
    ]
  };
};
