import { buildVariantClauses, splitName } from './utils';

export const pppLoanDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const firstLast = (filterValue[0] || '').split(' ');
  return firstLast.length > 1
    ? {
        and: [
          {
            firstName: { startsWithInsensitive: firstLast[0] }
          },
          {
            lastName: {
              startsWithInsensitive: firstLast[1]
            }
          }
        ]
      }
    : {
        or: [
          {
            firstName: { startsWithInsensitive: firstLast[0] }
          },
          {
            lastName: {
              startsWithInsensitive: firstLast[0]
            }
          }
        ]
      };
};

export const pppLoanEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  var firstLast = splitName(filterValue[0]);
  const multiTerm = firstLast.length > 1;

  const variants = buildVariantClauses(firstLast);
  return multiTerm
    ? {
        minimum_should_match: variants.minShouldMatch,
        should: [
          ...variants.variantClauses,
          {
            match_phrase: {
              borrowername_text: {
                query: `${filterValue[0]}`
              }
            }
          },
          {
            match_phrase: {
              loannumber_text: {
                query: `${filterValue[0]}`
              }
            }
          }
        ]
      }
    : {
        should: [
          {
            match_bool_prefix: {
              last_name_text: {
                query: firstLast[0]
              }
            }
          },
          {
            match_phrase: {
              borrowername_text: {
                query: `${filterValue[0]}`
              }
            }
          },
          {
            match_phrase: {
              loannumber_text: {
                query: `${filterValue[0]}`
              }
            }
          }
        ]
      };
};
