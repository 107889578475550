import { toSnakeCase } from '../../utils/format';

export const lessThanOrEqualToDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return {
    [field]: {
      lessThanOrEqualTo: filterValue[0]
    }
  };
};

export const lessThanOrEqualToEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return {
    must: [
      {
        range: {
          [snkField]: {
            lte: filterValue[0]
          }
        }
      }
    ]
  };
};
