import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const SUBSIDIZED_HOUSING_LISTING = gql`
  query SubsidizedHousingListing(
    $filter: ViewSubsidizedHousingFilter!
    $offset: Int!
    $orderBy: [ViewSubsidizedHousingsOrderBy!]
  ) {
    subsidizedHousing: viewSubsidizedHousings(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        name
        address
        cityId
        city
        countyId
        county
        state
        zip
        owner
        unitCount
        voterCount
        votersPerUnit
      }
      totalCount
    }
  }
`;

export const useSubsidizedHousingQuery = (offset: number, sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  useEffect(() => {
    if (sort.length < 1) {
      sort = loadDefaultSort(searchParams, sort, ['NAME_ASC']);
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const filter = {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: '2' }
  };

  const result = useQuery(SUBSIDIZED_HOUSING_LISTING, filter);

  return result;
};

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: viewSubsidizedHousings(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useStateSubsidizedHousingCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY, { context: { version: '2' } });
};

const COUNTIES_QUERY = gql`
  query CountiesByState($filter: ViewSubsidizedHousingFilter!) {
    counties: viewSubsidizedHousings(filter: $filter) {
      groupedAggregates(groupBy: [COUNTY_ID, COUNTY, STATE]) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useSubsidizedHousingCounties = () => {
  return useLazyQuery(COUNTIES_QUERY, { context: { version: '2' } });
};

const CITIES_QUERY = gql`
  query CitiesForState($filter: ViewSubsidizedHousingFilter!) {
    cities: viewSubsidizedHousings(filter: $filter) {
      groupedAggregates(groupBy: [CITY_ID, CITY, COUNTY, COUNTY_ID, STATE]) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useSubsidizedHousingCities = () => {
  return useLazyQuery(CITIES_QUERY, { context: { version: '2' } });
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewSubsidizedHousings {
      totalCount
    }
  }
`;

export const useSubsidizedHousingTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

const SUBSIDIZED_HOUSING_FOR_DOWNLOAD = gql`
  query SubsidizedHousingDownload(
    $first: Int!
    $filter: ViewSubsidizedHousingFilter!
    $offset: Int!
    $orderBy: [ViewSubsidizedHousingsOrderBy!]
  ) {
    csv: viewSubsidizedHousings(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        name
        address
        city
        county
        state
        zip
        owner
        unitCount
        voterCount
        votersPerUnit
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: SUBSIDIZED_HOUSING_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};
