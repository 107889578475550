import dayjs from 'dayjs';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { VersionState } from '../../App';
import { ClassNameProps } from '../../common/Props';
import { useExpandedNav } from '../../store/app';
import Breadcrumb from '../Breadcrumb';
import UserFilter from '../UserFilter';
import { Nav, NavBar } from './NavBar';
import NavMenu from './NavMenu';
import PendingUsers from './PendingUsers';

type MainLayoutProps = ClassNameProps & {
  versionState: VersionState;
  leftNav: React.ReactNode;
};

export const MainLayout = styled(({ versionState, className, leftNav }: MainLayoutProps) => {
  const expandedNav = useExpandedNav();
  return (
    <div className={`${className} main-layout${expandedNav ? '--expanded' : ''}`}>
      <div className="main-layout__body">
        <div className="main-layout__body__left-nav">{leftNav}</div>
        <div className="main-layout__body__content">
          <div className="main-layout__header">
            <NavBar>
              <Nav className="main-layout__nav-right">
                <Breadcrumb />
                <PendingUsers />
                <UserFilter />
                <NavMenu />
              </Nav>
            </NavBar>
          </div>
          <Outlet />
        </div>
      </div>
      <div className="copyright" title={`${versionState.version} - ${versionState.date} (${versionState.sha})`}>
        This product uses the Census Bureau Data API but is not endorsed or certified by the Census Bureau. &copy;{' '}
        {dayjs().format('YYYY')} Eldorado Grand LLC. All rights reserved.
      </div>
    </div>
  );
})`
  height: 100%;
  margin: 0;
  max-height: 100%;
  overflow-x: hidden;

  .copyright {
    text-align: right;
    font-size: 10px;
    color: var(--gray-dark);
    padding-right: 16px;
    padding-bottom: 8px;
  }

  @media (orientation: landscape) {
    overflow-y: auto;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .navbar-toggler {
    transition:
      right,
      transform 0.3s;
    right: 0;
    transform: translateX(50%);
    @media (max-width: 767.98px) {
      transform: translateX(60%);
    }
  }

  &.main-layout--expanded {
    .navbar-toggler {
      right: 40px;
    }

    .nav-search {
      display: block;
    }
    .main-layout__header {
      width: calc(100vw - (var(--left-nav-width) + 80px));
      .navbar {
        box-shadow: var(--header-box-shadow);
        border-radius: var(--border-radius-xs);
      }
      @media (max-width: 767.98px) {
        width: calc(100vw - 45px);
        top: 10px;
      }
    }
    .main-layout__top-nav {
      color: var(--left-nav-color);
      background-color: var(--primary);
    }

    .main-layout__body {
      .main-layout__body__left-nav {
        width: var(--left-nav-width);
        .filter {
          display: block;
          opacity: 1;
          height: auto;
        }
        .label {
          display: inline;
          opacity: 1;
          width: auto;
        }
      }
      .main-layout__body__content {
        transition: margin-left 0.3s;
        margin-left: var(--left-nav-width);
        padding-bottom: 2px;
      }
    }

    @media (max-width: 767.98px) {
      .main-layout__body {
        .main-layout__body__left-nav {
          position: absolute;
          left: 0;
        }
        .main-layout__body__content {
          margin-left: 5px;
        }
      }
    }
  }
  .main-layout__header {
    position: fixed;
    z-index: 1001;
    transition: width 0.3s;
    height: var(--header-height);
    top: 20px;
    box-shadow: var(--header-box-shadow);
    border-radius: var(--border-radius-xs);
    width: calc(100vw - (var(--left-nav-width-collapsed) + 80px));
    @media (max-width: 767.98px) {
      width: calc(100vw - 45px);
      top: 10px;
      height: calc(var(--header-height) - 15px);
    }
    .navbar {
      box-shadow: var(--header-box-shadow);
      border-radius: 16px;
    }

    .main-layout__top-nav {
      padding: var(--nav-padding);
    }
    .main-layout__header__header {
      transition: width 0.3s;
      position: relative;
      background-color: var(--primary);
      height: var(--header-height);
      width: 0;
      text-align: center;
      .navbar-brand {
        max-width: 120px;
        opacity: 0;
      }
    }
    .main-layout__nav-right {
      flex-grow: 1;
      justify-content: flex-end;
      align-self: stretch;
      position: relative;
      padding-right: 0;
      #nav-dropdown {
        line-height: 36px;
        display: inline-block;
        padding: 7px 15px;
        border-left: 1px solid #f6f6f6;
        &:hover {
          color: #6185a8;
        }
      }
      .show {
        #nav-dropdown {
          background-color: var(--gray);
          &:hover {
            color: var(--white);
          }
        }
      }
    }
  }
  .main-layout__body {
    padding-top: var(--header-height);
    flex-basis: 100%;

    .main-layout__body__content {
      transition: margin-left 0.3s;
      margin-left: var(--left-nav-width-collapsed);
      padding: var(--content-padding);
      padding-top: 12px;
      height: 100%;

      @media (max-width: 767.98px) {
        margin-left: 5px;
      }
    }
    .main-layout__body__left-nav {
      position: fixed;
      top: 15px;
      color: var(--left-nav-color);
      padding-top: 5px;
      padding-bottom: 5px;
      height: 100%;
      display: block;
      float: left;
      transition: width 0.3s;
      width: var(--left-nav-width-collapsed);
      z-index: 1002;
      background-color: transparent;
      .label {
        opacity: 0;
        transition: all 0.3s;
        position: absolute;
        width: 0;
        overflow: hidden;
      }
      .filter {
        opacity: 0;
        transition: all 0.3s;
        height: 0;
        // overflow: hidden;
        .filter__title {
          white-space: nowrap;
        }
      }

      @media (max-width: 767.98px) {
        top: 0;
        padding: 0;
        left: calc(var(--left-nav-width-collapsed) * -1);
      }
    }
  }
`;
