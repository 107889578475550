import isPropValid from '@emotion/is-prop-valid';
import React from 'react';
import { StyleSheetManager } from 'styled-components';
import './theme.scss';

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return <StyleSheetManager shouldForwardProp={shouldForwardProp}>{children}</StyleSheetManager>;
};

function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
