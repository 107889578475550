import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { useFilterQueryCriteriaFromUrl } from '../../utils/filter';
import { PROPERTY_FRAGMENT } from '../property';

const PROPERTIES_LISTING = gql`
  query PropertiesListing($filter: JSONObject!, $offset: Int!, $orderBy: [ViewPropertiesOrderBy!]) {
    properties: viewProperties(first: 150, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Property
      }
      totalCount
      totalAggCount
    }
  }
  ${PROPERTY_FRAGMENT}
`;

const STATES_FOR_PROPERTIES_QUERY = gql`
  query StatesQuery($filter: JSONObject!) {
    states: viewProperties(elasticSearch: $filter) {
      esResult
    }
  }
`;

const COUNTIES_FOR_PROPERTIES_QUERY = gql`
  query CountiesByState($filter: JSONObject!) {
    counties: viewProperties(elasticSearch: $filter) {
      esResult
    }
  }
`;

const CITIES_FOR_PROPERTIES_QUERY = gql`
  query CitiesForState($filter: JSONObject!) {
    cities: viewProperties(elasticSearch: $filter) {
      esResult
    }
  }
`;

const COMPLEXES_FOR_PROPERTIES_QUERY = gql`
  query ComplexesForState($filter: JSONObject!) {
    complexes: viewProperties(elasticSearch: $filter) {
      esResult
    }
  }
`;

const SUBDIVISIONS_FOR_PROPERTIES_QUERY = gql`
  query SubdivisionsForState($filter: JSONObject!) {
    subdivisions: viewProperties(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useStates = () => {
  const filter: any = {
    aggs: {
      results: {
        terms: {
          field: 'state',
          size: 59
        }
      }
    },
    size: 0
  };
  const vars = { variables: { filter: filter }, context: { version: '2' } };

  return useLazyQuery(STATES_FOR_PROPERTIES_QUERY, vars);
};

export const useCountiesForState = () => {
  return useLazyQuery(COUNTIES_FOR_PROPERTIES_QUERY, { context: { version: '2' } });
};

export const useCitiesForState = () => {
  return useLazyQuery(CITIES_FOR_PROPERTIES_QUERY, { context: { version: '2' } });
};

export const useComplexes = () => {
  return useLazyQuery(COMPLEXES_FOR_PROPERTIES_QUERY, { context: { version: '2' } });
};

export const useSubdivisions = () => {
  return useLazyQuery(SUBDIVISIONS_FOR_PROPERTIES_QUERY, { context: { version: '2' } });
};

export const useAllPropertiesListingQuery = (offset: number, sort: string[]) => {
  const [searchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

  if (sort.length < 1) {
    sort.push('address_ASC');
  }

  if (sort?.indexOf('address_ASC') > -1) {
    sort.splice(
      sort?.indexOf('address_ASC'),
      1,
      'CITY_ASC',
      'STREET_NAME_ASC',
      'STREET_TYPE_ASC',
      'PRE_DIRECTION_ASC',
      'HOUSE_NUMBER_ASC'
    );
  }
  if (sort?.indexOf('address_DESC') > -1) {
    sort.splice(
      sort?.indexOf('address_DESC'),
      1,
      'CITY_DESC',
      'STREET_NAME_DESC',
      'STREET_TYPE_DESC',
      'PRE_DIRECTION_DESC',
      'HOUSE_NUMBER_DESC'
    );
  }

  const result = useQuery(PROPERTIES_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: '2' }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

const PROPERTIES_FOR_DOWNLOAD = gql`
  query PropertiesDownload($first: Int!, $filter: JSONObject!, $offset: Int!, $orderBy: [ViewPropertiesOrderBy!]) {
    csv: viewProperties(first: $first, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Property
      }
    }
  }
  ${PROPERTY_FRAGMENT}
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

  const csvProps: CsvProps = {
    query: PROPERTIES_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['OWNER_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewProperties(elasticSearch: { index: "propertiesv2" }) {
      totalCount
    }
  }
`;

export const usePropertiesTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

const PROPERTIES_BY_VOTER_ID = gql`
  query PropertiesByVoterId($id: UUID) {
    property: viewVoterAtPropertiesConnection(condition: { voterId: $id }) {
      nodes {
        propertyId
        voterId
        lat
        lon
        propertyLat
        propertyLon
      }
    }
  }
`;

export const usePropertiesByVoterIdQuery = (id: string) => {
  return useQuery(PROPERTIES_BY_VOTER_ID, { variables: { id: id } });
};
