import { toUpper } from 'lodash';
import { stripPunctuation } from '../../utils/search';

export const propertyDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return filterValue.length > 0
    ? {
        or: [
          {
            address: { startsWithInsensitive: filterValue[0] }
          }
        ]
      }
    : {};
};
export const propertyEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (filterValue[0].length <= 1) {
    return {};
  }

  const tokens = filterValue[0].split(' ');

  const query = tokens
    .map((t: string) => {
      return `((*${t}*) OR (*${stripPunctuation(toUpper(t))}*))`;
    })
    .join(' AND ');

  return {
    must: [
      {
        query_string: {
          query: query,
          fields: ['address'],
          analyze_wildcard: true
        }
      }
    ]
  };
};

export const propertyOwnerDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return filterValue.length > 0
    ? {
        or: [
          {
            owner: { likeInsensitive: '%' + filterValue[0] + '%' }
          }
        ]
      }
    : {};
};
export const propertyOwnerEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (filterValue[0].length <= 1) {
    return {};
  }

  const tokens = filterValue[0].split(' ');

  let resp = ``;
  tokens.forEach((token) => {
    if (resp) {
      resp += `,`;
    }
    resp +=
      `{
      "query_string": {
        "query": "*` +
      token +
      `*",
        "fields": ["owner_text"]
      }
    }`;
  });
  resp = `{"must":[` + resp + `]}`;

  return JSON.parse(resp);
};
