import { camelCase, memoize } from 'lodash';

//sorts a string containing a number. ensures the number portion is treated as a number.
const sortStringWithNumber = (a: string = '', b: string = '') => {
  let aMatch = a.match(/\d+/);
  let bMatch = b.match(/\d+/);
  const aNumber = aMatch ? aMatch[0] : '';
  const bNumber = bMatch ? bMatch[0] : '';
  const [aBefore, aAfter] = extractBeforeAndAfter(aNumber, a);
  const [bBefore, bAfter] = extractBeforeAndAfter(bNumber, b);

  if (aBefore > bBefore) {
    return 1;
  }
  if (aBefore < bBefore) {
    return -1;
  }
  if (aNumber.length > bNumber.length) {
    return 1;
  }
  if (aNumber.length < bNumber.length) {
    return -1;
  }
  if (aNumber > bNumber) {
    return 1;
  }
  if (aNumber < bNumber) {
    return -1;
  }
  if (aAfter > bAfter) {
    return 1;
  }
  if (aAfter < bAfter) {
    return -1;
  }
  return 0;
};

const extractBeforeAndAfter = (foundNumber: string, text: string): string[] => {
  if (foundNumber.length > 0) {
    let indexOfNumber = text.indexOf(foundNumber);
    return [text.substring(0, indexOfNumber), text.substring(indexOfNumber + foundNumber.length)];
  }
  return [text, ''];
};

export const sortListBySortParams = memoize((list: any[], sort: string[]) => {
  if (sort.length < 1) {
    return [];
  }
  const keys = sort[0].split('_');
  const direction = keys[keys.length - 1];
  const fieldName = sort[0].substring(0, sort[0].lastIndexOf('_'));
  list.sort((a: any, b: any) => {
    const fieldA = a[camelCase(fieldName)] || a[fieldName] || '';
    const fieldB = b[camelCase(fieldName)] || b[fieldName] || '';

    let res = 0;
    if (isNaN(fieldA) && isNaN(fieldB)) {
      res = fieldA.localeCompare(fieldB);
    } else {
      res = Number(fieldA) - Number(fieldB);
    }

    return direction === 'DESC' ? res * -1 : res;
  });

  return [...list];
});

const facetSort = (a: any, b: any) => {
  let aOrder = a.order || 0;
  let bOrder = b.order || 0;

  let result = aOrder - bOrder;
  if (result === 0) {
    result = a.label.localeCompare(b.label);
  }

  return result;
};

export { sortStringWithNumber, facetSort };
