import { gql, useLazyQuery, useQuery } from '@apollo/client';

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    options: viewElectionResultsByPrecinctsConnection(
      elasticSearch: {
        aggs: {
          states: {
            terms: { field: "state", size: 75 }
            aggs: { contests: { cardinality: { field: "contest_name" } } }
          }
        }
        size: 0
        index: "electionresults"
      }
      orderBy: NATURAL
    ) {
      aggBuckets
    }
  }
`;

export const ELECTION_RESULTS_OPTIONS_QUERY = gql`
  query ElectionResultsQuery($filter: JSONObject!) {
    options: viewElectionResultsByPrecinctsConnection(elasticSearch: $filter, orderBy: NATURAL) {
      aggBuckets
    }
  }
`;

export const ELECTION_RESULTS_QUERY = gql`
  query ElectionResultsQuery($filter: JSONObject!) {
    result: viewElectionResultsByPrecinctsConnection(elasticSearch: $filter, orderBy: NATURAL) {
      aggBuckets
    }
  }
`;

export const useElectionResultsQuery = () => {
  return useLazyQuery(ELECTION_RESULTS_QUERY);
};

export const useElectionResultsOptionsQuery = () => {
  return useLazyQuery(ELECTION_RESULTS_OPTIONS_QUERY);
};

export const useStateElectionResultsCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY);
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewElectionResultsConnection {
      totalCount
    }
  }
`;

export const useElectionResultsTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, {});
};

export * from './contests';
export * from './counties';
export * from './precincts';
export * from './townships';
