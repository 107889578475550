import { toSnakeCase } from '../../utils/format';

export const flagDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return {
    [filterValue[0]]: { equalTo: filterValue.length === 2 && filterValue[1] === 'false' ? false : true }
  };
};
export const flagEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return {
    must: [
      {
        match: {
          [toSnakeCase(filterValue[0])]: {
            query: filterValue.length === 2 && filterValue[1] === 'false' ? false : true
          }
        }
      }
    ]
  };
};
