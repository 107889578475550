import { env } from '../../env';

export type UserListFilter = {
  id: string;
  user_id: string;
  filter_type: string;
  filter_name: string;
  filter: string;
};

export const fetchUserListFilter = async (userId: string, callback: Function) => {
  await fetch(
    `${env.REACT_APP_AUTH_URL}realms/${env.REACT_APP_AUTH_REALM}/user-resource-api/resources/list-filter/${userId}`,
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )
    .then((response) => response.json())
    .then((data) => callback(data));
};

export const deleteUserListFilter = async (userId: string, id: string, success: Function, failure: Function) => {
  await fetch(
    `${env.REACT_APP_AUTH_URL}realms/${env.REACT_APP_AUTH_REALM}/user-resource-api/resource/list-filter/${userId}/${id}`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: '[]'
    }
  )
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        success();
      } else {
        failure('Unable to execute delete');
      }
    })
    .catch((error) => {
      failure(error);
    });
};

export const saveUserListFilter = async (
  userId: string,
  filterType: string,
  name: string,
  filter: string,
  success: Function,
  failure: Function
) => {
  await fetch(`${env.REACT_APP_AUTH_URL}realms/${env.REACT_APP_AUTH_REALM}/user-resource-api/resource/list-filter`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      user_id: userId,
      filter_type: filterType,
      filter_name: name,
      filter
    })
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.id) {
        success();
      }
    })
    .catch((error) => {
      failure(error);
    });
};
