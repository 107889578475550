import { faCrosshairs, faRobot } from '@fortawesome/free-solid-svg-icons';
import { DATA_MANAGER_ROLE } from '../../utils/auth';
import { routes } from '../routes/routes';
import { NavItem, SubMenu } from './NavigationConfig';

const DataLoadNavigationConfig: (NavItem | SubMenu)[] = [
  {
    roles: [DATA_MANAGER_ROLE],
    filterType: 'data-load',
    title: 'Data Matching',
    route: `/${routes.dataLoad}`,
    icon: faCrosshairs
  },
  {
    roles: [DATA_MANAGER_ROLE],
    filterType: 'automated-data-load',
    title: 'Automated Data Matching',
    route: `/${routes.automatedDataLoad}`,
    icon: faRobot
  }
];

export default DataLoadNavigationConfig;
