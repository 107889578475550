import { KeycloakTokenParsed } from 'keycloak-js';
import keycloak from '../keycloak';

export const getJWTToken = (): string | null => {
  return keycloak.token || null;
};

export const getRefreshToken = (): string | null => {
  return keycloak.refreshToken || null;
};

export const getParsedJWTToken = (): KeycloakTokenParsed | null => {
  return keycloak.tokenParsed || null;
};

export const isTokenValid = () => {
  let currentDate = new Date();
  const token = getJWTToken();
  if (!token || token === 'null') {
    return false;
  }
  const decodedToken: any = (keycloak as any).tokenParsed;
  return decodedToken.exp * 1000 >= currentDate.getTime();
};

export const useSubscriptionActive = (): boolean => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const success = searchParams.get('sub_success');
  // if (success === 'true') {
  //   showSuccess('Subscription signup successful');
  //   searchParams.delete('sub_success');
  //   console.log(searchParams)
  //   setSearchParams(new URLSearchParams(searchParams));
  // } else if (success === 'false') {
  //   showWarning('Subscription signup cancelled');
  //   searchParams.delete('sub_success');
  //   setSearchParams(new URLSearchParams(searchParams));
  // }

  const token = getParsedJWTToken();
  const active: boolean = token?.subscription_active;
  return active;
};

export const VOTER_ROLE = 'voter-role';
export const JUDICIAL_ROLE = 'judicial-role';
export const ADMIN_ROLE = 'admin-role';
export const FEATURE_ROLE = 'feature-role';
export const MESSAGING_ROLE = 'messaging-role';
export const DATA_MANAGER_ROLE = 'data-manager-role';

export const hasRole = (role: string): boolean => {
  return keycloak.hasResourceRole(role, 'roseland');
};

export const isUserActivated = (): boolean => {
  return keycloak?.tokenParsed?.approved;
};

export const logout = async () => {
  await keycloak.logout({ redirectUri: `${window.location.href}` });
};
export const setJWTToken = (token?: string | null) => {
  if (token && token !== 'null') {
    localStorage.setItem('auth_token', token);
  }
};

export const useUserInfo = (): KeycloakTokenParsed | null => {
  if (!isTokenValid()) {
    return null;
  }
  return getParsedJWTToken();
};

function copyToClipboardFallback(text) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    copyToClipboardFallback(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
}
