import { useLazyQuery } from '@apollo/client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import React, { memo, useCallback, useState } from 'react';
import { ButtonProps } from 'react-bootstrap';
import { useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { downloadCsv } from '../../utils/csv';
import { useNumberFormat } from '../../utils/format';
import { useMaybeGroupResultsByAddress } from '../../utils/table';
import Button from '../Button';
import Icon from '../Icon';

export interface CsvProps extends ButtonProps {
  query: any;
  vars: any;
  totals?: number;
  aggregateTotals?: number;
  className?: string;
  data?: any;
  context?: any;
  icon?: IconProp;
}

const DownloadBatchSize = 2000;

const DownloadCsv = styled(
  memo(
    ({
      headers,
      query,
      vars,
      totals,
      aggregateTotals,
      className,
      data,
      context,
      icon,
      ...rest
    }: CsvProps & { headers: any[] }) => {
      const [getCsvData, { loading }] = useLazyQuery(query);
      const [searchParams] = useSearchParams();
      const maybeGroupResultsByHousehold = useMaybeGroupResultsByAddress();

      const max = 250000;
      const RECORDS_PER_FILE = 30000;
      const disabled = totals === 0 || (totals || 0) > max || loading;

      const [pct, setPct] = useState<number>(0);

      const location = useLocation();

      const numberFormat = useNumberFormat({
        maximumFractionDigits: 0
      });

      const handleClick = useCallback(async () => {
        if (disabled) {
          return;
        }
        setPct(data ? 100 : 0);

        let nodes = data || [];
        let totalComplete = 0;
        let count = 1;
        if (!data) {
          vars.first = DownloadBatchSize;

          let content = await getCsvData({
            variables: vars,
            context: context ? context : '',
            fetchPolicy: 'no-cache'
          });

          headers = headers.filter((o: any) => !o.hideFromColumnSelector);

          nodes = content?.data?.csv?.nodes;

          let totalCount = aggregateTotals || 0;
          setPct((nodes.length / totalCount) * 100);

          // TODO: Fix this hack
          while (content?.data?.csv?.nodes.length === DownloadBatchSize) {
            vars.offset = vars.offset + DownloadBatchSize;
            content = await getCsvData({ variables: vars, fetchPolicy: 'no-cache' });
            nodes = nodes.concat(content?.data?.csv?.nodes);
            setPct(((nodes.length + totalComplete) / totalCount) * 100);
            if (nodes.length >= RECORDS_PER_FILE) {
              downloadCsv(headers, nodes || [], location.pathname, count++);
              totalComplete += nodes.length;
              nodes = [];
            }
          }

          nodes = maybeGroupResultsByHousehold({ data: { csv: { nodes: nodes } } }, searchParams, 'csv');

          //batch the json back into an array
          nodes = nodes.data?.csv?.nodes || nodes;
        }
        if (nodes.length > 0) {
          downloadCsv(headers, data || nodes || [], location.pathname, count);
        }
      }, [
        disabled,
        maybeGroupResultsByHousehold,
        searchParams,
        getCsvData,
        aggregateTotals,
        setPct,
        vars,
        context,
        data,
        headers,
        location.pathname,
        totals
      ]);

      return (
        <Button
          {...rest}
          title={`Download ${totals} results`}
          onClick={handleClick}
          variant="white"
          className={`${className} download ${disabled ? 'disabled' : ''}`}
        >
          <Icon
            size="lg"
            className={loading ? 'spinner' : ''}
            icon={loading ? (faSpinner as IconProp) : icon ? icon : (faDownload as IconProp)}
          />
          {(totals || 0) > max ? <span style={{ fontSize: '12px' }}>{numberFormat(max)} max</span> : null}
          {loading ? <span style={{ fontSize: '12px' }}>Please wait... ({numberFormat(pct)}%)</span> : null}
        </Button>
      );
    }
  )
)`
  @media (max-width: 767.98px) {
    display: none;
  }
  color: var(--primary);
  line-height: 0;
  span.info {
    font-size: 6px;
  }

  .spinner {
    animation: load 1.4s infinite linear;
    transform: translateZ(0);
  }

  @keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default DownloadCsv;
export { DownloadBatchSize };
