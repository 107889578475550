import { debounce } from 'lodash';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

const useScreenSize = () => {
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  const resizeListener = useRef(
    debounce(() => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }, 100)
  );

  useEffect(() => {
    const listener = resizeListener.current;
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [resizeListener]);

  return size;
};

function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

// Stolen from:https://github.com/juliencrn/usehooks-ts

type UseMediaQueryOptions = {
  defaultValue?: boolean;
  initializeWithValue?: boolean;
};

const IS_SERVER = typeof window === 'undefined';

export function useMediaQuery(query: string, options?: UseMediaQueryOptions): boolean;

export function useMediaQuery(query: string, options?: boolean | UseMediaQueryOptions): boolean {
  // TODO: Refactor this code after the deprecated signature has been removed.
  const defaultValue = typeof options === 'boolean' ? options : (options?.defaultValue ?? false);
  const initializeWithValue = typeof options === 'boolean' ? undefined : (options?.initializeWithValue ?? undefined);

  const getMatches = (query: string): boolean => {
    if (IS_SERVER) {
      return defaultValue;
    }
    return window.matchMedia(query).matches;
  };

  const [matches, setMatches] = useState<boolean>(() => {
    if (initializeWithValue) {
      return getMatches(query);
    }
    return defaultValue;
  });

  /** Handles the change event of the media query. */
  function handleChange() {
    setMatches(getMatches(query));
  }

  useLayoutEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Use deprecated `addListener` and `removeListener` to support Safari < 14 (#135)
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
  }, [query, handleChange]);

  return matches;
}

export { useScreenSize, useTraceUpdate };
