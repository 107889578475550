import { createReduxModule } from 'hooks-for-redux';

interface ConfirmModalProps {
  message: string;
  title?: string;
  open: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}

export const [useConfirmModal, setConfirmModal] = createReduxModule<ConfirmModalProps>('confirmModal', {
  message: '',
  title: 'Confirm',
  open: false,
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  onConfirm: () => {},
  onCancel: () => {}
});
