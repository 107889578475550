export const routes = {
  root: '/',
  app: 'app',
  messaging: 'messaging',
  dataLoad: 'data-load',
  automatedDataLoad: 'auto-data-load',
  people: 'people',
  dataLoadStart: 'start/:id?',
  automatedDataLoadStart: 'start/:id?',
  messagingPortfolios: 'portfolios',
  messagingNewPortfolio: '/messaging/portfolios/new',
  messagingEditPortfolio: '/messaging/portfolios/edit/:portfolioId',
  messagingCampaigns: 'campaigns',
  messagingStatistics: 'statistics',
  messagingCampaignStats: 'campaigns/statistics/:campaignId',
  messagingNewCampaign: '/messaging/campaigns/new',
  messagingEditCampaign: '/messaging/campaigns/edit/:campaignId',
  messagingShareList: '/messaging/share',
  messagingGroups: 'groups',
  messagingNewGroup: '/messaging/groups/new',
  messagingEditGroup: '/messaging/groups/edit/:groupId',
  messagingStories: 'stories',
  peopleSearch: 'search',
  person: 'people/:id',
  personFacet: 'people/:id/:facetId',
  geographies: 'geographies',
  geography: 'geographies/:type/:id',
  candidates: 'candidates',
  candidate: 'candidates/:id',
  candidateFacet: 'candidates/:id/:facetId',
  checkout: 'checkout',
  paymentResult: 'payment-result',
  properties: 'properties',
  property: 'properties/:id',
  propertyFacet: 'properties/:id/:facetId',
  users: 'admin/users',
  preferences: 'preferences',
  higherEducationSalaries: 'higher-ed-salaries',
  higherEducationSalary: 'higher-ed-salaries/:id',
  higherEducationSalaryFacet: 'higher-ed-salaries/:id/:facetId',
  pppLoans: 'ppp-loans',
  pppLoan: 'ppp-loans/:id',
  pppLoanFacet: 'ppp-loans/:id/:facetId',
  courts: 'courts',
  court: 'courts/:id',
  courtFacet: 'courts/:id/:facetId',
  judges: 'judges',
  judge: 'judges/:id',
  judgeFacet: 'judges/:id/:facetId',
  cases: 'cases',
  case: 'cases/:id',
  caseFacet: 'cases/:id/:facetId',
  lawyers: 'lawyers',
  lawyer: 'lawyers/:id',
  lawyerFacet: 'lawyers/:id/:facetId',
  lawfirms: 'lawfirms',
  lawfirm: 'lawfirms/:id',
  lawfirmFacet: 'lawfirms/:id/:facetId',
  schools: 'schools',
  school: 'schools/:type/:id',
  schoolFacet: 'schools/:id/:type/:facetId',
  schoolBoards: 'school-boards',
  schoolBoard: 'school-boards/:id',
  university: 'schools/university/:id',
  universityFacet: 'schools/university/:id/:facetId',
  lawschool: 'schools/law_school/:id',
  lawschoolFacet: 'schools/law_school/:id/:facetId',
  requiresActivation: 'requires-activation',
  userApproval: 'user-approval',
  electionResults: 'election-results',
  electionCountyList: 'election-results/:state/:contest/:type/:year',
  electionTownshipPrecinctList: 'election-results/:state/:contest/:type/:year/:countyId/:subType',
  electionTownshipVoterList: 'election-results/:state/:contest/:type/:year/:countyId/townships/:townshipId',
  organizations: 'organizations',
  organization: 'organizations/:id',
  organizationFacet: 'organizations/:id/:facetId',
  features: 'admin/features',
  nursingHomes: 'nursing-homes',
  nursingHome: 'nursing-homes/:id',
  nursingHomeFacet: 'nursing-homes/:id/:facetId',
  subsidizedHousings: 'subsidized-housing',
  subsidizedHousing: 'subsidized-housing/:id',
  subsidizedHousingFacet: 'subsidized-housing/:id/:facetId',
  pensions: 'pensions',
  pension: 'pensions/:id',
  pensionFacet: 'pensions/:id/:facetId',
  unions: 'unions',
  union: 'unions/:id',
  unionFacet: 'unions/:id/:facetId',
  ncoas: 'ncoas',
  ncoa: 'ncoas/:id',
  ncoaFacet: 'ncoas/:id/:facetId',
  unregisteredPeople: 'suspect-non-voters',
  unregisteredPerson: 'suspect-non-voters/:id',
  unregisteredPersonFacet: 'suspect-non-voters/:id/:facetId',
  voterFiles: 'voter-files',
  campaignContributions: 'campaign-contributions',
  campaignContribution: 'campaign-contributions/:personId/:id',
  campaignContributionFacet: 'campaign-contributions/:personId/:id/:facetId',
  mobileHomes: 'mobile-home',
  mobileHome: 'mobile-home/:id',
  mobileHomeFacet: 'mobile-home/:id/:facetId',
  dormitories: 'dormitory',
  dormitory: 'dormitory/:id',
  dormitoryFacet: 'dormitory/:id/:facetId',
  arrest: 'arrests/:id',
  arrests: 'arrests',
  arrestFacet: 'arrests/:id/:facetId',
  emailOutreaches: 'email-outreaches',
  emailOutreach: 'email-outreaches/:id',
  emailOutreachFacet: 'email-outreaches/:id/:facetId'
};

export const paths = {
  dashboard: `${routes.root}${routes.app}`,
  people: `${routes.root}${routes.app}/${routes.people}`,
  peopleSearch: `${routes.root}${routes.app}/${routes.peopleSearch}`,
  person: `${routes.root}${routes.app}/${routes.people}/`,
  geographies: `${routes.root}${routes.app}/${routes.geographies}/`,
  geography: `${routes.root}${routes.app}/${routes.geographies}/`,
  candidates: `${routes.root}${routes.app}/${routes.candidates}`,
  candidate: `${routes.root}${routes.app}/${routes.candidates}/`,
  properties: `${routes.root}${routes.app}/${routes.properties}`,
  checkout: `${routes.root}${routes.app}/${routes.checkout}`,
  paymentResult: `${routes.root}${routes.app}/${routes.paymentResult}`,
  property: `${routes.root}${routes.app}/${routes.properties}/`,
  higherEducationSalaries: `${routes.root}${routes.app}/${routes.higherEducationSalaries}`,
  higherEducationSalary: `${routes.root}${routes.app}/${routes.higherEducationSalaries}/`,
  pppLoans: `${routes.root}${routes.app}/${routes.pppLoans}`,
  pppLoan: `${routes.root}${routes.app}/${routes.pppLoans}/`,
  users: `${routes.root}${routes.app}/${routes.users}`,
  courts: `${routes.root}${routes.app}/${routes.courts}`,
  court: `${routes.root}${routes.app}/${routes.courts}/`,
  judges: `${routes.root}${routes.app}/${routes.judges}`,
  judge: `${routes.root}${routes.app}/${routes.judges}/`,
  cases: `${routes.root}${routes.app}/${routes.cases}`,
  case: `${routes.root}${routes.app}/${routes.cases}/`,
  lawyers: `${routes.root}${routes.app}/${routes.lawyers}`,
  lawyer: `${routes.root}${routes.app}/${routes.lawyers}/`,
  lawfirms: `${routes.root}${routes.app}/${routes.lawfirms}`,
  lawfirm: `${routes.root}${routes.app}/${routes.lawfirms}/`,
  schools: `${routes.root}${routes.app}/${routes.schools}`,
  school: `${routes.root}${routes.app}/${routes.schools}/`,
  schoolBoards: `${routes.root}${routes.app}/${routes.schoolBoards}`,
  schoolBoard: `${routes.root}${routes.app}/${routes.schoolBoards}`,
  userApproval: `${routes.root}${routes.app}/${routes.userApproval}/`,
  requiresActivation: `${routes.root}${routes.app}/${routes.requiresActivation}/`,
  electionResults: `${routes.root}${routes.app}/${routes.electionResults}`,
  electionCountyList: `${routes.root}${routes.app}/${routes.electionCountyList}/`,
  electionTownshipPrecinctList: `${routes.root}${routes.app}/${routes.electionTownshipPrecinctList}/`,
  electionTownshipVoterList: `${routes.root}${routes.app}/${routes.electionTownshipVoterList}/`,
  organizations: `${routes.root}${routes.app}/${routes.organizations}`,
  organization: `${routes.root}${routes.app}/${routes.organizations}/`,
  features: `${routes.root}${routes.app}/${routes.features}/`,
  nursingHomes: `${routes.root}${routes.app}/${routes.nursingHomes}`,
  nursingHome: `${routes.root}${routes.app}/${routes.nursingHomes}/`,
  subsidizedHousings: `${routes.root}${routes.app}/${routes.subsidizedHousings}`,
  subsidizedHousing: `${routes.root}${routes.app}/${routes.subsidizedHousings}/`,
  pensions: `${routes.root}${routes.app}/${routes.pensions}`,
  pension: `${routes.root}${routes.app}/${routes.pensions}/`,
  unions: `${routes.root}${routes.app}/${routes.unions}`,
  union: `${routes.root}${routes.app}/${routes.unions}/`,
  ncoas: `${routes.root}${routes.app}/${routes.ncoas}`,
  ncoa: `${routes.root}${routes.app}/${routes.ncoas}/`,
  unregisteredPeople: `${routes.root}${routes.app}/${routes.unregisteredPeople}`,
  unregisteredPerson: `${routes.root}${routes.app}/${routes.unregisteredPeople}/`,
  voterFiles: `${routes.root}${routes.app}/${routes.voterFiles}`,
  campaignContributions: `${routes.root}${routes.app}/${routes.campaignContributions}`,
  campaignContribution: `${routes.root}${routes.app}/${routes.campaignContributions}/`,
  mobileHomes: `${routes.root}${routes.app}/${routes.mobileHomes}`,
  mobileHome: `${routes.root}${routes.app}/${routes.mobileHomes}/`,
  dormitories: `${routes.root}${routes.app}/${routes.dormitories}`,
  dormitory: `${routes.root}${routes.app}/${routes.dormitories}/`,
  dataLoad: `${routes.root}${routes.dataLoad}/`,
  automatedDataLoad: `${routes.root}${routes.automatedDataLoad}/`,
  dataLoadStart: `${routes.root}${routes.dataLoad}/start/`,
  automatedDataLoadStart: `${routes.root}${routes.automatedDataLoad}/start/`,
  messagingPortfolios: `${routes.root}${routes.messaging}/${routes.messagingPortfolios}/`,
  messagingNewPortfolio: `${routes.root}${routes.messaging}/${routes.messagingPortfolios}/new`,
  messagingEditPortfolio: `${routes.root}${routes.messaging}/${routes.messagingPortfolios}/edit`,
  messagingStatistics: `${routes.root}${routes.messaging}/${routes.messagingCampaigns}/${routes.messagingStatistics}/`,
  messagingCampaigns: `${routes.root}${routes.messaging}/${routes.messagingCampaigns}/`,
  messagingStories: `${routes.root}${routes.messaging}/${routes.messagingStories}/`,
  messagingGroups: `${routes.root}${routes.messaging}/${routes.messagingGroups}/`,
  messagingShareList: `${routes.root}${routes.messaging}${routes.messagingShareList}/`,
  messagingNewGroup: `${routes.root}${routes.messaging}/${routes.messagingGroups}/new`,
  messagingEditGroup: `${routes.root}${routes.messaging}/${routes.messagingGroups}/edit/`,
  messagingNewCampaign: `${routes.root}${routes.messaging}/${routes.messagingCampaigns}/new`,
  messagingEditCampaign: `${routes.root}${routes.messaging}/${routes.messagingCampaigns}/edit`,
  arrest: `${routes.root}${routes.app}/${routes.arrests}/`,
  arrests: `${routes.root}${routes.app}/${routes.arrests}`,
  emailOutreaches: `${routes.root}${routes.app}/${routes.emailOutreaches}`,
  emailOutreach: `${routes.root}${routes.app}/${routes.emailOutreaches}/`
};

export const pageTitles = {
  [paths.dashboard]: 'Home',
  [paths.people]: `People`,
  [paths.peopleSearch]: `People`,
  [paths.geographies]: `Geographies`,
  [paths.campaignContributions]: `Contributions`,
  [paths.pppLoans]: `PPP Loans`,
  [paths.properties]: `Properties`,
  [paths.higherEducationSalaries]: `Higher Education Salaries`,
  [paths.higherEducationSalary]: `Higher Education Salaries`,
  [paths.candidates]: `Candidates`,
  [paths.users]: `Users`,
  [paths.courts]: `Courts`,
  [paths.judges]: `Judges`,
  [paths.cases]: `Cases`,
  [paths.lawyers]: `Lawyers`,
  [paths.lawfirms]: `Law Firms`,
  [paths.schools]: `Schools`,
  [paths.schoolBoards]: `School Boards`,
  [paths.electionResults]: `Election Results`,
  [paths.organizations]: `Organizations`,
  [paths.features]: `Admin Features`,
  [paths.nursingHomes]: `Nursing Homes`,
  [paths.subsidizedHousings]: `Subsidized Housing`,
  [paths.pensions]: `Pensions`,
  [paths.unions]: `Union Memberships`,
  [paths.ncoas]: `NCOAs`,
  [paths.unregisteredPeople]: `Unregistered People`,
  [paths.voterFiles]: `Voter Files`,
  [paths.mobileHomes]: `Mobile Home Parks`,
  [paths.dormitories]: `Dormitories`,
  [paths.messagingPortfolios]: `Portfolios`,
  [paths.messagingNewPortfolio]: `New Portfolio`,
  [paths.messagingEditPortfolio]: `Edit Portfolio`,
  [paths.messagingCampaigns]: `Campaigns`,
  [paths.messagingGroups]: `Groups`,
  [paths.messagingNewCampaign]: `New Campaign`,
  [paths.messagingNewGroup]: `New Group`,
  [paths.messagingEditGroup]: `Edit Group`,
  [paths.messagingEditCampaign]: `Edit Campaign`,
  [paths.messagingShareList]: `Outreach Sharing`,
  [paths.messagingStories]: `Stories`,
  [paths.dataLoad]: `Data Matching`,
  [paths.automatedDataLoad]: `Automated Data Matching`,
  [paths.dataLoadStart]: `Match Configuration`,
  [paths.automatedDataLoadStart]: `Automated Match Configuration`,
  [paths.arrests]: `Arrest`,
  [paths.emailOutreaches]: `Email Outreach`
};
