import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { ClassNameProps } from '../../common/Props';

const Secured: React.FC<ClassNameProps> = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  if (!initialized) {
    return null;
  }

  if (!isLoggedIn) {
    keycloak.login({ redirectUri: window.location.href });
    return null;
  }

  return <>{children}</>;
};

export default Secured;
