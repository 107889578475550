import { faFolder } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import { useAppFilterKey } from '../../store/app';
import Button from '../Button';
import Icon from '../Icon';
import UserFilterModal from './UserFilterModal';

const hideFilterKeys = ['', 'campaign-stats', 'messaging-groups', 'messaging-campaigns', 'message-groups-details'];

const isShowUserFilter = (appFilterKey: string): boolean => {
  return !hideFilterKeys.includes(appFilterKey || '');
};

const UserFilter: React.FC<{ type?: string } & ClassNameProps> = styled(({ type, className }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const appFilterKey = useAppFilterKey();

  return !isShowUserFilter(appFilterKey) ? null : (
    <span className={`${className} user-filter`}>
      <Button onClick={() => setShowModal(true)} variant="white" title={'Manage and apply previously saved filters'}>
        <Icon size="lg" icon={faFolder} /> <span className="user-filter__label">My Filters</span>
      </Button>
      {showModal && <UserFilterModal type={appFilterKey} handleClose={() => setShowModal(false)} open={showModal} />}
    </span>
  );
})`
  @media (max-width: 767.98px) {
    .user-filter__label {
      display: none;
    }
  }
  position: relative;
  top: 15px;
  right: 12px;
  display: inline-block;
  .btn.btn-white {
    padding: 0;
    border: 0;
    &:hover {
      border: 0;
      background-color: transparent;
    }
  }
`;

export default UserFilter;
