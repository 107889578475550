import { toSnakeCase } from '../../utils/format';

export const numericDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return {
    [field]: {
      greaterThanOrEqualTo: parseInt(filterValue[0]),
      lessThanOrEqualTo: parseInt(filterValue[1])
    }
  };
};

export const numericEqDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return {
    [field]: {
      equalTo: parseInt(filterValue[0])
    }
  };
};
export const numericEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return {
    must: [
      {
        range: {
          [snkField]: {
            gte: parseInt(filterValue[0]),
            lte: parseInt(filterValue[1])
          }
        }
      }
    ]
  };
};

export const numericEqEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return {
    must: [
      {
        match: {
          [snkField]: parseInt(filterValue[0])
        }
      }
    ]
  };
};
