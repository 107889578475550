export const fieldExistsDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  console.error('Field exists parameter processor not available for DB query.');
};
export const fieldExistsEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (filterValue[0] === 'false') {
    return {
      must_not: [
        {
          wildcard: {
            [field]: '*'
          }
        }
      ]
    };
  } else {
    return {
      must: [
        {
          wildcard: {
            [field]: '*'
          }
        }
      ]
    };
  }
};
