import { useRollbar } from '@rollbar/react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFeatureFlag } from '../../utils/features';
import Button from '../Button';

const ErrorHandler: React.FC<{ error: any; resetErrorBoundary: any; className?: string }> = styled(
  ({ className, error, resetErrorBoundary }) => {
    const rollbar = useRollbar();
    const rollbarEnabled: boolean = useFeatureFlag('rollbar');

    useEffect(() => {
      if (rollbarEnabled) {
        rollbar.error(error);
      }
    }, [rollbar, error, rollbarEnabled]);

    return (
      <div role="alert" className={`error ${className}`}>
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <details>
          <summary>View Stack Trace</summary>
          <div>
            <pre>{error.stack}</pre>
          </div>
        </details>
        <Button variant="danger" onClick={resetErrorBoundary}>
          Try again
        </Button>
      </div>
    );
  }
)`
  &.error {
    border: 1px dotted var(--danger);
    padding: 12px;
    color: var(--danger);
    max-width: 60vw;
    width: 100%;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: var(--border-radius-sm);
    background-color: #f1ddd7;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.6);
    details {
      margin-bottom: 20px;
    }
    .btn {
      float: right;
    }
  }
`;

export default ErrorHandler;
