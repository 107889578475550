import React, { useEffect, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import { useSubNavState } from '../../store/subnav';
import ScrollPane from '../ScrollPane';

export const SubNavLink: React.FC<{ href: string; children: React.ReactNode } & ClassNameProps> = styled(
  ({ className, href, children }) => {
    let resolved = useResolvedPath(href, { relative: 'route' });
    let match = useMatch({ path: resolved.pathname, end: false });
    return (
      <Link to={href || '#'} className={`${className} sub-nav__link ${match ? 'sub-nav__link--active' : ''}`}>
        {children}
      </Link>
    );
  }
)`
  color: var(--left-nav-color);
  text-decoration: none;
  line-height: 50px;
  padding: 0 16px;
  padding-left: 40px;
  display: inline-block;
  width: 100%;
  transition: background-color 0.2s;
  background-color: inherit;
  &.sub-nav__link--active {
    color: var(--white);
  }
  &.disabled {
    color: var(--left-nav-color-disabled);
  }

  &:hover {
    color: var(--gray-light);
  }
`;

const SubNav = styled(({ className, type }: { type: string } & ClassNameProps) => {
  const state = useSubNavState();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(state && state.type === type);
  }, [type, state, setShow]);

  return show && state.type ? (
    <ul className={`${className} sub-nav`}>
      <ScrollPane>
        {(state?.items || []).map((navItem, i) => (
          <li key={i} className="sub-nav__item">
            <SubNavLink href={navItem.href || ''}>{navItem.label}</SubNavLink>
          </li>
        ))}{' '}
      </ScrollPane>
    </ul>
  ) : null;
})`
  &.sub-nav {
    list-style-type: none;

    padding: var(--base-padding);

    padding-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    background-color: var(--subnav-bg);

    padding: 0;
  }
`;

export default SubNav;
