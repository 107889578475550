import { DEMOCRATIC, GENERAL, PRIMARY, REPUBLICAN, VOTED, getLatestElectionYear } from '../../utils/propensity';
import { toSnakeCase } from '../../utils/format';

const processPropGte = (snkField: string, filterValue: any[]): any => {
  const type = getType(snkField);
  const queryFieldKey = getQueryFieldKey(filterValue);
  const value = getValue(filterValue);

  const queryKeys: any[] = getKeys(type, queryFieldKey);

  let query = {};

  if ('p' === type || 'g' === type) {
    query = {
      bool: {
        should: queryKeys,
        minimum_should_match: value
      }
    };
  } else if ('pe' === type || 'ge' === type) {
    query = {
      bool: {
        must_not: [
          {
            bool: {
              should: queryKeys,
              minimum_should_match: value
            }
          }
        ]
      }
    };
  }

  return query;
};

const processPropEq = (snkField: string, filterValue: any[]): any => {
  const type = getType(snkField);
  const queryFieldKey = getQueryFieldKey(filterValue);
  const value = getValue(filterValue);

  const queryKeys: any[] = getKeys(type, queryFieldKey);

  let query = {};

  if ('p' === type || 'g' === type) {
    query = {
      bool: {
        should: queryKeys,
        minimum_should_match: value,
        must_not: [
          {
            bool: {
              should: queryKeys,
              minimum_should_match: +value + 1
            }
          }
        ]
      }
    };
  } else if ('p' === type || 'g' === type) {
    query = {
      bool: {
        must_not: [
          {
            bool: {
              should: queryKeys,
              minimum_should_match: value,
              must_not: [
                {
                  bool: {
                    should: queryKeys,
                    minimum_should_match: +value + 1
                  }
                }
              ]
            }
          }
        ]
      }
    };
  }

  return query;
};

const getType = (snkField: string) => {
  return snkField.split('_')[1] || 'p';
};

const getQueryFieldKey = (filterValue: string[]) => {
  return filterValue[0].split('_').length > 1 ? filterValue[0].split('_')[1] : 'E';
};

const getValue = (filterValue: string[]) => {
  return filterValue[0].split('_')[0];
};

const getKeys = (type: string, queryFieldKey: string) => {
  let keys: string[] = [];
  let year = getLatestElectionYear();

  if ('p' === type || 'pe' === type) {
    if ('E' === queryFieldKey) {
      for (let i = 0; i < 4; i++) {
        keys.push('*' + (year - i * 2) + '_' + PRIMARY + '*' + DEMOCRATIC + '*');
        keys.push('*' + (year - i * 2) + '_' + PRIMARY + '*' + REPUBLICAN + '*');
      }
    } else {
      for (let i = 0; i < 4; i++) {
        keys.push('*' + (year - i * 2) + '_' + PRIMARY + '*' + (queryFieldKey === 'D' ? DEMOCRATIC : REPUBLICAN) + '*');
      }
    }
  } else {
    for (let i = 0; i < 4; i++) {
      keys.push('*' + (year - i * 2) + '_' + GENERAL + '_' + VOTED);
    }
  }

  let queryKeys: any[] = [];
  keys.forEach((key: string) => {
    queryKeys.push({
      query_string: {
        default_field: 'voter_history.key.keyword',
        query: key
      }
    });
  });

  return queryKeys;
};

export const propensityGteDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  throw new Error('propgte is unimplemented for db source type');
};

export const propensityGteEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return processPropGte(snkField, filterValue);
};

export const propensityEqDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  throw new Error('propeq is unimplemented for db source type');
};

export const propensityEqEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return processPropEq(snkField, filterValue);
};
