import { isNestedPath, toSnakeCase } from '../../utils/format';
import { stripPunctuation } from '../../utils/search';

export const equalsIgnoreCaseDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (isNestedPath(field)) {
    const path = field.substring(0, field.indexOf('.'));
    const rest = field.substring(field.indexOf('.') + 1);

    return { [path]: equalsIgnoreCaseDbFilter(rest, searchParams, filterValue) };
  }
  return {
    [field]: {
      startsWithInsensitive: filterValue[0]
    }
  };
};

export const equalsIgnoreCaseEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return {
    must: [
      {
        bool: {
          should: [
            {
              prefix: {
                [snkField]: {
                  value: stripPunctuation(filterValue[0]),
                  case_insensitive: true
                }
              }
            },
            {
              prefix: {
                [snkField]: {
                  value: filterValue[0],
                  case_insensitive: true
                }
              }
            }
          ]
        }
      }
    ]
  };
};
