import { toSnakeCase } from '../../utils/format';

export const exactMatchDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return {
    [field]: {
      equalTo: filterValue[0]
    }
  };
};
export const exactMatchEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  return {
    must: [
      {
        bool: {
          should: [
            {
              match: {
                [snkField]: {
                  query: filterValue[0]
                }
              }
            }
          ]
        }
      }
    ]
  };
};
