import { debounce, endsWith, isArray, isNil, startsWith } from 'lodash';
import { KeyboardEvent, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Option from '../common/Option';

export const useSearch = (type: string = 'person', onInputChange: boolean = false) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const updateSearchTerm = useCallback(
    (value: string, navigateTo?: string) => {
      if (value.trim() !== '') {
        if (!isNil(navigateTo)) {
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set(type, value);
          navigate(`${navigateTo}?${newSearchParams.toString()}`);
          return;
        }
        searchParams.set(type, value);
      } else {
        searchParams.delete(type);
      }
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams, type, navigate]
  );

  const handleChange = useCallback(
    debounce((e: KeyboardEvent, navigateTo?: string) => {
      const value = (e.target as HTMLInputElement).value;
      if ((e.type === 'keypress' && e.key === 'Enter') || (e.type === 'input' && (value === '' || onInputChange))) {
        updateSearchTerm(value, navigateTo);
      }
    }, 425),
    [updateSearchTerm]
  );

  const handleSubmit = useCallback(
    (val: string) => {
      updateSearchTerm(val);
    },
    [updateSearchTerm]
  );

  return [handleChange, handleSubmit];
};

export const getSearchParamsAsOptions = (searchParams: URLSearchParams, type: string, options?: Option[]) => {
  const params = searchParams.getAll(type);
  return params
    .map((p) => {
      let option: any | undefined = undefined;
      if (options) {
        option = options.find((o) => startsWith(o.value, p));
      } else {
        option = { label: p, value: p };
      }
      return option;
    })
    .filter((o: any) => !isNil(o));
};

export const useSelectSearch = (type: string, dependents?: string[]) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const handleChange = useCallback(
    (val: any, t: { action: string }, existingSearchParams?: URLSearchParams) => {
      const newParams = new URLSearchParams();
      if (!isNil(existingSearchParams)) {
        searchParams = existingSearchParams!;
      }
      if (t.action === 'select-option') {
        let selected = val;
        if (!isArray(selected)) {
          selected = [selected];
        }
        selected.forEach(({ value }) => {
          if (value.trim() !== '' && 'undefined' !== value.trim()) {
            newParams.append(type, value);
          }
        });
        searchParams.forEach((value, key) => {
          if (key !== type && !dependents?.includes(key)) {
            newParams.append(key, value);
          }
        });
        setSearchParams(newParams);
      } else if (t.action === 'clear') {
        searchParams.forEach((value, key) => {
          if (key !== type && !dependents?.includes(key)) {
            //exception logic for voter history
            if (!(key.startsWith('vh') && dependents?.includes('vh'))) {
              newParams.append(key, value);
            }
          }
        });
        setSearchParams(newParams);
      } else if (t.action === 'remove-value') {
        searchParams.forEach((value, key) => {
          if (key !== type || (val.find((v: any) => startsWith(v.value, value)) && !dependents?.includes(key))) {
            newParams.append(key, value);
          }
        });
        setSearchParams(newParams);
      }
      return newParams;
    },
    [setSearchParams, searchParams, type, dependents]
  );

  return handleChange;
};

export function getSortByFromParams(params: URLSearchParams) {
  const sortParams = params.getAll('sort');
  const result: any = {};
  sortParams.forEach((sort) => {
    const splitIndex = sort.lastIndexOf('_');
    const direction = sort.slice(splitIndex + 1).toLowerCase();
    const field = sort.slice(0, splitIndex);

    result[field] = direction;
  });

  return result;
}

export const deleteStartsWith = (searchParams: URLSearchParams, keyPrefix: string) => {
  const keys = searchParams.keys();
  const deletedKeys: any[] = [];
  for (const k of keys) {
    if (startsWith(k, keyPrefix)) {
      deletedKeys.push(k);
    }
  }
  for (const k of deletedKeys) {
    searchParams.delete(k);
  }
};

export const deleteEndsWith = (searchParams: URLSearchParams, keySuffix: string) => {
  const keys = searchParams.keys();
  for (const k of keys) {
    if (endsWith(k, `-${keySuffix}`)) {
      searchParams.delete(k);
    }
  }
};

export const hasAnySearchParamStartingWith = (searchParams: URLSearchParams, keyPrefix: string[]) => {
  const keys = searchParams.keys();
  for (const k of keys) {
    if (keyPrefix.some((kp: string) => startsWith(k, kp))) {
      return true;
    }
  }
  return false;
};

export const getAllSearchParamsEndingWith = (searchParams: URLSearchParams, keyPrefix: string) => {
  const keys = searchParams.keys();
  let result: string[] = [];
  for (const k of keys) {
    if (endsWith(k, `-${keyPrefix}`)) {
      result = [...result, ...searchParams.getAll(k)];
    }
  }
  return result;
};

export const splitSearchParamToArray = (searchParams: URLSearchParams, keyPrefix: string) => {
  const keys = searchParams.keys();
  let result: any[] = [];
  for (const k of keys) {
    if (startsWith(k, keyPrefix)) {
      const typeOpSplit = k.split('-');
      result = [...typeOpSplit];
      const val = searchParams.get(k) || '';
      const valSplit = val.split('_');
      result = [...result, ...valSplit];
      break;
    }
  }
  return result;
};

export const useFilterPersistence = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params = sessionStorage.getItem(location.pathname);
    const urlParams = new URLSearchParams(location.search);
    const preventRestore = urlParams.get('r') === 'false';
    if (!isNil(params) && !preventRestore) {
      setTimeout(() => {
        setSearchParams(new URLSearchParams(params!));
        sessionStorage.removeItem(location.pathname);
      }, 50);
    }
  }, [location.pathname]);
  useEffect(() => {
    return () => {
      sessionStorage.setItem(location.pathname, searchParams.toString());
    };
  }, [searchParams, location.pathname]);
};

export const stripPunctuation = (str: string) => {
  return str?.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '').replace(/\s{2,}/g, ' ');
};
